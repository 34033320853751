import {
  CLEAR_METRICS,
  METRICS_SUCCESS,
  TRENDS_SUCCESS,
  GROUP_QUERY_SUCCESS,
  METRICS_REQUEST,
  TRENDS_REQUEST,
  PA_SUCCESS,
  PA_REQUEST,
  SUBTEST_REPORT_SUCCESS,
  POPUP_SUCCESS,
  POPUP_LOADING,
  POPUP_LOADING_FAIL,
} from '../constants/metricsConstants';
import {
  SUBTESTLIST_SUCCESS,
  PANTESTLIST_SUCCESS,
} from '../constants/resultConstants';
import { ADMIN_FACILITYLIST_SUCCESS } from '../constants/userConstants';

const initialState = {
  facilities: [],
  data: [],
  paData: [],
  trends: [],
  testList: [],
  panTestList: [],
  groups: [],
  searching: false,
  popup: [],
  popupLoadingSuccess:false,
};
const metricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_FACILITYLIST_SUCCESS:
      return {
        ...state,
        facilities: action.facilityList.sort(function (a, b) {
          var nameA = a.facilityName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.facilityName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }),
      };
    case PANTESTLIST_SUCCESS:
      return {
        ...state,
        panTestList: action.panTestList,
      };
    case SUBTESTLIST_SUCCESS:
      return {
        ...state,
        testList: action.subtestList,
      };
    case METRICS_SUCCESS:
      return {
        ...state,
        data: action.metrics,
        searching: false,
      };
    case METRICS_REQUEST:
      return {
        ...state,
        searching: true,
      };
    case PA_SUCCESS:
      return {
        ...state,
        paData: action.metrics,
        searching: false,
      };
    case PA_REQUEST:
      return {
        ...state,
        searching: true,
      };
    case TRENDS_REQUEST:
      return {
        ...state,
        searching: true,
      };
    case TRENDS_SUCCESS:
      return {
        ...state,
        trends: action.trends,
        searching: false,
      };
    case CLEAR_METRICS:
      return {
        ...state,
        data: [],
        paData: [],
        trends: [],
      };
    case GROUP_QUERY_SUCCESS:
      return {
        ...state,
        groups: action.groups,
      };
    case SUBTEST_REPORT_SUCCESS:
      return {
        ...state,
        reports: action.subtestReports,
      };
      case POPUP_SUCCESS:
        return {
          ...state,
          popup: action.popup,
          popupLoading: false,
          popupLoadingSuccess: true,
        };
        case POPUP_LOADING:
          return {
            ...state,
            popupLoading: true,
            popupLoadingSuccess: false,
          };
          case POPUP_LOADING_FAIL:
          return {
            ...state,
            popupLoading: false,
            popupLoadingSuccess: false,
          };
    default:
      return state;
  }
};
export default metricsReducer;
