import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFacilityRequest } from '../actions/userActions';
import {
  addFacilityLoginRequest,
  deleteFacilityLoginRequest,
  updateFacilityLoginRequest,
} from '../actions/facilityLoginActions';
import { Typography } from '@rmwc/typography';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import { updateFacesheets } from '../actions/reportActions';
import { Button } from '@rmwc/button';
import '../css/FacilityLoginComponent.css';
import { getLoginsRequest } from '../actions/facilityLoginActions';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import Swal from 'sweetalert2';

class FacilityLoginComponent extends Component {
  constructor() {
    super();
    this.state = {
      facilities: [],
      facilityList: [],
      logins: [],
      loginInfoDialog: false,
      currentLogin: [],
      editFacilityLoginDialogOpen: false,
      facScraper: '',
    };
  }
  static getDerivedStateFromProps(props, state) {
    let facilityList = [''];
    props.facilities.forEach((fac) => {
      facilityList.push(fac);
    });
    return {
      ...state,
      facilities: props.facilities,
      facilityList: facilityList,
      logins: props.logins,
    };
  }

  componentDidMount() {
    this.props.getLoginsRequest();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  }

  addLogin = () => {
    let loginJson = {
      username: this.state.username,
      password: this.state.password,
      scraper: this.state.facScraper,
    };
    this.props.addFacilityLogin(loginJson);
  };
  updateLogin = () => {
    let loginJson = {
      username: this.state.username,
      password: this.state.password,
      scraper: this.state.facScraper,
    };
    this.props.updateFacilityLogin(loginJson);
  };
  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    let loginInfoDialog = () => {
      return (
        <Dialog
          open={this.state.loginInfoDialog}
          onClose={(evt) => {
            this.setState({ loginInfoDialog: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle id="addTitle">EMR Information</DialogTitle>
          <DialogContent>
            <Grid>
              <GridRow>
                <GridCell span={12}></GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Name: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentLogin.facilityUsername}
                </GridCell>
              </GridRow>
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Scraper: </Typography>
                </GridCell>
                <GridCell span={6}>{this.state.currentLogin.scraper}</GridCell>
              </GridRow>
              <hr></hr>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ loginInfoDialog: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let editFacilityLoginDialog = () => {
      let type = '';
      if (this.state.facScraper === 'Point Click Care') {
        type = 'Point Click Care';
      } else if (this.state.facScraper === 'Matrix') {
        type = 'Matrix';
      }
      return (
        <Dialog
          open={this.state.editFacilityLoginDialogOpen}
          onClose={(evt) => {
            this.setState({ editFacilityLoginDialogOpen: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            {'Modify Facility Login "' + this.state.username + '"'}
          </DialogTitle>

          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Password:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    placeholder="Password"
                    outlined
                    name="password"
                    value={this.state.password}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Type:</Typography>
                </GridCell>
                <GridCell span={9} style={{ width: '75%' }}>
                  <Select
                    placeholder="EMR Type"
                    outlined
                    value={type}
                    name="facScraper"
                    options={['Point Click Care', 'Matrix']}
                    onChange={(event) => {
                      this.setState({
                        facScraper: event.currentTarget.value,
                      });
                    }}
                  ></Select>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <Button
              action="update"
              unelevated
              icon="save"
              onClick={() => {
                if (
                  this.isEmpty(this.state.password) ||
                  this.isEmpty(this.state.facScraper)
                ) {
                  Swal.fire({
                    icon: 'error',
                    text: 'Please fill out all search fields',
                  });
                } else {
                  this.updateLogin();
                  this.setState({ editFacilityLoginDialogOpen: false });
                }
              }}
            >
              Update
            </Button>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({ editFacilityLoginDialogOpen: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let addFacilityLoginDialog = () => {
      return (
        <Dialog
          open={this.state.addFacilityLoginDialogOpen}
          onClose={(evt) => {
            this.setState({ addFacilityLoginDialogOpen: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Add EMR Login
          </DialogTitle>
          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>Username:</GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    placeholder="Username"
                    outlined
                    required
                    name="username"
                    value={this.state.username}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>Password:</GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    placeholder="Password"
                    outlined
                    required
                    name="password"
                    value={this.state.password}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>Type:</GridCell>
                <GridCell style={{ width: '75%' }} span={9}>
                  <Select
                    placeholder="EMR Type"
                    outlined
                    required
                    name="facScraper"
                    value={this.state.facScraper}
                    options={['Point Click Care', 'Matrix']}
                    onChange={(event) => {
                      this.setState({
                        facScraper: event.currentTarget.value,
                      });
                    }}
                  ></Select>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              action="add"
              icon="add"
              unelevated
              onClick={(evt) => {
                if (
                  this.isEmpty(this.state.username) ||
                  this.isEmpty(this.state.password) ||
                  this.isEmpty(this.state.facScraper)
                ) {
                  evt.stopPropagation();
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'you must fill out the required fields',
                  });
                } else {
                  this.addLogin();
                  this.setState({
                    addFacilityLoginDialogOpen: false,
                    username: '',
                    password: '',
                    facScraper: '',
                  });
                }
              }}
            >
              Add
            </DialogButton>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({
                  addFacilityLoginDialogOpen: false,
                  username: '',
                  password: '',
                  facScraper: '',
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let loginsData = () =>
      this.state.logins.map((login, i) => {
        return (
          <DataTableRow key={i}>
            <DataTableCell
              onClick={() => {
                this.setState({
                  loginInfoDialog: true,
                  currentLogin: login,
                });
              }}
              style={{
                border: '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              {login.facilityUsername}
            </DataTableCell>
            <DataTableCell>
              <Button
                icon="edit"
                onClick={() =>
                  this.setState({
                    editFacilityLoginDialogOpen: true,
                    username: login.facilityUsername,
                    facScraper: login.scraper,
                    password: '',
                  })
                }
              >
                Modify
              </Button>
              <Button
                icon="delete"
                onClick={() => {
                  Swal.fire({
                    title:
                      'Are you sure you want to delete Facility Login ' +
                      login.facilityUsername +
                      ' ?',
                    text: 'You will not be able to revert this!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.props.deleteFacilityLogin(login.facilityUsername);
                      Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                      );
                    }
                  });
                }}
              >
                Delete
              </Button>
              <Button
                icon="update"
                onClick={() => {
                  Swal.fire({
                    title:
                      'Would you like to update the facility facesheets under login "' +
                      login.facilityUsername +
                      '" ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, update facesheets!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.props.updateFacesheets(login, 'id');
                      Swal.fire(
                        'Updating!',
                        'Your facesheets are being updated.',
                        'success'
                      );
                    }
                  });
                }}
              >
                Update Facesheets
              </Button>
            </DataTableCell>
          </DataTableRow>
        );
      });

    return (
      <div>
        <div id="facilityHead">
          <div id="facility">
            <Typography use="headline4"> EMR Logins </Typography>
          </div>
        </div>
        <DataTable id="facilityLoginTable" stickyRows={1} stickyColumns={1}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                    width: '50%',
                  }}
                  value={this.state.facilitySort}
                  sort={this.state.facilitySort}
                  onSortChange={(sortDir) => {
                    this.setState(
                      {
                        facilitySort: sortDir,
                      },
                      () => {
                        this.sortData(sortDir);
                      }
                    );
                  }}
                >
                  Username
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Actions
                </DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>{loginsData()}</DataTableBody>
          </DataTableContent>
        </DataTable>

        <div>
          <div>
            <Button
              id="addButton"
              variant="contained"
              onClick={() => {
                this.setState({
                  addFacilityLoginDialogOpen: true,
                  username: '',
                  password: '',
                  facScraper: '',
                });
              }}
            >
              Add EMR Login
            </Button>
          </div>
        </div>
        {loginInfoDialog()}
        {addFacilityLoginDialog()}
        {editFacilityLoginDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.login.decodedJWT.role,
  facilities: state.admin.facilities,
  logins: state.facilityLogins.logins,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFacilities: () => dispatch(getFacilityRequest()),
    getLoginsRequest: () => dispatch(getLoginsRequest()),
    addFacilityLogin: (json) => dispatch(addFacilityLoginRequest(json)),
    deleteFacilityLogin: (username) =>
      dispatch(deleteFacilityLoginRequest(username)),
    updateFacilityLogin: (json) => dispatch(updateFacilityLoginRequest(json)),
    updateFacesheets: (where, type) => dispatch(updateFacesheets(where, type)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacilityLoginComponent);
