import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/GroupComponent.css';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import { getAvailFacilityRequest } from '../actions/userActions';
import {
  addGroupRequest,
  getGroupsRequest,
  deleteGroupRequest,
  updateGroupRequest,
} from '../actions/groupActions';
import Swal from 'sweetalert2';
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import { updateFacilityRequest } from '../actions/facilityActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Checkbox } from 'rmwc';
import { Grid, GridCell, GridRow } from '@rmwc/grid';

class GroupComponent extends Component {
  constructor() {
    super();
    this.state = {
      groups: [],
      facilities: [],
      openGroupID: new Set(),
      editGroupDialogOpen: false,
      addGroupDialogOpen: false,
      editingGroup: false,
      rightList: [],
      rightChecked: [],
      leftList: [],
      leftChecked: [],
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (state.editingGroup) {
      return {
        ...state,
      };
    } else {
      return {
        ...state,
        leftList: props.availFacilities,
        groups: props.groups,
      };
    }
  }

  componentDidMount() {
    this.props.getFacilities();
    this.props.getGroups();
  }

  isEmpty(str) {
    return !str || str.length === 0;
  }

  addGroup = () => {
    let groupJson = {
      groupName: this.state.groupName,
      facilities: JSON.stringify(this.state.rightList),
    };

    this.props.addGroup(groupJson);
  };

  updateGroup = () => {
    let group = this.state.currentGroup;
    let groupJson = {
      groupID: group.groupID,
      groupName: this.state.groupName,
      facilities: this.state.rightList,
    };
    this.props.updateGroupRequest(groupJson);
  };

  deleteGroup = (groupID, groupName, groupFacilities) => {
    let groupJson = {
      groupID: groupID,
      groupName: groupName,
      groupFacilities: groupFacilities,
    };
    this.props.deleteGroup(groupJson);
  };

  moveRight = () => {
    let rightJson = [];
    let rightList = this.state.rightList;
    let leftList = this.state.leftList;
    this.state.leftChecked.forEach((item) => {
      rightJson.push(JSON.parse(item));
    });
    rightJson.forEach((item) => {
      let checkname = 'check' + item.facilityID;
      leftList = leftList.filter((facility) => {
        return facility.facilityID !== item.facilityID;
      });
      rightList.push(item);
      this.setState({ [checkname]: false });
    });
    this.setState({
      leftList: leftList,
      rightList: rightList,
      leftChecked: [],
    });
  };

  moveLeft = () => {
    let leftJson = [];
    let rightList = this.state.rightList;
    let leftList = this.state.leftList;
    this.state.rightChecked.forEach((item) => {
      leftJson.push(JSON.parse(item));
    });
    leftJson.forEach((item) => {
      let checkname = 'check' + item.facilityID;
      rightList = rightList.filter((facility) => {
        return facility.facilityID !== item.facilityID;
      });
      leftList.push(item);
      this.setState({ [checkname]: false });
    });

    this.setState({
      leftList: leftList,
      rightList: rightList,
      rightChecked: [],
    });
  };
  checkLeft(evt, checkname) {
    if (evt.target.checked) {
      let lftCheck = this.state.leftChecked;
      lftCheck.push(evt.target.value);
      this.setState({ leftChecked: lftCheck, [checkname]: true });
    } else {
      let lftCheck = this.state.leftChecked;
      lftCheck = lftCheck.filter((facility) => {
        let fac = JSON.parse(facility);
        let val = JSON.parse(evt.target.value);
        return fac.facilityID !== val.facilityID;
      });
      this.setState({ leftChecked: lftCheck, [checkname]: false });
    }
  }
  checkRight(evt, checkname) {
    if (evt.target.checked) {
      let rtCheck = this.state.rightChecked;
      rtCheck.push(evt.target.value);
      this.setState({ rightChecked: rtCheck, [checkname]: true });
    } else {
      let rtCheck = this.state.rightChecked;
      rtCheck = rtCheck.filter((facility) => {
        let fac = JSON.parse(facility);
        let val = JSON.parse(evt.target.value);
        return fac.facilityID !== val.facilityID;
      });
      this.setState({ rightChecked: rtCheck, [checkname]: false });
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    let setOpen = (groupID) => {
      let openlist = this.state.openGroupID;
      let contains = false;
      openlist.forEach((id) => {
        if (id === groupID) {
          contains = true;
          openlist.delete(id);
        }
      });
      if (!contains) {
        openlist.add(groupID);
      }
      this.setState({ openGroupID: openlist });
    };

    let isGroupOpen = (groupID) => {
      let openlist = this.state.openGroupID;
      let contains = false;
      openlist.forEach((id) => {
        if (id === groupID) {
          contains = true;
        }
      });
      return contains;
    };

    let editGroupDialog = () => {
      let group = this.state.currentGroup;
      let leftList = this.state.leftList;
      let rightList = this.state.rightList;

      if (group !== undefined) {
        return (
          <Dialog
            open={this.state.editGroupDialogOpen}
            onOpen={() => {
              if (group.groupFacilities !== null) {
                let rightList = this.state.rightList;
                group.groupFacilities.forEach((item) => {
                  rightList.push(item);
                });
              }
              this.setState({ editingGroup: true, rightList: rightList });
            }}
            onClose={(evt) => {
              this.props.getFacilities();
              this.setState({
                editGroupDialogOpen: false,
                editingGroup: false,
                leftList: this.props.availFacilities,
                leftChecked: [],
                rightList: [],
                rightChecked: [],
              });
            }}
            onClosed={(evt) => { }}
          >
            <DialogTitle id="addTitle">Update Group</DialogTitle>
            <DialogContent>
              <Grid>
                <GridRow>
                  <GridCell span={3}>
                    <Typography>Group Name:</Typography>
                  </GridCell>
                  <GridCell span={9}>
                    <TextField
                      style={{ width: '500px' }}
                      placeholder="Group Name"
                      outlined
                      required
                      label="Group Name"
                      name="groupName"
                      value={this.state.groupName}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridRow>
                <div>
                  <Grid
                    id="groupGrid"
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid id="leftGrid" item>
                      <Paper id="paper" variant="outlined">
                        <List dense component="div" role="list">
                          {leftList.map((facility) => {
                            let checkname = 'check' + facility.facilityID;
                            return (
                              <ListItem role="listitem">
                                <ListItemIcon>
                                  <Checkbox
                                    name={facility.facilityName}
                                    tabIndex={-1}
                                    disableRipple
                                    checked={this.state[checkname]}
                                    value={JSON.stringify(facility)}
                                    onChange={(evt) => {
                                      this.checkLeft(evt, checkname);
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={facility.facilityName} />
                              </ListItem>
                            );
                          })}
                          <ListItem />
                        </List>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <div id="buttonDiv">
                          <div>
                            <Button
                              outlined
                              size="small"
                              aria-label="move all right"
                            >
                              ≫
                            </Button>
                          </div>
                          <div>
                            <Button
                              outlined
                              size="small"
                              onClick={() => {
                                this.moveRight();
                              }}
                              aria-label="move selected right"
                            >
                              &gt;
                            </Button>
                          </div>
                          <div>
                            <Button
                              outlined
                              size="small"
                              onClick={() => {
                                this.moveLeft();
                              }}
                              aria-label="move selected left"
                            >
                              &lt;
                            </Button>
                          </div>
                          <div>
                            <Button
                              outlined
                              size="small"
                              aria-label="move all left"
                            >
                              ≪
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid id="rightGrid" item>
                      <Paper id="rightPaper" variant="outlined">
                        <List dense component="div" role="list">
                          {rightList.map((facility) => {
                            let checkname = 'check' + facility.facilityID;
                            return (
                              <ListItem role="listitem">
                                <ListItemIcon>
                                  <Checkbox
                                    name={facility.facilityName}
                                    tabIndex={-1}
                                    disableRipple
                                    checked={this.state[checkname]}
                                    value={JSON.stringify(facility)}
                                    onChange={(evt) => {
                                      this.checkRight(evt, checkname);
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={facility.facilityName} />
                              </ListItem>
                            );
                          })}
                          <ListItem />
                        </List>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </DialogContent>
            <DialogActions>
              <DialogButton
                action="update"
                unelevated
                icon="save"
                onClick={(evt) => {
                  if (this.isEmpty(this.state.groupName)) {
                    evt.stopPropagation();
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'you must fill out the required fields',
                    });
                  } else {
                    this.updateGroup();
                  }
                }}
              >
                Update
              </DialogButton>
              <DialogButton
                icon="close"
                outlined
                onClick={() => {
                  this.setState({
                    editGroupDialogOpen: false,
                  });
                }}
              >
                Close
              </DialogButton>
            </DialogActions>
          </Dialog>
        );
      }
    };
    let addGroupDialog = () => {
      let leftList = this.state.leftList;
      let rightList = this.state.rightList;
      return (
        <div id="addGroup">
          <Dialog
            open={this.state.addGroupDialogOpen}
            onOpen={() => {
              this.setState({ editingGroup: true, rightList: rightList });
            }}
            onClose={(evt) => {
              this.setState({
                addGroupDialogOpen: false,
                editingGroup: false,
                leftList: this.props.availFacilities,
                leftChecked: [],
                rightList: [],
                rightChecked: [],
              });
            }}
            onClosed={(evt) => { }}
          >
            <DialogTitle
              id="addTitle"
              style={{
                borderTop: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              Add Group
            </DialogTitle>

            <DialogContent
              style={{
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              <Grid>
                <GridRow>
                  <GridCell span={3}>
                    <Typography>Group Name:</Typography>
                  </GridCell>
                  <GridCell span={9}>
                    <TextField
                      style={{ width: '500px' }}
                      placeholder="Group Name"
                      outlined
                      required
                      label="Group Name"
                      name="groupName"
                      value={this.state.groupName}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridRow>

                <div>
                  <Grid
                    id="groupGrid"
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid id="leftGrid" item>
                      <Paper id="paper" variant="outlined" n>
                        <List dense component="div" role="list">
                          {leftList != null
                            ? leftList.map((facility) => {
                              let checkname = 'check' + facility.facilityID;
                              return (
                                <ListItem role="listitem">
                                  <ListItemIcon>
                                    <Checkbox
                                      name={facility.facilityName}
                                      tabIndex={-1}
                                      disableRipple
                                      checked={this.state[checkname]}
                                      value={JSON.stringify(facility)}
                                      onChange={(evt) => {
                                        this.checkLeft(evt, checkname);
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={facility.facilityName}
                                  />
                                </ListItem>
                              );
                            })
                            : null}
                          <ListItem />
                        </List>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <div id="buttonDiv">
                          <div>
                            <Button
                              outlined
                              size="small"
                              onClick={() => {
                                this.moveRight();
                              }}
                              aria-label="move selected right"
                            >
                              &gt;
                            </Button>
                          </div>
                          <div>
                            <Button
                              outlined
                              size="small"
                              onClick={() => {
                                this.moveLeft();
                              }}
                            >
                              &lt;
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid id="rightGrid" item>
                      <Paper id="rightPaper" variant="outlined">
                        <List dense component="div" role="list">
                          {rightList.map((facility) => {
                            let checkname = 'check' + facility.facilityID;
                            return (
                              <ListItem role="listitem">
                                <ListItemIcon>
                                  <Checkbox
                                    tabIndex={-1}
                                    disableRipple
                                    value={JSON.stringify(facility)}
                                    checked={this.state[checkname]}
                                    onChange={(evt) => {
                                      this.checkRight(evt, checkname);
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={facility.facilityName} />
                              </ListItem>
                            );
                          })}
                          <ListItem />
                        </List>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                borderBottom: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                color: 'dodgerblue',
              }}
            >
              <DialogButton
                action="add"
                icon="add"
                unelevated
                onClick={(evt) => {
                  if (this.isEmpty(this.state.groupName)) {
                    evt.stopPropagation();
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'you must fill out the required fields',
                    });
                  } else {
                    this.setState({ editingGroup: false, groupName: '' });
                    this.addGroup();
                  }
                }}
              >
                Add
              </DialogButton>
              <DialogButton
                icon="close"
                outlined
                onClick={() => {
                  this.setState({
                    addGroupDialogOpen: false,
                    editingGroup: false,
                  });
                }}
              >
                Close
              </DialogButton>
            </DialogActions>
          </Dialog>
        </div>
      );
    };

    let groupData = () => {
      if (this.state.groups.length === 0) {
        return (
          <Typography id="noOBR" use="headline5">
            No Groups
          </Typography>
        );
      } else {
        let groupData = this.state.groups.map((group, i) => {
          return [
            <TableRow key={group.groupID}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(group.groupID)}
                >
                  {isGroupOpen(group.groupID) ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                {group.groupName}
              </TableCell>
            </TableRow>,
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse
                  in={isGroupOpen(group.groupID)}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box margin={1}>
                    <Table stickyHeader="true">
                      <TableRow>
                        <TableCell id="cellBold">Facility Names</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {group.groupFacilities !== null ? (
                            group.groupFacilities.map((facilityJson) => {
                              return (
                                <List>
                                  {facilityJson.facilityName}
                                </List>
                              );
                            })
                          ) : (
                            <Typography>no facilities, </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow key={i}>
                        <TableCell>
                          <Button
                            onClick={() => {
                              this.setState({
                                editGroupDialogOpen: true,
                                currentGroup: group,
                                groupName: group.groupName,
                              });
                            }}
                            icon="edit"
                          >
                            Update Group
                          </Button>
                          <Button
                            icon="delete"
                            onClick={() => {
                              Swal.fire({
                                title:
                                  'Are you sure you want to delete this group?',
                                text: 'You will not be able to revert this!',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, delete it!',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  this.props.deleteGroup(
                                    group.groupName,
                                    group.groupID
                                  );
                                  Swal.fire(
                                    'Deleted!',
                                    'The group has been deleted.',
                                    'success'
                                  );
                                }
                              });
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                      ,
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>,
          ];
        });
        return groupData;
      }
    };
    return (
      <div>
        <div id="groupHead">
          <div id="groups">
            <Typography use="headline4"> Groups </Typography>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    Group Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{groupData()}</TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <Button
            id="addButton"
            variant="contained"
            onClick={() => this.setState({ addGroupDialogOpen: true })}
          >
            Add Group
          </Button>
        </div>
        {addGroupDialog()}
        {editGroupDialog()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  role: state.login.decodedJWT.role,
  availFacilities: state.group.availFacilities,
  groups: state.group.groups,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFacilities: () => dispatch(getAvailFacilityRequest()),
    addGroup: (json) => dispatch(addGroupRequest(json)),
    getGroups: () => dispatch(getGroupsRequest()),
    deleteGroup: (groupName, groupID) =>
      dispatch(deleteGroupRequest(groupName, groupID)),
    updateGroupRequest: (json) => dispatch(updateGroupRequest(json)),
    updateFacilityRequest: (json) => dispatch(updateFacilityRequest(json)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupComponent);
