export const METRICS_REQUEST = 'METRICS_REQUEST';
export const METRICS_SUCCESS = 'METRICS_SUCCESS';
export const METRICS_FAILURE = 'METRICS_FAILURE';
export const TRENDS_REQUEST = 'TRENDS_REQUEST';
export const TRENDS_SUCCESS = 'TRENDS_SUCCESS';
export const TRENDS_FAILURE = 'TRENDS_FAILURE';
export const PA_REQUEST = 'PA_REQUEST';
export const PA_SUCCESS = 'PA_SUCCESS';
export const PA_FAILURE = 'PA_FAILURE';
export const CLEAR_METRICS = 'CLEAR_METRICS';
export const METRICS_REPORT_SUCCESS = 'METRICS_REPORT_SUCCESS';
export const METRICS_REPORT_FAILURE = 'METRICS_REPORT_FAILURE';
export const METRICS_REPORT = 'METRICS_REPORT';
export const GROUP_QUERY_SUCCESS = 'GROUP_QUERY_SUCCESS';
export const GROUP_QUERY_FAIL = 'GROUP_QUERY_FAIL';
export const SUBTEST_REPORT_SUCCESS = 'SUBTEST_REPORT_SUCCESS';
export const SUBTEST_REPORT_FAIL = 'SUBTEST_REPORT_FAIL';
export const SUBTEST_REPORT_QUERY = 'SUBTEST_REPORT_QUERY';
export const POPUP_SUCCESS = 'POPUP_SUCCESS';
export const POPUP_LOADING = 'POPUP_LOADING';
export const POPUP_LOADING_FAIL = 'POPUP_LOADING_FAIL';
