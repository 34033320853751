import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getCodesRequest,
} from '../actions/labOrderManagerActions';
import TextField from '@material-ui/core/TextField';

import { Button } from '@rmwc/button';
import { Typography } from '@rmwc/typography';
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Select } from '@rmwc/select';
import {
  labTestsList,
  patientNameList,
  printReportsByDate,
} from '../actions/reportActions';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableContent,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow,
  Dialog,
  DialogActions,
  DialogButton,
  GridCell,
  GridRow,
  //Grid
} from 'rmwc';
import { getPanTestRequest, getSubtestRequest } from '../actions/resultsActions';
import Swal from 'sweetalert2';
import {
  addQRRequest,
  deleteQRRequest,
  getFacilityRequest,
  getQuickReports,
  updateQRRequest,
} from '../actions/userActions';
import { List, ListItem } from '@rmwc/list';
import Checkbox from '@material-ui/core/Checkbox';
import {
  getQRMetricsRequest,
  getQRTrendsRequest,
} from '../actions/metricsActions';
import Grid from '@material-ui/core/Grid';
import {
  getGroupsRequest,
} from '../actions/metricsActions';



class QuickReportsComponent extends Component {
  constructor() {
    super();
    this.state = {
      searchStartDate: new Date().toISOString().slice(0, 10),
      searchEndDate: new Date().toISOString().slice(0, 10),
      reports: [],
      printDialog: false,
      facesheetDialog: false,
      files: [],
      facilities: [],
      facilityList: [],
      facesheetFacility: JSON.stringify('{}'),
      testType: [],
      checked: [],
      reportType: '',
      qrType: '',
      test: '',
      patients: [],
      labs: [],
      labChoiceModalOpen: false,
      rv: 'custom',
      updatingTest: false,
      reportName: '',
      testType: 'subtest',
      testList: [],
      checkAll: false,
      testChecked: [],
      name: '',
      rv2: 'allResults',


    };
  }

  static getDerivedStateFromProps(props, state) {
    let facilityList = [];
    props.facilities.forEach((facility) => {
      facilityList.push(facility);
    });
    let patientList = [];
    props.patients.forEach((patient) => {
      patientList.push(
        patient
        // patient.patientLastName + ', ' + patient.patientFirstName
      );
    });
    let groupList = [];
    props.groups.forEach((group) => {
      groupList.push(group);
    });
    return {
      ...state,
      facilities: props.facilities,
      facilityList: facilityList,
      patients: props.patients,
      patientList: patientList,
      role: props.role,
      testList: props.testList,
      groupList: groupList,

    };
  }
  componentDidMount() {
    this.props.getFacilities();
    this.props.patientNameList();
    this.props.getQuickReports();
    this.props.patientNameList();
    this.props.getSubtests();
    this.props.getPanTests();
    this.props.getGroupsRequest();
  }

  buildJson() {
    let json = {};
    let reportOptions = {};
    let tests;

    if (this.state.checkAll) {
      if (this.state.qrType === 'Metrics Report') {
        tests = this.state.testChecked
      }
      else {
        tests = []
      }
    }
    else {
      tests = this.state.testChecked
    }
    reportOptions.reportName = this.state.reportName;
    reportOptions.reportType = this.state.qrType;
    reportOptions.target = this.state.reportSelect;
    reportOptions.frequency = this.state.rv;
    reportOptions.tests = tests;
    reportOptions.type = this.state.reportType;
    json.username = this.props.username;
    json.reportOptions = reportOptions;
    let facName = '';
    if (this.state.reportType === 'Facility' || this.state.reportType === 'Report By Facility') {
      facName = JSON.parse(json.reportOptions.target).facilityName;
      reportOptions.facName = facName;
      reportOptions.critical = this.state.rv2;
    }
    else if (this.state.reportType === 'Patient' || this.state.reportType === 'Report By Patient') {
      facName = JSON.parse(json.reportOptions.target).patientLastName + ", " + JSON.parse(json.reportOptions.target).patientFirstName
      reportOptions.facName = facName;
      reportOptions.critical = this.state.rv2;

    }
    else if (this.state.reportType === 'Group') {
      facName = JSON.parse(json.reportOptions.target).groupName;
      reportOptions.facName = facName;

    }
    else {
      facName = reportOptions.target;
      reportOptions.facName = facName;

    }

    if (this.state.updating) {
      json.qrID = this.state.qrID;
      this.props.updateQuickReport(json);
    } else {
      this.props.addQuickReport(json);
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }
  clearChecks = () => {
    Object.keys(this.state).map((key) => {
      let regex = new RegExp('check*');

      if (regex.test(key)) {
        console.log(key);
        this.setState({ [key]: false });
      }
      return null;
    });
  };
  selectAll = (e) => {
    if (this.state.testType === 'subtest') {
      this.props.testList.map((test) => {
        if (test.testName !== null) {
          this.setState({ [test.testName]: true });
          let testChecked = this.state.testChecked;
          if (!this.state[test.testName]) {
            testChecked.push(test);
          } else {
            testChecked = [];
            this.clearChecks();
            // testChecked = testChecked.filter(
            //   (check) => check.testName !== test.testName
            // );
          }
          this.setState({
            [test.testName]: !this.state[test.testName],
            testChecked: testChecked,
          });
        }
        this.setState({ selectAll: !this.state.selectAll });
      });
    } else if (this.state.testType === 'panel') {
      this.props.panList.map((test) => {
        if (test.panelName !== null) {
          this.setState({ [test.panelName]: true });
          let testChecked = this.state.panChecked;
          if (!this.state[test.panelName]) {
            testChecked.push(test);
          } else {
            testChecked = testChecked.filter(
              (check) => check.panelName !== test.panelName
            );
          }
          this.setState({
            [test.panelName]: !this.state[test.panelName],
            testChecked: testChecked,
          });
        }
        this.setState({ selectAll: !this.state.selectAll });
      });
    }
    this.setState({ checkAll: !this.state.checkAll })
  };

  render() {

    const states = [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ];

    let addQuickReportDialog = () => {
      return (
        <Dialog
          id="printReportDialog"
          open={this.state.QRDialog}
          onClose={(evt) => {
            this.clearChecks();
            this.setState({
              QRDialog: false,
              updating: false,
              reportSelect: '',
              rv: '',
              reportName: '',
              reportType: '',
              name: '',
            });
          }}
        >
          <DialogTitle
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
            id="reportTitle"
          >
            Quick Report Options
          </DialogTitle>
          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
              width: '100%',
              // overflow:'auto'
            }}
            id="printDialogContent"
          >
            <Grid
              container
              spacing={4}
              alignContent="center"
              justifyContent="center">
              <Grid item xs={3}>
                <Typography>Select Quick Report Type:</Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  label="Quick Report Type"
                  outlined
                  options={['Laboratory Report', 'Metrics Report', 'Trends Report']}
                  value={this.state.qrType}
                  name="reportType"
                  onChange={(event) => {
                    let value = event.currentTarget.value;
                    this.setState({}, () => {
                      this.setState({ qrType: value });
                    });
                  }}
                ></Select>
              </Grid>
            </Grid>

            {this.state.qrType === 'Laboratory Report' ? (<div>
              <Grid
                container
                spacing={4}
                alignContent="center"
                justifyContent="center">
                <Grid item xs={3}>
                  <Typography>Report Name:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    //style={{ width: '78%', left: '4%', top: '10%' }}
                    label="Report Name"
                    outlined
                    fullWidth
                    value={this.state.reportName}
                    name="reportName"
                    onChange={(event) => {
                      let value = event.currentTarget.value;
                      this.setState({}, () => {
                        this.setState({ reportName: value });
                      });
                    }}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                alignContent="center"
                justifyContent="center"
              // id="reportTypeRow"
              // style={{ position: 'absolute', top: '30%' }}
              >
                <Grid item xs={3}>
                  <Typography>Select Report Type:</Typography>
                </Grid>
                <Grid
                  item xs={7}
                //style={{ position: 'absolute', left: '32.25%', width: '91.5%' }}
                >
                  <Select
                    label="Report Type"
                    outlined
                    options={['Report By Facility', 'Report By Patient']}
                    value={this.state.reportType}
                    name="reportType"
                    onChange={(event) => {
                      let value = event.currentTarget.value;
                      this.setState({}, () => {
                        this.setState({ reportType: value });
                      });
                    }}
                  ></Select>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                alignContent="center"
                justifyContent="center"
              >
                <Grid item xs={3}>
                  {this.state.reportType === 'Report By Patient' ? (
                    <Typography>Select Patient Name:</Typography>
                  ) : (
                    <Typography>Select Facility Name:</Typography>
                  )}
                </Grid>
                <Grid item xs={7}
                >
                  {this.state.reportType === 'Report By Patient' ? (
                    // <Select
                    //   label="Patient Name"
                    //   outlined
                    //   value={this.state.reportSelect}
                    //   options={this.state.patientList}
                    //   onChange={(event) => {
                    //     this.setState({
                    //       reportSelect: event.currentTarget.value,
                    //     });
                    //   }}
                    // ></Select>
                    <Select
                      id="patientSelect"
                      label="Patient"
                      outlined
                      native
                      displayEmpty
                      value={this.state.name}
                      onChange={(event) => {
                        this.setState({
                          checked: event.currentTarget.value,
                          name: event.currentTarget.name,
                          reportSelect: event.currentTarget.value,

                        });
                      }}
                    >
                      {this.state.patientList.map((state) => (
                        <option
                          name={state.patientID}
                          value={JSON.stringify(state)}
                        >
                          {state.patientLastName + ', ' + state.patientFirstName}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    // <Select
                    //   label="Facility Name"
                    //   outlined
                    //   value={this.state.reportSelect}
                    //   options={this.state.facilityList}
                    //   onChange={(event) => {
                    //     this.setState({
                    //       reportSelect: event.currentTarget.value,
                    //     });
                    //   }}
                    // ></Select>
                    <Select
                      id="facilitySelect"
                      label="Facility"
                      outlined
                      native
                      displayEmpty
                      value={this.state.name}
                      onChange={(event) => {

                        this.setState({
                          name: event.currentTarget.name,
                          reportSelect: event.currentTarget.value,
                        });
                      }}
                      labelId="Facility"
                      inputProps={{
                        name: 'checked',
                      }}
                    >
                      {this.state.facilityList.map((state) => (
                        <option
                          name={state.facilityName}
                          value={JSON.stringify(state)}
                        >
                          {state.facilityName}
                        </option>
                      ))}
                    </Select>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                alignContent="center"
                justifyContent="center"
              // id="reportDateRow"
              // style={{ position: 'absolute', top: '55%' }}
              >
                <Grid item xs={3}>
                  <Typography>Select Date Search:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <RadioGroup
                    value={this.state.rv}
                    name="rv"
                    onChange={this.handleChange}
                    row
                    style={{ left: '34%', position: 'absolute' }}
                  >
                    <FormControlLabel
                      value="Day"
                      control={<Radio color="primary" />}
                      label="Last Day"
                    />
                    <FormControlLabel
                      value="LastWeek"
                      control={<Radio color="primary" />}
                      label="Last 7 Days"
                    />
                    <FormControlLabel
                      value="LastMonth"
                      control={<Radio color="primary" />}
                      label="Last 30 Days"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container
                spacing={4}
                alignContent="center"
                justifyContent="center">
                <Grid item xs={3}
                >
                  <Typography>Critical Results: </Typography>
                </Grid>

                <Grid item xs={7}>
                  <RadioGroup value={this.state.rv2}
                    name="rv2"
                    onChange={this.handleChange}
                    row
                    style={{ left: '34%', position: 'absolute' }}
                  >
                    <FormControlLabel
                      value="allResults"
                      control={<Radio color="primary" />}
                      label="All Results"
                    />
                    <FormControlLabel
                      value="critical"
                      control={<Radio color="primary" />}
                      label="Critical Results Only"
                    />

                  </RadioGroup>
                </Grid>


              </Grid>
            </div>) :
              (
                <div>
                  <Grid
                    container
                    spacing={4}
                    alignContent="center"
                    justifyContent="center">
                    <Grid item xs={3}>
                      <Typography>Report Name:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        style={{ width: '78%', left: '4%', top: '10%' }}
                        label="Report Name"
                        outlined
                        fullWidth
                        value={this.state.reportName}
                        name="reportName"
                        onChange={(event) => {
                          let value = event.currentTarget.value;
                          this.setState({}, () => {
                            this.setState({ reportName: value });
                          });
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={4}
                    alignContent="center"
                    justifyContent="center"
                  >
                    <Grid item xs={3}>
                      {/* <Grid style={{ top: '105px', position: 'absolute', left: '50px' }}> */}
                      <Typography>Select trend type:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Select
                        id="reportType"
                        label="Report Type:"
                        outlined
                        options={['Facility', 'Group', 'Patient', 'State']}
                        value={this.state.reportType}
                        onChange={(event) => {
                          let value = event.currentTarget.value;
                          this.setState({
                            reportType: value,
                            checked: [],
                            trends: [],
                          });

                        }}
                      ></Select>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={4}
                    alignContent="center"
                    justifyContent="center">
                    <Grid item xs={3}>
                      {this.state.reportType === 'Patient' ? (
                        <Typography>Select Patient Name:</Typography>
                      ) : this.state.reportType === 'Group' ? (
                        <Typography>Select Group Name:</Typography>
                      ) : this.state.reportType === 'State' ? (
                        <Typography>Select State Name:</Typography>
                      ) : (
                        <Typography>Select Facility Name:</Typography>
                      )}
                    </Grid>
                    <Grid item xs={7}>
                      {this.state.reportType === 'Facility' ? (
                        <Select
                          id="facilitySelect"
                          label="Facility"
                          outlined
                          native
                          displayEmpty
                          value={this.state.name}
                          onChange={(event) => {
                            let value = event.currentTarget.value;
                            this.setState({
                              checked: value,
                              name: value,
                              reportSelect: value,
                            });
                          }}
                          labelId="Facility"
                          inputProps={{
                            name: 'checked',
                          }}
                        >
                          {this.state.facilityList.map((state) => (
                            <option
                              name={state.facilityName}
                              value={JSON.stringify(state)}
                            >
                              {state.facilityName}
                            </option>
                          ))}
                        </Select>

                      ) : this.state.reportType === 'Patient' ? (
                        // <Select
                        //   label="Patient Name"
                        //   outlined
                        //   value={this.state.reportSelect}
                        //   options={this.state.patientList}
                        //   onChange={(event) => {
                        //     this.setState({
                        //       reportSelect: event.currentTarget.value,
                        //     });
                        //   }}
                        // ></Select>
                        <Select
                          id="patientSelect"
                          label="Patient"
                          outlined
                          native
                          displayEmpty
                          value={this.state.name}
                          onChange={(event) => {
                            this.setState({
                              checked: event.currentTarget.value,
                              name: event.currentTarget.name,
                              reportSelect: event.currentTarget.value,

                            });
                          }}
                        >
                          {this.state.patientList.map((state) => (
                            <option
                              name={state.patientID}
                              value={JSON.stringify(state)}
                            >
                              {state.patientLastName + ', ' + state.patientFirstName}
                            </option>
                          ))}
                        </Select>
                      ) : this.state.reportType === 'Group' ? (
                        <Select
                          style={{
                            width: '100%',
                          }}
                          id="groupSelect"
                          label="Group"
                          outlined
                          native
                          displayEmpty
                          value={this.state.name}
                          onChange={(event) => {
                            this.setState({
                              checked: event.currentTarget.value,
                              name: event.currentTarget.name,
                              reportSelect: event.currentTarget.value,

                            });
                          }}
                        // labelId="Group"
                        // inputProps={{
                        //   name: 'checked',
                        // }}
                        >
                          {this.state.groupList.map((state) => (
                            <option
                              name={state.groupID}
                              value={JSON.stringify(state)}
                            >
                              {state.groupName}
                            </option>
                          ))}
                        </Select>
                      ) : this.state.reportType === 'State' ? (
                        <Select
                          style={{
                            width: '100%',
                          }}
                          id="stateSelect"
                          label="State"
                          outlined
                          native
                          displayEmpty
                          value={this.state.name}
                          onChange={(event) => {
                            this.setState({
                              checked: event.currentTarget.value,
                              name: event.currentTarget.name,
                              reportSelect: event.currentTarget.value,

                            });
                          }}
                        // labelId="State"
                        // inputProps={{
                        //   name: 'checked',
                        // }}
                        >
                          {states.map((state) => (
                            <option name={state} value={JSON.stringify(state)}>
                              {state}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Select
                          style={{ width: '100%' }}
                          label="Select a search type"
                          outlined
                          value={this.state.name}

                        ></Select>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={4}
                    alignContent="center"
                    justifyContent="center">
                    <Grid item xs={3}>
                      <Typography style={{ top: '20px', left: '0px' }}>
                        Select Date Search:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <RadioGroup
                        value={this.state.rv}
                        name="rv"
                        onChange={this.handleChange}
                        row
                        style={{ left: '34%', position: 'absolute' }}
                      >
                        <FormControlLabel
                          value="Day"
                          control={<Radio color="primary" />}
                          label="Last Day"
                        />
                        <FormControlLabel
                          value="LastWeek"
                          control={<Radio color="primary" />}
                          label="Last 7 Days"
                        />
                        <FormControlLabel
                          value="LastMonth"
                          control={<Radio color="primary" />}
                          label="Last 30 Days"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={4}
                    alignContent="center"
                    justifyContent="center"
                  >
                    <Grid item xs={4}>
                      {/* <div style={{ height: '50px' }}> </div> */}
                    </Grid>
                    <Grid container justifyContent="center" alignContent="center">
                      <Grid
                        item
                        xs={9}
                        style={{
                          borderStyle: 'solid',
                          left: '12%',
                          position: 'absolute',
                          width: '90%',
                          overflow: 'hidden'
                        }}
                      >
                        {this.state.testType === 'panel' ? (
                          <Typography>Panels</Typography>
                        ) : (
                          <Typography>Tests</Typography>
                        )}
                        <List
                          id="testList"
                          dense={true}
                          style={{
                            height: '180px',
                            position: 'reletive',
                            overflow: 'auto',
                            width: '745px',
                            top: '20px',
                          }}
                        >
                          {this.state.testType === 'subtest' ? (
                            <ListItem style={{ color: 'black', width: '100%' }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.selectAll}
                                    onClick={(e) => this.selectAll(e)}
                                    color="primary"
                                  />
                                }
                                label="Check All"
                              />
                            </ListItem>
                          ) : null}

                          {this.props.testList.length > 0 &&
                            this.state.testType === 'subtest'
                            ? this.props.testList.map((test) => {
                              if (test.testName !== null) {
                                return (
                                  <ListItem
                                    key={test.testName}
                                    style={{
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      color: 'black',
                                      width: '100%',
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            this.state[test.testName]
                                              ? this.state[test.testName]
                                              : false
                                          }
                                          onClick={() => {
                                            let testChecked =
                                              this.state.testChecked;
                                            if (!this.state[test.testName]) {
                                              testChecked.push(test);
                                            } else {
                                              testChecked = testChecked.filter(
                                                (check) =>
                                                  check.testName !== test.testName
                                              );
                                            }
                                            this.setState({
                                              [test.testName]:
                                                !this.state[test.testName],
                                              testChecked: testChecked,
                                            });
                                          }}
                                          name={test.testName}
                                          color="primary"
                                        />
                                      }
                                      label={test.testName}
                                    />
                                  </ListItem>
                                );
                              }
                            })
                            : null}
                          {this.props.testList.length > 0 &&
                            this.state.testType === 'panel'
                            ? this.props.panList.map((test) => {
                              if (test.testName !== null) {
                                let testCHK = 'checkd' + test.panelName;

                                return (
                                  <ListItem
                                    style={{
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      color: 'black',
                                      width: '100%',
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled={
                                            this.state.testChecked.length !== 0 &&
                                            !this.state[testCHK]
                                          }
                                          checked={
                                            this.state[testCHK]
                                              ? this.state[testCHK]
                                              : false
                                          }
                                          onClick={() => {
                                            let testChecked =
                                              this.state.testChecked;
                                            if (!this.state[testCHK]) {
                                              testChecked.push(test);
                                            } else {
                                              testChecked = testChecked.filter(
                                                (check) =>
                                                  check.panelName !== test.panelName
                                              );
                                            }
                                            this.setState({
                                              [test.panelName]:
                                                !this.state[test.panelName],
                                              [testCHK]: !this.state[testCHK],

                                              testChecked: testChecked,
                                            });
                                          }}
                                          name={test.panelName}
                                          color="primary"
                                        />
                                      }
                                      label={test.panelName}
                                    />
                                  </ListItem>
                                );
                              }
                            })
                            : null}

                          {this.props.panList.length <= 0 &&
                            this.state.testType === 'panel' ? (
                            <Typography variant="h2">No Panels</Typography>
                          ) : null}
                          {this.props.testList.length <= 0 &&
                            this.state.testType === 'subtest' ? (
                            <Typography variant="h2">No Tests</Typography>
                          ) : null}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )

            }


            {this.props.reportGenerating ? (
              <LinearProgress id="reportLoadBar"></LinearProgress>
            ) : null}

            <Grid
              container
              spacing={4}
              alignContent="center"
              justifyContent="center"
              id="reportButtonRow">
              <Grid item xs={3}>
                {this.state.updating ? (
                  <Button
                    id="createReportsButton"
                    variant="contained"
                    onClick={() => {
                      if (
                        this.isEmpty(this.state.reportName) ||
                        this.isEmpty(this.state.reportType) ||
                        this.isEmpty(this.state.reportSelect) ||
                        this.isEmpty(this.state.rv)
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all search fields',
                        });
                      } else {
                        this.buildJson();
                        this.setState({ QRDialog: false });
                      }
                    }}
                  >
                    Update Report
                  </Button>
                ) : (
                  <Button
                    id="createReportsButton"
                    variant="contained"
                    onClick={() => {
                      if (
                        this.isEmpty(this.state.reportName) ||
                        this.isEmpty(this.state.reportType) ||
                        // this.isEmpty(this.state.reportSelect) ||
                        this.isEmpty(this.state.rv)
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all search fields',
                        });
                      } else {
                        this.buildJson();
                        this.setState({ QRDialog: false });
                      }
                    }}
                  >
                    Add Report
                  </Button>
                )}
              </Grid>
            </Grid>


          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              icon="close"
              //cancel
              outlined
              onClick={() => {
                this.setState({
                  QRDialog: false,
                  reportType: '',
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let labTestTable = () => {
      let testsData = [];

      if (this.props.quickReports.length === 0) {
        return (
          <Typography id="noResult" use="headline6" style={{ right: '40%' }}>
            No Quick Reports
          </Typography>
        );
      } else {
        let testsData2 = this.props.quickReports.map((report) => {
          let options = JSON.parse(report.reportOptions);

          return (
            <DataTableRow key={report.qrID}>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>{options.reportName}</DataTableCell>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>{options.facName}</DataTableCell>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>{options.reportType}</DataTableCell>
              {options.frequency === 'LastWeek' ? (<DataTableCell style={{ backgroundColor: '#DBF1FF' }}>{"Last Week"}</DataTableCell>
              ) : options.frequency === 'LastMonth' ? (<DataTableCell style={{ backgroundColor: '#DBF1FF' }}>{"Last Month"}</DataTableCell>) : (<DataTableCell style={{ backgroundColor: '#DBF1FF' }}>{"Last Day"}</DataTableCell>
              )}
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                <Button
                  icon="edit"
                  label="Modify"
                  onClick={() => {
                    console.log(options)
                    this.setState({
                      QRDialog: true,
                      updating: true,
                      qrID: report.qrID,
                      qrType: options.reportType,
                      reportType: options.type,
                      reportName: options.reportName,
                      reportSelect: options.target,
                      rv: options.frequency,
                    });
                  }}
                >

                </Button>
                {this.props.genFacesheetLoading ? (
                  <CircularProgress id="genFacesheet"></CircularProgress>
                ) : null}
              </DataTableCell>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                <Button
                  icon="delete"
                  onClick={() => {
                    Swal.fire({
                      title:
                        'Are you sure you want to delete the quick report ' +
                        options.reportName +
                        ' ?',
                      text: 'You will not be able to revert this!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.props.deleteQuickReport(report.qrID);
                        Swal.fire(
                          'Deleted!',
                          'Your file has been deleted.',
                          'success'
                        );
                      }
                    });
                  }}
                >
                  Delete
                </Button>
                {this.props.genFacesheetLoading ? (
                  <CircularProgress id="genFacesheet"></CircularProgress>
                ) : null}
              </DataTableCell>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                <Button
                  icon="start"
                  onClick={() => {
                    let patientName = '';
                    let fac = '';
                    let start = new Date();
                    let end = new Date();
                    let body = {};
                    let critical = '';

                    if (options.type === 'Report By Facility') {
                      console.log("facility")
                      fac = JSON.parse(options.target).facilityName;
                      critical = options.critical;
                    } else if (options.type === 'Report By Patient') {
                      patientName = JSON.parse(options.target).patientLastName + ", " + JSON.parse(options.target).patientFirstName
                      // patientName = options.target;
                      critical = options.critical;

                    }
                    end = new Date();
                    switch (options.frequency) {
                      case 'Day':
                        start.setDate(end.getDate() - 1);
                        break;
                      case 'LastWeek':
                        start.setDate(end.getDate() - 7);
                        break;
                      case 'LastMonth':
                        start.setMonth(end.getMonth() - 1);
                        start.setDate(1);
                        break;
                      default:
                    }
                    if (options.reportType === 'Trends Report' || options.reportType === 'Metrics Report') {
                      let date = new Date(Date.parse(start));
                      let startDate = date.toISOString().slice(0, 10);
                      let date2 = new Date(Date.parse(end));
                      let endDate = date2.toISOString().slice(0, 10);
                      if (options.type === 'Patient' || options.type === 'Facility' || options.type === 'Group') {
                        body = {
                          dateFrom: startDate,
                          dateTo: endDate,
                          facilities: [JSON.parse(options.target)],
                          tests: options.tests,
                          searchType: options.type,
                          testType: 'subtest',
                          span: options.frequency,
                        };
                      }
                      else {
                        body = {
                          dateFrom: startDate,
                          dateTo: endDate,
                          facilities: options.target,
                          tests: options.tests,
                          searchType: options.type,
                          testType: 'subtest',
                          span: options.frequency,
                        };
                      }

                      if (options.reportType === 'Metrics Report') {
                        this.props.runTrendsReport(body)
                      }
                      else {
                        this.props.runMetricsReport(body)

                      }
                    }
                    else {
                      this.props.runReport(patientName, fac, start, end, options.critical);

                    }
                  }}
                >
                  Run
                </Button>
                {this.props.genFacesheetLoading ? (
                  <CircularProgress id="genFacesheet"></CircularProgress>
                ) : null}
              </DataTableCell>
            </DataTableRow>
          );
        });
        testsData.push(testsData2);
      }
      return testsData;
    };

    return (
      <div>
        <div id="physicianHead">
          <div id="physicians">
            <Typography use="headline4"> Quick Reports </Typography>
          </div>
        </div>
        <DataTable id="facilityLoginTable" stickyRows={1} stickyColumns={1}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Report Name
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Report Subject
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Report Type
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Frequency
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Modify
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Delete
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Run
                </DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>{labTestTable()}</DataTableBody>
          </DataTableContent>
        </DataTable>

        <div>
          <div>
            <Button
              id="addButton"
              variant="contained"
              onClick={() => {
                this.setState({
                  QRDialog: true,
                  qrType: '',
                  reportName: '',
                  reportType: '',
                  reportSelect: '',
                  name: '',
                });
                this.clearChecks();
              }}
            >
              Add Report
            </Button>
          </div>
        </div>
        {addQuickReportDialog()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCodes: () => dispatch(getCodesRequest()),
    getQuickReports: () => dispatch(getQuickReports()),
    labTestsList: (labID) => dispatch(labTestsList(labID)),
    getPanTests: (labID) => dispatch(getPanTestRequest(labID)),
    addQuickReport: (json) => dispatch(addQRRequest(json)),
    updateQuickReport: (json) => dispatch(updateQRRequest(json)),
    getFacilities: () => dispatch(getFacilityRequest()),
    patientNameList: () => dispatch(patientNameList()),
    runReport: (patientName, fac, start, end, critical) =>
      dispatch(
        printReportsByDate(patientName, encodeURIComponent(fac), start, end, critical)
      ),
    runMetricsReport: (body) =>
      dispatch(
        getQRMetricsRequest(body)
      ),
    runTrendsReport: (body) =>
      dispatch(
        getQRTrendsRequest(body)
      ),
    deleteQuickReport: (json) => dispatch(deleteQRRequest(json)),
    getSubtests: () => dispatch(getSubtestRequest()),
    getPanTests: () => dispatch(getPanTestRequest('')),
    getGroupsRequest: () => dispatch(getGroupsRequest()),
  };
};
const mapStateToProps = (state) => {
  return {
    facilities: state.report.facilities,
    patients: state.report.patients,
    codes: state.labMapper.codes,
    labs: state.labs.labs,
    panList: state.metrics.panTestList,
    quickReports: state.admin.quickReports,
    role: state.login.decodedJWT.role,
    username: state.login.decodedJWT.username,
    testList: state.metrics.testList,
    groups: state.metrics.groups,

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickReportsComponent);
