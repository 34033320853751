import {
  PHYSICIANLIST_SUCCESS,
  PHYSICIANLIST_FAIL,
  ADD_PHYSICIAN_SUCCESS,
  ADD_PHYSICIAN_FAIL,
  EDIT_PHYSICIAN_SUCCESS,
  EDIT_PHYSICIAN_FAIL,
  DELETE_PHYSICIAN_SUCCESS,
  DELETE_PHYSICIAN_FAIL,
} from '../constants/physicianConstatnts';

export const deletePhysicianSuccess = () => ({
  type: DELETE_PHYSICIAN_SUCCESS,
});

export const deletePhysicianFail = (error) => ({
  type: DELETE_PHYSICIAN_FAIL,
});

export const addPhysicianSuccess = () => ({
  type: ADD_PHYSICIAN_SUCCESS,
});

export const addPhysicianFail = (error) => ({
  type: ADD_PHYSICIAN_FAIL,
});

export const updatePhysicianSuccess = () => ({
  type: EDIT_PHYSICIAN_SUCCESS,
});

export const updatePhysicianFail = (error) => ({
  type: EDIT_PHYSICIAN_FAIL,
});
export const queryphysicianListSuccess = (physicianList) => ({
  type: PHYSICIANLIST_SUCCESS,
  physicianList: physicianList,
});

export const queryphysicianListFail = (error) => ({
  type: PHYSICIANLIST_FAIL,
});

export const getPhysiciansRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/getPhysicians');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryphysicianListSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryphysicianListFail(error));
      });
  };
};

export const createPhysicianRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/addPhysician');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then(() => {
        dispatch(addPhysicianSuccess());
      })
      .then(() => {
        dispatch(getPhysiciansRequest());
      })
      .catch((error) => {
        dispatch(ADD_PHYSICIAN_FAIL(error));
      });
  };
};

export const deletePhysicianRequest = (doctorNPI) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/deletePhysician'
    );
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ doctorNPI: doctorNPI }),
    })
      .then(() => {
        dispatch(deletePhysicianSuccess());
      })
      .then(() => {
        dispatch(getPhysiciansRequest());
      })
      .catch((error) => {
        dispatch(deletePhysicianFail(error));
      });
  };
};

export const updatePhysicianRequest = (json) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/updatePhysician'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then(() => {
        dispatch(updatePhysicianSuccess());
      })
      .then(() => {
        dispatch(getPhysiciansRequest());
      })
      .catch((error) => {
        dispatch(updatePhysicianFail(error));
      });
  };
};
