import { LAB_HISTORY_REQUEST_SUCCESS } from '../constants/labHistoryConstants';

const initialState = {
  orders: [],
  facilities: [],
};
const labHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAB_HISTORY_REQUEST_SUCCESS:
      return {
        ...state,
        orders: action.history,
      };
    default:
      return { ...state };
  }
};
export default labHistoryReducer;
