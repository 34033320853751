import { QUERY_LABS_SUCCESS } from '../constants/labManagementConstants';
const initialState = {
  labs: [],
};
const labManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUERY_LABS_SUCCESS:
      return {
        ...state,
        labs: action.labs,
      };
    default:
      return state;
  }
};
export default labManagementReducer;
