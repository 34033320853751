import React from 'react';

import {
  TopAppBar,
  TopAppBarTitle,
  TopAppBarRow,
  TopAppBarSection,
} from '@rmwc/top-app-bar';
import { Button } from '@rmwc/button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import { logout } from '../actions/loginActions';
import { withRouter } from 'react-router-dom';
import '../css/TopAppBarComponent.css';
import sabre_logo from '../images/TSalta_Transparent_SuperFrench.png';
import { Menu, MenuItem } from '@rmwc/menu';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/list/dist/mdc.list.css';
import '@rmwc/icon/icon.css';
import { resultClicked } from '../actions/resultsActions';
import { Typography } from '@material-ui/core';
import Dropdown from 'react-multilevel-dropdown';

class TopAppBarComponent extends React.Component {
  constructor() {
    super();

    this.state = {
      query: '',
      adminMenuOpen: false,
      managementList: false,
      adminList: false,
      profileList: false,
      role: '',
    };

    this.onNav = this.onNav.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLab = this.handleLab.bind(this);
    this.handlePatient = this.handlePatient.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
    this.handleResult = this.handleResult.bind(this);
    this.handleFacilities = this.handleFacilities.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleDash = this.handleDash.bind(this);
    this.handlePhysicans = this.handlePhysicans.bind(this);
    this.handleGroups = this.handleGroups.bind(this);
    this.handleFacilityLogins = this.handleFacilityLogins.bind(this);
    this.handleLabs = this.handleLabs.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
    this.handleFileHistory = this.handleFileHistory.bind(this);
    this.handleCodeMapper = this.handleCodeMapper.bind(this);
    this.handleQuickReports = this.handleQuickReports.bind(this);
    this.handleWatchList = this.handleWatchList.bind(this);
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      role: props.role,
    };
  }
  onNav() {
    this.props.toggleDrawer();
  }
  handleLogout() {
    document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; //remove jwt cookie
    this.props.logout(); //logout from redux state
    this.props.history.push('/login');
  }
  handleDash() {
    this.props.history.push('/app/dashboard');
  }
  handleLab() {
    this.props.history.push('/app/lab');
  }

  handlePatient() {
    this.props.history.push('/app/patient');
  }
  handleUsers() {
    this.props.history.push('/app/Admin/users');
  }
  handleProfile() {
    this.props.history.push('/app/Profile');
  }
  handleResult() {
    this.props.history.push('/app/result');
    this.props.resultClicked();
  }
  handleFacilities() {
    this.props.history.push('/app/admin/facilities');
  }
  handleReport() {
    this.props.history.push('/app/report');
  }
  handlePhysicans() {
    this.props.history.push('/app/physicans');
  }
  handleGroups() {
    this.props.history.push('/app/admin/groups');
  }
  handleFacilityLogins() {
    this.props.history.push('/app/admin/facilityLogins');
  }
  handleLabs() {
    this.props.history.push('/app/admin/labs');
  }
  handleProfile() {
    this.props.history.push('/app/profile');
  }
  handleFileHistory() {
    this.props.history.push('/app/admin/fileHistory');
  }
  handleCodeMapper() {
    this.props.history.push('/app/admin/codeMap');
  }
  handleQuickReports() {
    this.props.history.push('/app/quickReports');
  }
  handleWatchList() {
    this.props.history.push('/app/watchList');
  }
  handleHover = () => {
    if (this.state.hover === 'true') {
    }
    this.setState({ hover: true });
  };
  handleHoverExit = () => {
    this.setState({ hover: false });
  };

  render() {
    let managementMenu = () => {
      if (this.state.role === 'Tsalta Admin') {
        return (
          <Menu
            id="managementMenu"
            style={{ left: '82%', top: '5%' }}
            open={this.state.managementList}
            onClose={(evt) => {
              this.setState({ managementList: false });
            }}
          >
            <MenuItem onClick={this.handlePhysicans}>Physicians</MenuItem>
            <MenuItem onClick={this.handleFacilities}>Facilities</MenuItem>
            <MenuItem onClick={this.handleLabs}>Laboratory</MenuItem>
          </Menu>
        );
      } else {
        return (
          <Menu
            id="managementMenu"
            style={{ left: '85%', top: '5%' }}
            open={this.state.managementList}
            onClose={(evt) => {
              this.setState({ managementList: false });
            }}
          >
            <MenuItem onClick={this.handlePhysicans}>Physicians</MenuItem>
            <MenuItem onClick={this.handleFacilities}>Facilities</MenuItem>
            <MenuItem onClick={this.handleLabs}>Laboratory</MenuItem>
          </Menu>
        );
      }
    };
    let adminMenu = () => {
      return (
        <Menu
          id="adminMenu"
          style={{ left: '89%', top: '5%' }}
          open={this.state.adminList}
          onClose={(evt) => {
            this.setState({ adminList: false });
          }}
        >
          <MenuItem onClick={this.handleUsers}>Users</MenuItem>
          <MenuItem onClick={this.handleGroups}>Groups</MenuItem>
          <MenuItem onClick={this.handleFacilityLogins}>EMR Logins</MenuItem>
        </Menu>
      );
    };
    let profileMenu = () => {
      return (
        <Menu
          id="profileMenu"
          style={{ left: '93.5%', top: '5%' }}
          open={this.state.profileList}
          onClose={(evt) => {
            this.setState({ profileList: false });
          }}
        >
          <MenuItem onClick={this.handleProfile}>Profile</MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ adminList: true, profileList: true });
            }}
          >
            Admin
          </MenuItem>
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      );
    };

    return (
      <div id="drawerDiv">
        {this.state.role === 'Lab' ? (
          <TopAppBar
            style={{
              backgroundColor: 'white',
              borderBottom: 'double',
              borderColor: '#0D4DA4',
            }}
            fixed
          >
            <TopAppBarRow>
              <TopAppBarSection alignStart>
                <TopAppBarTitle>
                  <img
                    src={sabre_logo}
                    alt="top app bar logo"
                    id="top_app_bar_logo"
                    style={{ backgroundColor: 'clear' }}
                  />
                  {}
                </TopAppBarTitle>
              </TopAppBarSection>
              <TopAppBarSection alignEnd>
                <Button icon="home" onClick={this.handleDash}>
                  Home
                </Button>
                <Button icon="biotech" onClick={this.handleResult}>
                  Results
                </Button>
                <Button icon="build" onClick={this.handleReport}>
                  Tools
                </Button>
                <Button icon="logout" onClick={this.handleLogout}>
                  Logout
                </Button>
              </TopAppBarSection>
            </TopAppBarRow>
          </TopAppBar>
        ) : (
          <TopAppBar
            style={{
              backgroundColor: 'white',
              borderBottom: 'double',
              borderColor: '#0D4DA4',
            }}
            fixed
          >
            <TopAppBarRow>
              <TopAppBarSection alignStart>
                <TopAppBarTitle>
                  <img
                    src={sabre_logo}
                    alt="top app bar logo"
                    id="top_app_bar_logo"
                    style={{ backgroundColor: 'clear' }}
                  />
                  {}
                </TopAppBarTitle>
              </TopAppBarSection>
              <TopAppBarSection alignEnd>
                <Button icon="home" onClick={this.handleDash}>
                  Home
                </Button>
                <Button icon="biotech" onClick={this.handleResult}>
                  Results
                </Button>
                <Button icon="hotel" onClick={this.handlePatient}>
                  Patients
                </Button>
                <Button icon="build" onClick={this.handleReport}>
                  Tools
                </Button>

                <Button icon="edit">
                  Management
                  <Dropdown
                    id="profileMenu"
                    style={{
                      width: '150px',
                      height: '50px',
                      position: 'fixed',
                      top: '10px',
                      right: '75px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Dropdown.Item onClick={this.handlePhysicans}>
                      Physicians
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleFacilities}>
                      Facilities
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleLabs}>
                      Laboratory
                    </Dropdown.Item>
                  </Dropdown>
                </Button>
                {/* 
              <Button icon="face">
                <Dropdown
                  id="profileMenu"
                  style={{
                    width: '80px',
                    height: '100px',
                    position: 'fixed',
                    top: '10px',
                    right: '0px',
                    backgroundColor: 'transparent',
                  }}
                >
                  <Dropdown.Item>
                    Profile
                    <Dropdown.Submenu>
                      <Dropdown.Item onClick={this.handleProfile}>
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={this.handleWatchList}>
                        My Watchlist
                      </Dropdown.Item>
                    </Dropdown.Submenu>
                  </Dropdown.Item>
                  {this.state.role === 'administrator' ||
                    this.state.role === 'Tsalta Admin' ? (
                    <Dropdown.Item>
                      Admin
                      <Dropdown.Submenu>
                        <Dropdown.Item onClick={this.handleUsers}>
                          Users
                        </Dropdown.Item>
                        <Dropdown.Item onClick={this.handleGroups}>
                          Groups
                        </Dropdown.Item>
                        <Dropdown.Item onClick={this.handleFacilityLogins}>
                          EMR Logins
                        </Dropdown.Item>
                        <Dropdown.Item onClick={this.handleFileHistory}>
                          File History
                        </Dropdown.Item>
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleFacilities}>
                      Facilities
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleLabs}>
                      Laboratory
                    </Dropdown.Item>
                  </Dropdown>
                </Button> */}

                <Button icon="face">
                  <Dropdown
                    id="profileMenu"
                    style={{
                      width: '80px',
                      height: '100px',
                      position: 'fixed',
                      top: '10px',
                      right: '0px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Dropdown.Item>
                      Profile
                      <Dropdown.Submenu>
                        <Dropdown.Item onClick={this.handleProfile}>
                          My Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={this.handleWatchList}>
                          My Watchlist
                        </Dropdown.Item>
                        <Dropdown.Item onClick={this.handleQuickReports}>
                          My Reports
                        </Dropdown.Item>
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                    {this.state.role === 'administrator' ||
                    this.state.role === 'Tsalta Admin' ? (
                      <Dropdown.Item>
                        Admin
                        <Dropdown.Submenu>
                          <Dropdown.Item onClick={this.handleUsers}>
                            Users
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.handleGroups}>
                            Groups
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.handleFacilityLogins}>
                            EMR Logins
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.handleFileHistory}>
                            File History
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.handleCodeMapper}>
                            Lab Code Mapper
                          </Dropdown.Item>
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                    ) : null}
                    <Dropdown.Item onClick={this.handleLogout}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown>
                </Button>
              </TopAppBarSection>
            </TopAppBarRow>
          </TopAppBar>
        )}

        <Typography
          style={{
            top: '97%',
            left: '40%',
            position: 'absolute',
            fontSize: '13px',
            fontStyle: 'italic',
          }}
        >
          Driven by TSalta Solutions, Inc. All rights reserved
        </Typography>
        {managementMenu()}
        {adminMenu()}
        {profileMenu()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.login.decodedJWT.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),

    resultClicked: () => dispatch(resultClicked()),
  };
};

TopAppBarComponent.propTypes = {
  logout: PropTypes.func.isRequired,
};
export default withRouter(
  withCookies(connect(mapStateToProps, mapDispatchToProps)(TopAppBarComponent))
);
