import { PHYSICIANLIST_SUCCESS } from '../constants/physicianConstatnts';

const initialState = {
  physicians: [],
};
const physicianReducer = (state = initialState, action) => {
  switch (action.type) {
    case PHYSICIANLIST_SUCCESS:
      return { ...state, physicians: action.physicianList };
    default:
      return state;
  }
};
export default physicianReducer;
