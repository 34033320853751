import React from 'react';
import { connect } from 'react-redux';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Select } from '@rmwc/select';
import {
  Button,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Typography } from '@rmwc/typography';
import '../css/reportComponent.css';
import Dropzone from 'react-dropzone';
import { fromEvent } from 'file-selector';
import classNames from 'classnames';
import { TextField } from '@rmwc/textfield';
import {
  printReportsByDate,
  uploadFiles,
  reportCSV,
  fileDialogControl,
  generateLabReq,
  facesheetRequest,
  updateFacesheets,
  patientNameList,
} from '../actions/reportActions';
import { getLabsRequest } from '../actions/labManagementActions';
import { getFacilityRequest } from '../actions/userActions';
import '@rmwc/linear-progress/styles';
import { LinearProgress } from '@rmwc/linear-progress';
import '@rmwc/circular-progress/styles';
import { CircularProgress } from '@rmwc/circular-progress';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
} from '@rmwc/dialog';
import Swal from 'sweetalert2';
import { Checkbox } from '@rmwc/checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Switch } from '@rmwc/switch';

class ReportComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      searchStartDate: new Date().toISOString().slice(0, 10),
      searchEndDate: new Date().toISOString().slice(0, 10),
      reports: [],
      printDialog: false,
      facesheetDialog: false,
      files: [],
      facilities: [],
      facilityList: [],
      facesheetFacility: JSON.stringify('{}'),
      testType: [],
      checked: [],
      reportType: '',
      test: '',
      patients: [],
      labs: [],
      labChoiceModalOpen: false,
      rv: 'custom',
      rv2: 'allResults'
    };
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ [event.target.name]: value }, () => { });
  };

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();
    window.location.reload(false);
  }
  handleMetrics() {
    this.props.history.push('/app/metrics');
  }
  handleTrends() {
    this.props.history.push('/app/trends');
  }
  handlePA() {
    this.props.history.push('/app/patientAnalytics');
  }
  handleLabReq() {
    this.props.history.push('/app/labReq');
  }
  handleLabHistory() {
    this.props.history.push('/app/labHistory');
  }
  handleGeoReport() {
    this.props.history.push('/app/geoReport');
  }
  componentDidMount() {
    this.props.getFacilities();
    this.props.patientNameList();
    this.props.getLabsRequest();
  }
  componentDidUpdate() {
    this.render();
  }
  static getDerivedStateFromProps(props, state) {
    let facilityList = [];
    props.facilities.forEach((facility) => {
      facilityList.push(facility.facilityName);
    });
    let patientList = [];
    props.patients.forEach((patient) => {
      patientList.push(
        patient.patientLastName + ', ' + patient.patientFirstName
      );
    });
    let labList = [];
    props.labs.forEach((lab) => {
      labList.push(lab.labName);
    });
    return {
      ...state,
      facilities: props.facilities,
      facilityList: facilityList,
      patients: props.patients,
      patientList: patientList,
      labList: labList,
      role: props.role,
    };
  }

  onDrop(files) {
    this.setState({ files });
  }

  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  createChecked(formType) {
    let temp = [];
    if (this.state.checked === 'Respiratory') {
      if (this.state.checkbox1 === true) {
        temp.push('Comprehensive Respiratory Panel');
      }
      if (this.state.checkbox2 === true) {
        temp.push('ABR');
      }
      if (this.state.checkbox3 === true) {
        temp.push('COVID-19');
      }
      if (this.state.checkbox4 === true) {
        temp.push('Reflux');
      }
      this.setState({ testType: temp }, () => {
        this.submitForm(formType);
      });
    }
    if (this.state.checked === 'Urine') {
      temp = [];
      temp.push('Urine');
      this.setState({ testType: temp }, () => {
        this.submitForm(formType);
      });
    }
    if (this.state.checked === 'Wound') {
      temp = [];
      temp.push('Wound');
      this.setState({ testType: temp }, () => {
        this.submitForm(formType);
      });
    }
  }

  submitForm(formType) {
    if (formType === 'patient')
      this.props.generateLabReq(
        this.state.reportSelect,
        '',
        this.state.testType,
        this.state.labName
      );
    if (formType === 'facility')
      this.props.generateLabReq(
        '',
        this.state.reportSelect,
        this.state.testType,
        this.state.labName
      );
  }
  search = () => {
    let body = {};

    let startDate;
    let endDate;
    let date2;
    let date;

    if (this.state.rv === 'monthRange' || this.state.rv === 'custom') {
      date = new Date(Date.parse(this.state.reportStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(Date.parse(this.state.reportEndDate));
      endDate = date2.toISOString().slice(0, 10);
    } else if (this.state.rv === 'month') {
      date = new Date(Date.parse(this.state.reportStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(date.toUTCString());
      date2.setUTCMonth(date2.getUTCMonth() + 1);
      endDate = date2.toISOString().slice(0, 10);
    }
    // else if (this.state.rv === 'day') {
    //   date = new Date(Date.parse(this.state.trendStartDate));
    //   startDate = date.toISOString().slice(0, 10);
    //   date2 = new Date(date.toUTCString());
    //   date2.setUTCMonth(date2.getUTCMonth() + 1);
    //   endDate = date.toISOString().slice(0, 10);
    // }
    body = {
      dateFrom: startDate,
      dateTo: endDate,
      facilities: [JSON.parse(this.state.checked)],
      tests: this.state.testChecked,
      searchType: this.state.searchBy,
      span: this.state.rv,
    };
    this.props.getMetrics(body);
    this.setState({ searchModalOpen: false });
  };

  render() {
    let LCMDialog = () => {
      return (
        <Dialog
          id="LCMDialog"
          open={this.state.labChoiceModalOpen}
          onClose={(evt) => {
            this.setState({ labChoiceModalOpen: false });
          }}
        >
          <DialogTitle
            id="reportTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            Lab Requests
          </DialogTitle>
          <DialogContent
            id="LCMDialogContent"
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell>
                  <Button
                    variant="outlined"
                    id="requestButton"
                    style={{
                      background:
                        'linear-gradient(0.25turn, #0990EC, #5CB7F4, #95D4FF)',
                    }}
                    onClick={() => {
                      this.handleLabReq();
                    }}
                  >
                    Create Lab Request
                  </Button>
                </GridCell>
                <GridCell>
                  <Button
                    variant="outlined"
                    id="historyButton"
                    style={{
                      background:
                        'linear-gradient(0.25turn, #0990EC, #5CB7F4, #95D4FF)',
                    }}
                    onClick={() => {
                      this.handleLabHistory();
                    }}
                  >
                    View Order History
                  </Button>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ labChoiceModalOpen: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    const files = this.state.files.map((f) => (
      <li key={f.name}>
        {f.path} - {f.size} bytes
      </li>
    ));
    let labTests = () => {
      if (this.state.labName === 'Mako')
        return (
          <GridRow id="testTypeRow">
            <GridCell span={3}>
              <Typography>Select Test Type:</Typography>
            </GridCell>
            <GridCell
              span={9}
              style={{
                left: '29%',
                position: 'absolute',
                width: '106%',
              }}
            >
              <Select
                label="Test Type"
                outlined
                options={['Blood', 'Urine', 'Toxicology']}
                value={this.state.test}
                name="test"
                onChange={(event) => {
                  let value = event.currentTarget.value;
                  this.setState({}, () => {
                    this.setState({ test: value, checked: value });
                  });
                }}
              ></Select>
            </GridCell>
          </GridRow>
        );
      else {
        return (
          <GridRow id="testTypeRow">
            <GridCell span={3}>
              <Typography>Select Test Type:</Typography>
            </GridCell>
            <GridCell
              span={9}
              style={{
                left: '29%',
                position: 'absolute',
                width: '106%',
              }}
            >
              <Select
                label="Test Type"
                outlined
                options={['Respiratory', 'Urine', 'Wound']}
                value={this.state.test}
                name="test"
                onChange={(event) => {
                  let value = event.currentTarget.value;
                  this.setState({}, () => {
                    this.setState({ test: value, checked: value });
                  });
                }}
              ></Select>
            </GridCell>
          </GridRow>
        );
      }
    };
    let testType = () => {
      if (this.state.test === 'Respiratory')
        return (
          <Grid>
            <GridRow id="testRow">
              <GridCell span={3}>Select Test Type:</GridCell>

              <GridCell span={9} style={{ position: 'fixed', left: '18%' }}>
                <div
                  id="selections"
                  style={{
                    border: '2px solid black',
                    width: '75%',
                    height: '30%',
                  }}
                >
                  <Checkbox
                    name="checkbox1"
                    checked={this.state.checkbox1}
                    style={{ color: 'black' }}
                    disabled={this.state.checked !== 'Respiratory'}
                    onChange={(e) => {
                      this.setState({
                        [e.target.name]: e.target.checked,
                      });
                    }}
                  >
                    <label id="resTestLabel">
                      {' '}
                      Comprehensive Respiratory Panel{' '}
                    </label>
                  </Checkbox>

                  <Checkbox
                    name="checkbox2"
                    checked={this.state.checkbox2}
                    style={{ color: 'black' }}
                    disabled={this.state.checked !== 'Respiratory'}
                    onChange={(e) => {
                      this.setState({
                        [e.target.name]: e.target.checked,
                      });
                    }}
                  >
                    <label id="resTestLabel">ABR</label>
                  </Checkbox>

                  <Checkbox
                    name="checkbox3"
                    checked={this.state.checkbox3}
                    style={{ color: 'black' }}
                    disabled={this.state.checked !== 'Respiratory'}
                    onChange={(e) => {
                      this.setState({
                        [e.target.name]: e.target.checked,
                      });
                    }}
                  >
                    <label id="resTestLabel">COVID-19 Only </label>
                  </Checkbox>

                  <Checkbox
                    name="checkbox4"
                    checked={this.state.checkbox4}
                    style={{ color: 'black' }}
                    disabled={this.state.checked !== 'Respiratory'}
                    onChange={(e) => {
                      this.setState({
                        [e.target.name]: e.target.checked,
                      });
                    }}
                  >
                    <label id="resTestLabel">Covid-19 + Reflux</label>
                  </Checkbox>
                </div>
              </GridCell>
            </GridRow>
            <GridRow id="requestTypeRow">
              <GridCell span={3}>
                <Typography>Select Report Type:</Typography>
              </GridCell>
              <GridCell
                span={9}
                style={{ position: 'absolute', left: '26%', width: '94%' }}
              >
                <Select
                  label="Report Type"
                  outlined
                  options={['Request By Facility', 'Request By Patient']}
                  value={this.state.reportType}
                  name="reportType"
                  onChange={(event) => {
                    let value = event.currentTarget.value;
                    this.setState({}, () => {
                      this.setState({ reportType: value });
                    });
                  }}
                ></Select>
              </GridCell>
            </GridRow>
          </Grid>
        );
      if (this.state.test === 'Wound' || this.state.test === 'Urine')
        return (
          <GridRow id="uwRequestTypeRow">
            <GridCell span={3}>
              <Typography>Select Report Type:</Typography>
            </GridCell>
            <GridCell
              span={9}
              style={{ position: 'absolute', left: '25.5%', width: '94.5%' }}
            >
              <Select
                label="Report Type"
                outlined
                options={['Request By Facility', 'Request By Patient']}
                value={this.state.reportType}
                name="reportType"
                onChange={(event) => {
                  let value = event.currentTarget.value;
                  this.setState({}, () => {
                    this.setState({ reportType: value });
                  });
                }}
              ></Select>
            </GridCell>
          </GridRow>
        );
    };
    let printDialog = () => {
      return (
        <Dialog
          id="printReportDialog"
          open={this.state.printDialog}
          onClose={(evt) => {
            this.setState({ printDialog: false });
          }}
        >
          <DialogTitle
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
            id="reportTitle"
          >
            Print Reports
          </DialogTitle>
          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
              width: '100%',
            }}
            id="printDialogContent"
          >
            <GridRow id="reportTypeRow">
              <GridCell span={3}>
                <Typography>Select Report Type:</Typography>
              </GridCell>
              <GridCell
                span={9}
                style={{ position: 'absolute', left: '26%', width: '90%' }}
              >
                <Select
                  label="Report Type"
                  outlined
                  options={[
                    'Report By Facility',
                    'Report By Patient',
                  ]}
                  value={this.state.reportType}
                  name="reportType"
                  onChange={(event) => {
                    let value = event.currentTarget.value;
                    this.setState({}, () => {
                      this.setState({ reportType: value });
                    });
                  }}
                ></Select>
              </GridCell>
            </GridRow>
            <GridRow id="patientNameRow">
              <GridCell span={3}>
                {this.state.reportType === 'Report By Patient' ? (
                  <Typography>Select Patient Name:</Typography>
                ) : (
                  <Typography>Select Facility Name:</Typography>
                )}
              </GridCell>
              <GridCell
                span={9}
                style={{ width: '85%', left: '23.5%', position: 'absolute' }}
              >
                {this.state.reportType === 'Report By Patient' ? (
                  <Select
                    label="Patient Name"
                    outlined
                    options={this.state.patientList}
                    onChange={(event) => {
                      this.setState({
                        reportSelect: event.currentTarget.value,
                      });
                    }}
                  ></Select>
                ) : (
                  <Select
                    label="Facility Name"
                    outlined
                    options={this.state.facilityList}
                    onChange={(event) => {
                      this.setState({
                        reportSelect: event.currentTarget.value,
                      });
                    }}
                  ></Select>
                )}
              </GridCell>
            </GridRow>
            <GridRow id="reportDateRow">
              <GridCell span={3}>
                <Typography>Select Date Search:</Typography>
              </GridCell>
              <GridCell span={9}>
                <RadioGroup
                  value={this.state.rv}
                  name="rv"
                  onChange={this.handleChange}
                  row
                >
                  <FormControlLabel
                    value="custom"
                    control={<Radio color="primary" />}
                    label="Day Range"
                  />
                  <FormControlLabel
                    value="monthRange"
                    control={<Radio color="primary" />}
                    label="Month Range"
                  />
                  <FormControlLabel
                    value="month"
                    control={<Radio color="primary" />}
                    label="Month"
                  />
                </RadioGroup>
              </GridCell>
            </GridRow>
            <GridRow id="reportCriticalRow">
              <GridCell span={3}>
                <Typography>Select Result Type:</Typography>
              </GridCell>
              <GridCell span={9}>
                <RadioGroup value={this.state.rv2}
                  name="rv2"
                  onChange={this.handleChange}
                  row>
                  <FormControlLabel
                    value="allResults"
                    control={<Radio color="primary" />}
                    label="All Results"
                  />
                  <FormControlLabel
                    value="critical"
                    control={<Radio color="primary" />}
                    label="Critical Results Only"
                  />

                </RadioGroup>
              </GridCell>
            </GridRow>
            {/* <GridRow id="reportDateSelectRow">
              <GridCell span={3}>
                <Typography>Select Date Range:</Typography>
              </GridCell> */}

            {this.state.rv === 'custom' ? (
              <GridRow style={{ top: '60%', position: 'absolute' }}>
                <GridCell span={6} style={{ position: 'fixed', left: '20%' }}>
                  <Typography>Start Date</Typography>
                  <form noValidate>
                    <TextField
                      style={{ width: '275px' }}
                      outlined
                      id="searchStartDateCalender"
                      name="reportStartDate"
                      type="date"
                      value={this.state.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handleChange}
                    />
                  </form>
                </GridCell>
                <GridCell span={6} style={{ position: 'fixed', left: '51%' }}>
                  <Typography>End Date</Typography>
                  <form noValidate>
                    <TextField
                      style={{ width: '275px' }}
                      outlined
                      id="searchEndDateCalender"
                      name="reportEndDate"
                      type="date"
                      value={this.state.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handleChange}
                    />
                  </form>
                </GridCell>
              </GridRow>
            ) : this.state.rv === 'monthRange' ? (<GridRow style={{ top: '60%', position: 'absolute' }}>
              <GridCell span={6} style={{ position: 'fixed', left: '20%' }}>
                <Typography>Start Month</Typography>
                <form noValidate>
                  <TextField
                    style={{ width: '275px' }}
                    outlined
                    id="searchStartDateCalender"
                    name="reportStartDate"
                    type="month"
                    value={this.state.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleChange}
                  />
                </form>
              </GridCell>
              <GridCell span={6} style={{ position: 'fixed', left: '51%' }}>
                <Typography>End Month</Typography>
                <form noValidate>
                  <TextField
                    style={{ width: '275px' }}
                    outlined
                    id="searchEndDateCalender"
                    name="reportEndDate"
                    type="month"
                    value={this.state.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleChange}
                  />
                </form>
              </GridCell>
            </GridRow>) : this.state.rv === 'month' ? (<GridRow style={{ top: '60%', position: 'absolute' }}>
              <GridCell span={6} style={{ position: 'fixed', left: '20%' }}>
                <Typography>Month</Typography>
                <form noValidate>
                  <TextField
                    style={{ width: '275px' }}
                    outlined
                    id="searchStartDateCalender"
                    name="reportStartDate"
                    type="month"
                    value={this.state.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleChange}
                  />
                </form>
              </GridCell>
            </GridRow>) : null}
            {/* </GridRow> */}

            {this.props.reportGenerating ? (
              <LinearProgress id="reportLoadBar"></LinearProgress>
            ) : null}

            <GridRow id="reportButtonRow">
              <GridCell>
                <Button
                  id="createReportsButton"
                  variant="contained"
                  disabled={this.props.reportGenerating}
                  onClick={() => {
                    if (this.state.reportType === 'Report By Facility') {
                      let startDate;
                      let endDate;
                      let date2;
                      let date;
                      if (
                        this.isEmpty(this.state.reportType) ||
                        this.isEmpty(this.state.reportSelect) ||
                        this.isEmpty(this.state.reportStartDate) ||
                        (this.isEmpty(this.state.reportEndDate) &&
                          this.state.rv !== 'month')
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all search fields',
                        });
                      } else {
                        if (this.state.rv === 'custom') {
                          this.props.printReportsByDate(
                            '',
                            this.state.reportSelect,
                            new Date(this.state.reportStartDate).getTime(),
                            new Date(this.state.reportEndDate).getTime(),
                            this.state.rv2,
                          );
                        }
                        else if (this.state.rv === 'monthRange') {
                          date = new Date(Date.parse(this.state.reportStartDate));
                          startDate = date.toISOString().slice(0, 10);
                          date2 = new Date(Date.parse(this.state.reportEndDate));
                          date2.setUTCMonth(date2.getUTCMonth() + 1);
                          date2.setDate(date2.getDate() - 1)
                          endDate = date2.toISOString().slice(0, 10);
                          this.props.printReportsByDate(
                            '',
                            this.state.reportSelect,
                            startDate,
                            endDate,
                            this.state.rv2,
                          );
                        }
                        else if (this.state.rv === 'month') {
                          date = new Date(Date.parse(this.state.reportStartDate));
                          startDate = date.toISOString().slice(0, 10);
                          date2 = new Date(Date.parse(this.state.reportStartDate));
                          date2.setUTCMonth(date2.getUTCMonth() + 1);
                          date2.setDate(date2.getDate() - 1)
                          endDate = date2.toISOString().slice(0, 10);
                          this.props.printReportsByDate(
                            '',
                            this.state.reportSelect,
                            startDate,
                            endDate,
                            this.state.rv2,
                          );
                        }
                      }
                    }
                    if (this.state.reportType === 'Report By Patient') {
                      let startDate;
                      let endDate;
                      let date2;
                      let date;
                      if (
                        this.isEmpty(this.state.reportType) ||
                        this.isEmpty(this.state.reportSelect) ||
                        this.isEmpty(this.state.reportStartDate) ||
                        (this.isEmpty(this.state.reportEndDate) &&
                          this.state.rv !== 'month')
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all search fields',
                        });
                      } else {
                        if (this.state.rv === 'custom') {
                          this.props.printReportsByDate(
                            this.state.reportSelect,
                            '',
                            new Date(this.state.reportStartDate).getTime(),
                            new Date(this.state.reportEndDate).getTime(),
                            this.state.rv2,

                          );
                        }
                        else if (this.state.rv === 'monthRange') {
                          date = new Date(Date.parse(this.state.reportStartDate));
                          startDate = date.toISOString().slice(0, 10);
                          date2 = new Date(Date.parse(this.state.reportEndDate));
                          date2.setUTCMonth(date2.getUTCMonth() + 1);
                          date2.setDate(date2.getDate() - 1)
                          endDate = date2.toISOString().slice(0, 10);
                          this.props.printReportsByDate(
                            this.state.reportSelect,
                            '',
                            startDate,
                            endDate,
                            this.state.rv2,
                          );
                        }
                        else if (this.state.rv === 'month') {
                          date = new Date(Date.parse(this.state.reportStartDate));
                          startDate = date.toISOString().slice(0, 10);
                          date2 = new Date(Date.parse(this.state.reportStartDate));
                          date2.setUTCMonth(date2.getUTCMonth() + 1);
                          date2.setDate(date2.getDate() - 1)
                          endDate = date2.toISOString().slice(0, 10);
                          this.props.printReportsByDate(
                            this.state.reportSelect,
                            '',
                            startDate,
                            endDate,
                            this.state.rv2,

                          );
                        }
                      }
                    }
                  }}
                >
                  Generate Report
                </Button>
              </GridCell>
            </GridRow>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({
                  printDialog: false,
                  testType: '',
                  test: '',
                  reportType: '',
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let labReqDialog = () => {
      return (
        <div id="labReqTest">
          <Dialog
            id="labReqDialog"
            open={this.state.labReqDialog}
            onClose={(evt) => {
              this.setState({ labReqDialog: false });
            }}
          >
            <DialogTitle id="reportTitle">Generate Lab Request</DialogTitle>

            <DialogContent id="labReqDialogContent">
              <GridRow id="labNameRow">
                <GridCell span={3}>
                  <Typography>Select Lab Name:</Typography>
                </GridCell>
                <GridCell
                  span={9}
                  style={{
                    left: '28.75%',
                    position: 'absolute',
                    width: '102%',
                  }}
                >
                  <Select
                    placeholder="Lab Name"
                    outlined
                    options={this.state.labList}
                    value={this.state.labName}
                    name="test"
                    onChange={(event) => {
                      let value = event.currentTarget.value;
                      this.setState({}, () => {
                        this.setState({ labName: value });
                      });
                    }}
                  ></Select>
                </GridCell>
              </GridRow>
              {labTests()}
              {testType()}
              {this.props.reportGenerating ? (
                <LinearProgress id="reportLoadBar"></LinearProgress>
              ) : null}

              <GridRow id="reportButtonRow">
                <GridCell>
                  <Button
                    id="createReportButton"
                    variant="contained"
                    disabled={this.props.reportGenerating}
                    onClick={() => {
                      if (
                        this.state.reportSelect == null ||
                        (this.state.checked !== 'Respiratory' &&
                          this.state.checked !== 'Urine' &&
                          this.state.checked !== 'Wound')
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all test request fields',
                        });
                      } else if (
                        this.state.checked === 'Respiratory' &&
                        this.state.checkbox1 === false &&
                        this.state.checkbox2 === false &&
                        this.state.checkbox3 === false &&
                        this.state.checkbox4 === false
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please make respiratory test selection',
                        });
                      } else {
                        if (this.state.reportType === 'Request By Facility') {
                          this.createChecked('facility');
                        } else if (
                          this.state.reportType === 'Request By Patient'
                        ) {
                          this.createChecked('patient');
                        }
                      }
                    }}
                  >
                    Generate Request
                  </Button>
                </GridCell>
              </GridRow>
            </DialogContent>
            <DialogActions>
              <DialogButton
                icon="close"
                cancel
                outlined
                onClick={() => {
                  this.setState({
                    labReqDialog: false,
                    testType: '',
                    test: '',
                    reportType: '',
                  });
                }}
              >
                Close
              </DialogButton>
            </DialogActions>
          </Dialog>
        </div>
      );
    };
    let successDialog = () => {
      return (
        <Dialog
          id="successDialog"
          open={this.props.labSuccessDialogOpen}
          onOpen={() => {
            this.setState({ uploadDialog: false });
          }}
          onClose={(evt) => {
            this.props.fileDialogControl(false);
          }}
        >
          <DialogContent>
            <Typography>Upload Success!</Typography>
          </DialogContent>
          <DialogActions>
            <DialogButton
              action="close"
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.props.fileDialogControl(false);
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let uploadDialog = () => {
      return (
        <Dialog
          id="fileUploadDialog"
          open={this.state.uploadDialog}
          onClose={(evt) => {
            this.setState({ uploadDialog: false, files: [] });
          }}
        >
          <DialogContent
            id="fileUploadDialogContent"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <Typography use="headline4">Upload Files</Typography>
            <form
              id="uploadForm"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.uploadLab(
                  this.state.fileType,
                  this.state.files,
                  this.state.facesheetFacility
                );
              }}
              style={{ paddingTop: '20px' }}
            >
              <Select
                outlined
                onChange={(evt) =>
                  this.setState({
                    fileType: evt.target.value,
                  })
                }
                value={this.state.fileType}
                label="Format"
                options={[
                  {
                    label: 'HL7',
                    options: [
                      {
                        label: 'HL7',
                        value: 'HL7',
                      },
                    ],
                  },
                  {
                    label: 'Diax',
                    options: [
                      {
                        label: 'JSON',
                        value: 'Diax',
                      },
                    ],
                  },
                  {
                    label: 'Facesheets',
                    options: [
                      {
                        label: 'Facesheet',
                        value: 'Facesheet',
                      },
                    ],
                  },
                ]}
              />
              {this.state.fileType === 'Facesheet' ? (
                <div style={{ paddingTop: '10px' }}>
                  <Select
                    label="Facility"
                    outlined
                    onChange={(event) => {
                      this.setState({
                        facesheetFacility: event.currentTarget.value,
                      });
                    }}
                  >
                    {this.state.facilities.map((facility) => {
                      return (
                        <option value={JSON.stringify(facility)}>
                          {facility.facilityName}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              ) : null}
              <section>
                <div style={{ paddingTop: '20px' }} id="dropzoneDropDiv--outer">
                  <Dropzone //File drop zone for dragging and dropping files to upload
                    id="dropzone"
                    getDataTransferItems={(evt) => fromEvent(evt)}
                    onDrop={this.onDrop.bind(this)}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => {
                      return (
                        <div
                          style={
                            isDragActive
                              ? {
                                height: '100px',
                                boxSizing: 'border-box',
                                borderWidth: '3px',
                                borderStyle: 'dotted',
                                borderColor: 'var(--mdc-theme-primary)',
                                textAlign: 'center',
                                lineHeight: '100px',
                              }
                              : {
                                height: '100px',
                                boxSizing: 'border-box',
                                borderWidth: '2px',
                                borderStyle: 'dotted',
                                borderColor: 'gray',
                                textAlign: 'center',
                                lineHeight: '100px',
                              }
                          }
                          {...getRootProps()}
                          className={classNames('dropzone', {
                            'dropzone--isActive': isDragActive,
                          })}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p id="dropzoneText">Drop files here...</p>
                          ) : (
                            <p id="dropzoneText">
                              Drop some files here, or click to select files to
                              upload.
                            </p>
                          )}
                        </div>
                      );
                    }}
                  </Dropzone>
                </div>
                <aside>
                  <h4>{files.length > 0 ? 'Files' : ''}</h4>
                  <ul style={{ maxHeight: '200px', overflow: 'auto' }}>
                    {files}
                  </ul>
                </aside>
              </section>
            </form>
            {this.props.labUploading ? <LinearProgress></LinearProgress> : null}
            <GridRow id="reportButtonRow">
              <GridCell>
                <Button
                  id="createReportButton"
                  unelevated
                  form="uploadForm"
                  disabled={this.props.labUploading}
                  icon="file_upload"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.uploadLab(
                      this.state.fileType,
                      this.state.files,
                      this.state.facesheetFacility
                    );
                  }}
                >
                  Upload
                </Button>
              </GridCell>
            </GridRow>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ uploadDialog: false, files: [] });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let facesheetDialog = () => {
      return (
        <Dialog
          id="facesheetDialog"
          open={this.state.facesheetDialog}
          onClose={(evt) => {
            this.setState({ facesheetDialog: false });
          }}
        >
          <DialogContent id="facesheetDialogContent">
            <div id="facesheetHead">
              <Typography use="headline4">Facility Facesheet Tools</Typography>
            </div>
            <div id="facesheetTitle">
              {' '}
              <Typography use="headline5">Get Facility Facesheets</Typography>
            </div>
            <div id="facesheetContent">
              <div id="facesheetSelect" style={{ width: '50%', right: '0%' }}>
                <Select
                  label="Facility"
                  outlined
                  options={this.state.facilityList}
                  onChange={(event) => {
                    this.setState({
                      facesheetSelect: event.currentTarget.value,
                    });
                  }}
                ></Select>
              </div>
              <div
                id="facesheetSelectButton"
                style={{ width: '50%', left: '53%' }}
              >
                {' '}
                <Button
                  id="facesheetSelectButton"
                  disabled={this.props.genFacesheetLoading}
                  onClick={() => {
                    this.props.facesheetRequest(this.state.facesheetSelect);
                  }}
                >
                  Generate Facesheets
                </Button>
                {this.props.genFacesheetLoading ? (
                  <CircularProgress id="genFacesheet"></CircularProgress>
                ) : null}
              </div>
            </div>
            <div id="facesheetUpdate">
              <div id="updateFacesheetTitle">
                {' '}
                <Typography use="headline5">
                  Update Facility Facesheets
                </Typography>
              </div>
              <div
                id="facesheetUpdateSelect"
                style={{ width: '50%', right: '0%' }}
              >
                <Select
                  label="Facility"
                  outlined
                  options={this.state.facilityList}
                  onChange={(event) => {
                    this.setState({
                      facesheetSelect: event.currentTarget.value,
                    });
                  }}
                ></Select>
              </div>
              <div
                id="facesheetUpdateSelectButton"
                style={{ width: '50%', left: '53%' }}
              >
                {' '}
                <Button
                  id="facesheetUpdateSelectButton"
                  onClick={() => {
                    this.props.updateFacesheets(
                      this.state.facesheetSelect,
                      'facility'
                    );
                  }}
                >
                  Update Facesheets
                </Button>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ facesheetDialog: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    return (
      <div>
        {LCMDialog()}
        {printDialog()}
        {uploadDialog()}
        {successDialog()}
        {labReqDialog()}
        {facesheetDialog()}
        <div id="toolsHead">
          <Typography id="tools" use="headline4">
            {' '}
            Tools{' '}
          </Typography>
        </div>
        <div id="toolList">
          <Grid style={{ left: '18%', position: 'absolute' }}>
            {this.state.role !== 'Lab' ? (
              <GridCell span={4}>
                <Card
                  style={{
                    width: '100%',
                    border: 'solid',
                    borderColor: 'white',
                    backgroundColor: '#DBF1FF',
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <div>
                        <Typography gutterBottom use="headline5" component="h2">
                          Lab Requests
                        </Typography>
                      </div>
                      <Typography
                        use="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Create a new lab request or view lab request history
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        this.handleLabReq();
                      }}
                    >
                      Create
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        this.handleLabHistory();
                      }}
                    >
                      View History
                    </Button>
                  </CardActions>
                </Card>
              </GridCell>
            ) : null}
            {this.state.role !== 'Lab' ? (
              <GridCell span={4}>
                <Card
                  style={{
                    border: 'solid',
                    borderColor: 'white',
                    backgroundColor: '#DBF1FF',
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <div>
                        <Typography gutterBottom use="headline5" component="h2">
                          Reports
                        </Typography>
                      </div>
                      <Typography
                        use="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Create a detailed TSalta lab report by patient or
                        facility
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        this.setState({ printDialog: true });
                      }}
                    >
                      Create
                    </Button>
                  </CardActions>
                </Card>
              </GridCell>
            ) : null}

            <GridCell span={4}>
              <Card
                style={{
                  border: 'solid',
                  borderColor: 'white',
                  backgroundColor: '#DBF1FF',
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <div>
                      <Typography gutterBottom use="headline5" component="h2">
                        Upload
                      </Typography>
                    </div>
                    <Typography use="body2" color="textSecondary" component="p">
                      Upload HL7 or JSON lab result files manually
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        uploadDialog: true,
                        testType: '',
                        test: '',
                        reportType: '',
                      });
                    }}
                  >
                    Upload
                  </Button>
                </CardActions>
              </Card>
            </GridCell>
            {this.state.role !== 'Lab' ? (
              <GridCell span={4}>
                <Card
                  style={{
                    border: 'solid',
                    borderColor: 'white',
                    backgroundColor: '#DBF1FF',
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <div>
                        <Typography gutterBottom use="headline5" component="h2">
                          Trend Analysis
                        </Typography>
                      </div>
                      <Typography
                        use="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Summary of test results over time
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        this.handleMetrics();
                      }}
                    >
                      Create
                    </Button>
                  </CardActions>
                </Card>
              </GridCell>
            ) : null}
            {this.state.role !== 'Lab' ? (
              <GridCell span={4}>
                <Card
                  style={{
                    border: 'solid',
                    borderColor: 'white',
                    backgroundColor: '#DBF1FF',
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <div>
                        <Typography gutterBottom use="headline5" component="h2">
                          Patient Analysis
                        </Typography>
                      </div>
                      <Typography
                        use="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Patient test result values over time
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        this.handlePA();
                      }}
                    >
                      Create
                    </Button>
                  </CardActions>
                </Card>
              </GridCell>
            ) : null}

            {this.state.role !== 'Lab' ? (
              <GridCell span={4}>
                <Card
                  style={{
                    border: 'solid',
                    borderColor: 'white',
                    backgroundColor: '#DBF1FF',
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <div>
                        <Typography gutterBottom use="headline5" component="h2">
                          Metrics Analysis
                        </Typography>
                      </div>
                      <Typography
                        use="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Detailed test summary tests or test panels
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        this.handleTrends();
                      }}
                    >
                      Create
                    </Button>
                  </CardActions>
                </Card>
              </GridCell>
            ) : null}
          </Grid>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  decodedJWT: state.login.decodedJWT,
  reports: state.result.reports,
  labUploadSuccess: state.report.labUploadSuccessful,
  labUploading: state.report.labUploading,
  labSuccessDialogOpen: state.report.labSuccessDialogOpen,
  facilities: state.report.facilities,
  facilityReportLoading: state.report.facilityReportLoading,
  patientReportLoading: state.report.patientReportLoading,
  labReqLoading: state.report.labReqLoading,
  patientReqLoading: state.report.patientReqLoading,
  genFacesheetLoading: state.report.genFacesheetLoading,
  reportGenerating: state.report.reportGenerating,
  patients: state.report.patients,
  labs: state.labs.labs,
  role: state.login.decodedJWT.role,
});

const mapDispatchToProps = (dispatch) => {
  return {
    uploadLab: (fileType, files, facility) =>
      dispatch(uploadFiles(fileType, files, facility)),
    printReportsByDate: (patientName, fac, start, end, crit) =>
      dispatch(
        printReportsByDate(patientName, encodeURIComponent(fac), start, end, crit)
      ),
    reportCSV: (fac, start, end) =>
      dispatch(reportCSV(encodeURIComponent(fac), start, end)),
    fileDialogControl: (boolean) => dispatch(fileDialogControl(boolean)),
    getFacilities: () => dispatch(getFacilityRequest()),
    generateLabReq: (patientName, facility, testType, labName) =>
      dispatch(generateLabReq(patientName, facility, testType, labName)),
    facesheetRequest: (where) => dispatch(facesheetRequest(where)),
    updateFacesheets: (where, type) => dispatch(updateFacesheets(where, type)),
    patientNameList: () => dispatch(patientNameList()),
    getLabsRequest: () => dispatch(getLabsRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportComponent);
