import {
  QUERY_RESULTS,
  QUERY_RESULTS_SUCCESS,
  QUERY_RESULTS_FAILURE,
  QUERY_REPORT,
  QUERY_REPORTS_SUCCESS,
  QUERY_REPORTS_FAILURE,
  QUERY_OBR_SUCCESS,
  QUERY_OBR_FAILURE,
  QUERY_OBR,
  PRINT_REPORTS_SUCCESS,
  PRINT_REPORTS_FAILURE,
  PRINT_REPORTS,
  ANALYZED_REPORTS_SUCCESS,
  ANALYZED_REPORTS_FAILURE,
  PANTESTLIST_SUCCESS,
  PANTESTLIST_FAILURE,
  SUBTESTLIST_SUCCESS,
  SUBTESTLIST_FAILURE,
  TESTNAMELIST_SUCCESS,
  TESTNAMELIST_FAILURE,
  RESULT_CLICKED,
  RESULT_CLICKED_SUCCESS,
  CLEAR_SEARCH,
} from '../constants/resultConstants';
import { openNewTabBlob, openNewTabFile } from '../utils/Utils';

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
});
export const queryPatients = () => ({
  type: QUERY_RESULTS,
});
export const querySuccess = (results) => ({
  type: QUERY_RESULTS_SUCCESS,
  patientResults: JSON.parse(results.results),
  count: results.count,
});
export const queryFail = () => ({
  type: QUERY_RESULTS_FAILURE,
});
export const queryReportsSuccess = (reports) => ({
  type: QUERY_REPORTS_SUCCESS,
  reportResults: reports,
});
export const queryReportsFail = () => ({
  type: QUERY_REPORTS_FAILURE,
});
export const queryReport = () => ({
  type: QUERY_REPORT,
});
export const queryObrSuccess = (results) => ({
  type: QUERY_OBR_SUCCESS,
  obrResults: results,
});
export const queryObrFail = () => ({
  type: QUERY_OBR_FAILURE,
});
export const queryObr = () => ({
  type: QUERY_OBR,
});
export const printReport = () => ({
  type: PRINT_REPORTS,
});
export const printReportsSuccess = (reports) => {
  openNewTabFile('testPDF', reports.file);
  return {
    type: PRINT_REPORTS_SUCCESS,
  };
};
export const printReportsFail = () => ({
  type: PRINT_REPORTS_FAILURE,
});
export const analyzedSuccess = (analyzed) => ({
  type: ANALYZED_REPORTS_SUCCESS,
  analyzedReports: analyzed,
});
export const analyzedFail = () => ({
  type: ANALYZED_REPORTS_FAILURE,
});
export const panTestListSuccess = (panTestList) => ({
  type: PANTESTLIST_SUCCESS,
  panTestList: panTestList,
});
export const panTestListFailure = () => ({
  type: PANTESTLIST_FAILURE,
});
export const subtestListSuccess = (subtestList) => ({
  type: SUBTESTLIST_SUCCESS,
  subtestList: subtestList,
});
export const subtestListFailure = () => ({
  type: SUBTESTLIST_FAILURE,
});
export const testNameListSuccess = (testNameList) => ({
  type: TESTNAMELIST_SUCCESS,
  testNameList: testNameList,
});
export const testNameListFailure = () => ({
  type: TESTNAMELIST_FAILURE,
});

export const resultButtonClicked = () => ({
  type: RESULT_CLICKED,
});
export const resultButtonDone = () => ({
  type: RESULT_CLICKED_SUCCESS,
});

export const resultClicked = () => {
  return (dispatch) => {
    dispatch(resultButtonClicked());
  };
};
export const resultClickedDone = () => {
  return (dispatch) => {
    dispatch(resultButtonDone());
  };
};
export const resultRequest = (limit, offset, sort, dir) => {
  return (dispatch) => {
    let url = null;

    url = new URL(
      process.env.REACT_APP_API_URL +
      '/data/resultsQuery?col=*' +
      '&limit=' +
      limit +
      '&offset=' +
      offset +
      '&orderby=' +
      sort +
      '&dir=' +
      dir +
      '&param=' +
      '&where='
    );

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(querySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const patientLabResults = (
  patientID,
  facilityID,
  limit,
  offset,
  sort,
  direction
) => {
  return (dispatch) => {
    let url = null;
    let param = null;

    param =
      'Patients.patientID="' +
      patientID +
      '" AND Patients.patientFacilityID = "' +
      facilityID +
      '"';
    // let limit = limit;
    // let offset = offset;
    // let sort = sort;
    let dir = direction;

    url = new URL(
      process.env.REACT_APP_API_URL +
      '/data/resultsQuery?col=*' +
      '&limit=' +
      limit +
      '&offset=' +
      offset +
      '&orderby=' +
      sort +
      '&dir=' +
      dir +
      '&where=' +
      param
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(querySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const searchRequest = (col, term, limit, offset, sort, dir) => {
  return (dispatch) => {
    let url = null;
    let param = null;

    if (term === 'RPP') {
      term =
        'Gram Positive Bacterial RPP" OR testName = "Gram Negative Bacterial RPP';
    }
    switch (col) {
      case 'Patient Last Name':
        param = 'patientLastName="' + term + '"';
        break;
      case 'Facility':
        param = 'facilityName="' + encodeURIComponent(term) + '"';
        break;
      case 'Report Date':
        param = 'Reports.reportDate="' + term + '"';
        break;
      case 'Lab Name':
        param = 'labName="' + term + '"';
        break;
      case 'Doctor Last Name':
        param = 'doctorLastName="' + term + '"';
        break;
      case 'Abnormal Results':
        if (term === 'Yes') {
          param = 'reportAbnormal=1';
        } else {
          param = 'reportAbnormal=0';
        }
        break;
      case 'Test Name':
        param = 'testName="' + term + '"';
        break;
      case 'Doctor NPI':
        param = 'Doctors.doctorNPI="' + term + '"';
        break;
      case 'Reviewed':
        if (term === 'Reviewed') {
          param = 'Reports.analyzed=" ' + 1 + '"';
        } else {
          param = 'Reports.analyzed=" ' + 0 + '"';
        }
        break;
      case 'Subtest':
        param = 'OBX.subtest="' + term + '"';
        break;
      case 'Critical':
        if (term === 'Show Only Critical Results') {
          param = 'Reports.reportCritical=1';
        }
        else {
          param = 'Reports.reportCritical=0';
        }
        break;
      default:
        break;
    }

    url = new URL(
      process.env.REACT_APP_API_URL +
      '/data/resultsQuery?col=*' +
      '&limit=' +
      limit +
      '&offset=' +
      offset +
      '&orderby=' +
      sort +
      '&dir=' +
      dir +
      '&param=' +
      '&where=' +
      param
    );
    dispatch(queryPatients());
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(querySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};

export const printReports = (offset, param) => {
  return (dispatch) => {
    let url = null;

    url = new URL(
      process.env.REACT_APP_API_URL +
      '/report/printReportsQuery?col=*' +
      '&param= ' +
      param +
      '&type=res&crit="critical"'
    );
    dispatch(printReport());
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/octet-stream',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((searchResult) => {
        var base64String = Buffer.from(searchResult).toString('base64');
        var title = 'report_' + new Date().toISOString().slice(0, 10) + '.pdf';
        openNewTabFile(title, base64String);
        dispatch(printReportsSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(printReportsFail(error));
      });
  };
};
export const analyzedReports = (col, param) => {
  return (dispatch) => {
    let url = null;

    url = new URL(
      process.env.REACT_APP_API_URL +
      '/data/analyzedQuery?col="' +
      col +
      '" ' +
      '&param= reportID = "' +
      param +
      '"'
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(analyzedSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(analyzedFail(error));
      });
  };
};
export const obrRequest = (param) => {
  return (dispatch) => {
    let url = null;

    url = new URL(
      process.env.REACT_APP_API_URL +
      '/data/obrQuery?col=*' +
      '&param= reportID = "' +
      param +
      '"'
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryObrSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryObrFail(error));
      });
  };
};
export const advancedSearch = (terms, limit, offset, sort, dir) => {
  let param = '';
  let facilities = [];
  let patients = [];
  let others = [];
  terms.forEach((term) => {
    switch (term.dropVal) {
      case 'Patient Last Name':
        patients.push(term.value);
        // param += 'Patients.patientLastName="' + term.value + '"';
        break;
      case 'Facility':
        facilities.push(term.value);
        // param +=
        //   'Facilities.facilityName="' + encodeURIComponent(term.value) + '"';
        // facCount = facCount + 1;
        break;
      default:
        others.push(term);
        break;
    }

    // if (terms.length > 1 && terms.length !== terms.indexOf(term) + 1) {
    //   param += ' AND ';
    // }
  });
  if (others.length > 0) {
    for (let i = 0; i <= others.length - 1; i++) {
      let term = others[i];
      switch (term.dropVal) {
        case 'Report Date':
          param += 'Reports.reportDate ="' + term.value + '"';
          break;
        case 'Lab Name':
          param += 'Laboratory.labName="' + term.value + '"';
          break;
        case 'Doctor Last Name':
          param += 'Doctors.doctorLastName="' + term.value + '"';
          break;
        case 'Abnormal Results':
          if (term.value === 'Yes') {
            param += 'OBX.abnormal=1';
          } else {
            param += 'OBX.abnormal=0';
          }
          break;
        case 'Critical':
          if (term.value === 'Show Only Critical Results') {
            console.log("crit")
            param += 'Reports.reportCritical=1';
          }
          break;
        case 'Test Name':
          param += 'OBR.testName="' + term.value + '"';
          break;
        case 'Doctor NPI':
          param += 'Doctors.doctorNPI="' + term.value + '"';
          break;
        case 'Reviewed':
          if (term.value === 'Reviewed') {
            param += 'Reports.analyzed=' + 1 + ' ';
          } else {
            param += 'Reports.analyzed=' + 0 + ' ';
          }
          break;
        case 'Subtest':
          param += 'OBX.subtest="' + term.value + '"';
          break;
        default:
          break;
      }
      if (others.length - 1 !== i && others.length !== 1) {
        param += ' AND ';
      }
    }
  }
  if (patients.length > 0) {
    param += ' AND ';
    for (let i = 0; i <= patients.length - 1; i++) {
      if (patients.length === 1) {
        param += '(Patients.patientLastName="' + patients[i] + '")';
      } else if (i === 0) {
        param += '(Patients.patientLastName="' + patients[i] + '" OR ';
      } else if (i !== patients.length - 1) {
        param += ' Patients.patientLastName="' + patients[i] + '" OR ';
      } else {
        param += ' Patients.patientLastName="' + patients[i] + '") ';
      }
    }
  }
  if (facilities.length > 0) {
    param += ' AND ';
    for (let facCount = 0; facCount <= facilities.length - 1; facCount++) {
      if (facilities.length === 1) {
        param +=
          '(Facilities.facilityName="' +
          encodeURIComponent(facilities[facCount]) +
          '") ';
      } else if (facCount === 0) {
        param +=
          '(Facilities.facilityName="' +
          encodeURIComponent(facilities[facCount]) +
          '" OR ';
      } else if (facCount !== facilities.length - 1) {
        param +=
          'Facilities.facilityName="' +
          encodeURIComponent(facilities[facCount]) +
          '" OR ';
      } else {
        param +=
          'Facilities.facilityName="' +
          encodeURIComponent(facilities[facCount]) +
          '")';
      }
    }
  }
  return (dispatch) => {
    let url = null;
    url = new URL(
      process.env.REACT_APP_API_URL +
      '/data/resultsQuery?col=*' +
      '&limit=' +
      limit +
      '&offset=' +
      offset +
      '&orderby=' +
      sort +
      '&dir=' +
      dir +
      '&param=' +
      '&where=' +
      param
    );
    dispatch(queryPatients());
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(querySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const getPanTestRequest = (labID) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/data/getPantests?labID=' + labID
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(panTestListSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(panTestListFailure(error));
      });
  };
};
export const getSubtestRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/getSubtests');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(subtestListSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(subtestListFailure(error));
      });
  };
};
export const getTestNameRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/getTestName');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(testNameListSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(testNameListFailure(error));
      });
  };
};
export const queryReports = (offset) => {
  return (dispatch) => {
    let url = null;
    let col = '*';
    let param = '';
    url = new URL(
      process.env.REACT_APP_API_URL +
      '/data/resultsQuery?col=' +
      col +
      '&param=' +
      param
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryReportsSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
