import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import '../css/patientComponent.css';
import '../css/MedicationsTabComponent.css';

import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';

class MedicationsTabComponent extends Component {
  constructor() {
    super();
    this.state = {
      medications: [],
    };
  }
  componentDidMount() {}
  render() {
    let medicationsTableInfo = () => {
      const sortedMeds = this.props.medications.sort(
        (a, b) => b.medicationDate - a.medicationDate
      );
      if (sortedMeds.length === 0) {
        return (
          <Typography id="noResult" use="headline6" style={{ right: '40%' }}>
            No current medications
          </Typography>
        );
      } else {
        let medicationsData = sortedMeds.map((medications, i) => {
          if (i === 0 && medications.medication === null) {
            return (
              <Typography
                id="noResult"
                use="headline6"
                style={{ right: '40%' }}
              >
                No current medications
              </Typography>
            );
          }
          var epoch =
            medications.medicationDate + new Date().getTimezoneOffset() * -1;
          var hrDate = new Date(epoch);
          var newDate =
            hrDate.getMonth() +
            1 +
            '/' +
            hrDate.getDate() +
            '/' +
            hrDate.getFullYear();
          return (
            <DataTableRow>
              <DataTableCell>{medications.medication}</DataTableCell>
              <DataTableCell>{newDate}</DataTableCell>
            </DataTableRow>
          );
        });
        return medicationsData;
      }
    };
    return (
      <div id="allergiesTable">
        <Grid>
          <GridRow>
            <GridCell span={12}>
              <DataTable style={{ width: '100%' }}>
                <DataTableContent>
                  <DataTableHead>
                    <DataTableRow>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        Medication
                      </DataTableHeadCell>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        Date
                      </DataTableHeadCell>
                    </DataTableRow>
                  </DataTableHead>
                  <DataTableBody>{medicationsTableInfo()}</DataTableBody>
                </DataTableContent>
              </DataTable>
            </GridCell>
          </GridRow>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicationsTabComponent);
