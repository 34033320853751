import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import '../css/patientComponent.css';
import '../css/MedicationsTabComponent.css';
import '@rmwc/dialog/styles';

import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import { TextField } from '@rmwc/textfield';
import { addPatientNote } from '../actions/patientActions';
import { CollectionsBookmarkRounded } from '@material-ui/icons';

class HistoryTabComponent extends Component {
  constructor() {
    super();
    this.state = {
      dmv: [],
      author: '',
      note: '',
      date: '',
      addNoteDialog: false,
      currentHistory: [],
      currentPatient: '',
    };
  }
  patientHistoryJson = () => {
    let patientHistoryJson = {
      author: this.props.decodedJWT.username,
      note: this.state.note,
      date: Date.now(),
    };
    let patientHistory = JSON.parse(this.props.patient.patientHistory);
    patientHistory.push(patientHistoryJson);

    this.props.addPatientNote(this.props.patient.patientID, patientHistory);
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
  }
  render() {
    let historyTableInfo = () => {
      let patientHistory = JSON.parse(this.props.patient.patientHistory);
      if (patientHistory.length === 0) {
        return (
          <Typography id="noResult" use="headline6" style={{ right: '40%' }}>
            No current patient history
          </Typography>
        );
      } else {
        let historyData = patientHistory.map((history, i) => {
          if (history !== null) {

            let date = new Date(history.date);
            return (
              <DataTableRow>
                <DataTableCell>{history.author}</DataTableCell>
                <DataTableCell>{history.note}</DataTableCell>
                <DataTableCell>{date.toLocaleString()}</DataTableCell>
              </DataTableRow>
            );

          }
        });

        return historyData;
      }
    };
    let addNoteDialog = () => {
      return (
        <div>
          <TextField
            label="New Note"
            outlined
            style={{
              width: '80%',
              height: '10%',
              top: '75%',
              left: '7%',
              position: 'absolute',
            }}
            value={this.state.note}
            onChange={(event) => {
              this.setState({ note: event.currentTarget.value });
            }}
          ></TextField>
          <Button
            style={{
              width: '10%',
              height: '10%',
              top: '75%',
              left: '87%',
              position: 'absolute',
            }}
            onClick={() => {
              this.patientHistoryJson();
              this.setState({ addNoteDialog: true, note: '' });
            }}
          >
            Add
          </Button>
        </div>
      );
    };
    return (
      <div id="historyTable">
        <Grid>
          <GridRow>
            <GridCell span={12}>
              <DataTable
                style={{ width: '91%', height: '40%', position: 'absolute' }}
              >
                <DataTableContent>
                  <DataTableHead>
                    <DataTableRow>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        Author
                      </DataTableHeadCell>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        Notes
                      </DataTableHeadCell>
                      <DataTableHeadCell
                        style={{ fontWeight: 'bold', width: '25%' }}
                      >
                        Date
                      </DataTableHeadCell>
                    </DataTableRow>
                  </DataTableHead>
                  <DataTableBody>{historyTableInfo()}</DataTableBody>
                </DataTableContent>
              </DataTable>
            </GridCell>
          </GridRow>
        </Grid>
        {addNoteDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  decodedJWT: state.login.decodedJWT,
  patient: state.patient.facesheet,
});
const mapDispatchToProps = (dispatch) => {
  return {
    addPatientNote: (patientID, history) =>
      dispatch(addPatientNote(patientID, history)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryTabComponent);
