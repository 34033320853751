import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import dialogReducer from './dialogReducer';
import patientReducer from './patientReducer';
import adminReducer from './userReducer';
import resultReducer from './resultReducer';
import reportReducer from './reportReducer';
import physicanReducer from './physicanReducer';
import groupsReducer from './groupsReducer';
import 'bootstrap/dist/css/bootstrap.min.css';
import metricsReducer from './metricsReducer';
import facilityLoginReducer from './facilityLoginReducer';
import labReducer from './labManagementReducer';
import labHistoryReducer from './labHistoryReducer';
import fileHistoryReducer from './fileHistoryReducer';
import labOrderManagerReducer from './labOrderManagerReducer';
const rootReducer = combineReducers({
  login: loginReducer,
  dialog: dialogReducer,
  patient: patientReducer,
  admin: adminReducer,
  result: resultReducer,
  report: reportReducer,
  physician: physicanReducer,
  group: groupsReducer,
  metrics: metricsReducer,
  facilityLogins: facilityLoginReducer,
  labs: labReducer,
  labHistory: labHistoryReducer,
  fileHistory: fileHistoryReducer,
  labMapper: labOrderManagerReducer,
});

export default rootReducer;
