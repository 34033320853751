import React from 'react';
import { connect } from 'react-redux';
import { getLabHistoryRequest } from '../actions/labHistoryActions';
import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import { Typography } from '@rmwc/typography';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import '../css/labHistoryComponent.css';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { patientNameList } from '../actions/reportActions';
import { getLabsRequest } from '../actions/labManagementActions';
import { searchRequest } from '../actions/patientActions';

class LabHisotryComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      count: 0,
      ohPage: 0,
      ordersPerPage: 10,
      ordersPage: 0,
      ordersTotal: 100,
      offset: 0,

      tabIndex: 0,
    };
  }
  static getDerivedStateFromProps(props, state) {
    let patientList = [];
    props.patients.forEach((patient) => {
      patientList.push(patient);
    });
    let labList = [];
    props.labs.forEach((lab) => {
      labList.push(lab);
    });
    return {
      ...state,
      labList: labList,
      patientList: patientList,
    };
  }

  componentDidMount() {
    this.props.getLH('', '*');
    this.props.patientNameList();
    this.props.getLabsRequest();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangePage = (event, newPage) => {
    let offset = this.state.patientsPerPage * newPage;
    this.setState(
      {
        ordersPage: newPage,
        offset: offset,
      },
      () => {
        let offset = this.state.ordersPerPage * this.state.ordersPage;
      }
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        ordersPage: 0,
        ordersPerPage: parseInt(event.target.value, 10),
        offset: 0,
      },
      () => {}
    );
  };
  clearSearch() {
    this.setState({
      searchCol: '',
      query: '',
    });
    this.props.getLH('', '*');
  }

  render() {
    let lhTableInfo = () => {
      if (this.props.ohList.length === 0) {
        return (
          <Typography id="noResult" use="headline5">
            No Results
          </Typography>
        );
      } else {
        let patientData = this.props.ohList.map((order, i) => {
          let orderString = '';
          if (order.orderedTests !== null && order.orderedTests !== undefined) {
            orderString = order.orderedTests.map((test) => {
              return test.type;
            });
          }

          return (
            <DataTableRow key={i}>
              <DataTableCell>{order.orderNumber}</DataTableCell>

              <DataTableCell>
                {order.patientLastName + ', ' + order.patientFirstName}
              </DataTableCell>
              <DataTableCell>{orderString}</DataTableCell>
              <DataTableCell>{order.labName}</DataTableCell>
              <DataTableCell>
                {order.OHDateSubmitted !== null
                  ? new Date(order.OHDateSubmitted).toLocaleDateString()
                  : ''}
              </DataTableCell>
              <DataTableCell>
                {order.OHDateCompleted !== null
                  ? new Date(order.OHDateCompleted).toLocaleDateString()
                  : ''}
              </DataTableCell>
              <DataTableCell>{order.OHStatus}</DataTableCell>
            </DataTableRow>
          );
        });
        return patientData;
      }
    };
    return (
      <div>
        <Grid>
          <GridRow>
            <GridCell span={5}></GridCell>

            <GridCell span={3}>
              <div id="OHSearchCol">
                <Select
                  outlined
                  label="Field"
                  options={['Patient Last Name', 'Laboratory']}
                  value={this.state.searchCol}
                  onChange={(event) => {
                    this.setState({
                      searchCol: event.currentTarget.value,
                      query: '',
                    });
                  }}
                />
              </div>
            </GridCell>
            <GridCell span={3}>
              {this.state.searchCol === 'Laboratory' ? (
                <GridCell span={9}>
                  <Select
                    outlined
                    value={this.state.query}
                    onChange={(event) => {
                      this.setState({
                        query: event.currentTarget.value,
                      });
                    }}
                  >
                    {this.state.labList.map((lab) => {
                      return (
                        <option
                          value={lab.labName}
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {lab.labName}
                        </option>
                      );
                    })}
                  </Select>
                </GridCell>
              ) : (
                <GridCell span={4}>
                  <TextField
                    style={{ width: '432px' }}
                    placeholder="Last Name"
                    outlined
                    name="query"
                    value={this.state.query}
                    onChange={(e) => this.handleChange(e)}
                    trailingIcon={{
                      icon: 'search',
                      tabIndex: 0,
                      onClick: () => {
                        this.setState({ searching: true, offset: 0 }, () => {
                          this.props.getLH(
                            this.state.query,
                            this.state.searchCol
                          );
                        });
                      },
                    }}
                  ></TextField>
                </GridCell>
              )}
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={9}>
              <div id="ohHead">
                <Typography use="headline4">Order History</Typography>
              </div>
            </GridCell>
            <GridCell span={3}>
              <Button
                id="clearSearch"
                icon="clear"
                label="Clear Search"
                onClick={() => this.clearSearch()}
              ></Button>
            </GridCell>
          </GridRow>
        </Grid>
        <DataTable
          id="OHTable"
          stickyRows={1}
          style={{ backgroundColor: 'white' }}
        >
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell
                  style={{
                    borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                    width: '5%',
                  }}
                >
                  Order Number
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                  }}
                >
                  Patient
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                  }}
                >
                  Orders
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                  }}
                >
                  Lab
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                  }}
                >
                  Date Submitted
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                  }}
                >
                  Date Completed
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                  }}
                >
                  Order Status
                </DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>{lhTableInfo()}</DataTableBody>
          </DataTableContent>
        </DataTable>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  decodedJWT: state.login.decodedJWT,
  ohList: state.labHistory.orders,
  labs: state.labs.labs,
  patients: state.report.patients,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getLH: (condition, col) => dispatch(getLabHistoryRequest(condition, col)),
    getLabsRequest: () => dispatch(getLabsRequest()),
    patientNameList: () => dispatch(patientNameList()),
    searchResults: (col, term, limit, offset, sort, dir) =>
      dispatch(searchRequest(col, term, limit, offset, sort, dir)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabHisotryComponent);
