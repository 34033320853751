import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  CHANGE_PASS_REQUEST,
  CHANGE_PASS_SUCCESS,
  CHANGE_PASS_FAILURE,
  LOGIN_DBS_FAIL,
  LOGIN_DBS_SUCCESS,
  LOGOUT,
} from '../constants/loginConstants';
import auth0 from 'jsonwebtoken';

const loginRequest = (username) => ({
  type: LOGIN_REQUEST,
  username: username,
});

const loginSuccess = (jwt) => ({
  type: LOGIN_SUCCESS,
  jwt: jwt,
  decodedJWT: auth0.decode(jwt),
});

const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    error: error,
  };
};

export const logout = () => ({
  type: LOGOUT,
});

const changePassRequest = () => ({
  type: CHANGE_PASS_REQUEST,
});

const changePassSuccess = () => ({
  type: CHANGE_PASS_SUCCESS,
});

const changePassFailure = (error) => ({
  type: CHANGE_PASS_FAILURE,
  error: error,
});

const queryDBSSuccess = (result) => ({
  type: LOGIN_DBS_SUCCESS,
  dbList: result,
});

const queryDBSFail = (error) => ({
  type: LOGIN_DBS_FAIL,
  error: error,
});

/*
 *Allow login if response code 200 is returned.
 *Verifies JWT, sets cookie
 */
export const login = (username, password, database) => {
  return (dispatch) => {
    dispatch(loginRequest(username));
    fetch(process.env.REACT_APP_API_URL + '/login/attempt', {
      method: 'POST',
      headers: {
        Authorization: 'Basic ' + btoa(username + ':' + password),
        Database: database,
      },
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 200) {
          response.text().then((jwt) => {
            setCookie('jwt', jwt, 1);
            dispatch(loginSuccess(jwt));
          });
        } else if (response.status === 401) {
          response.text().then((error) => {
            dispatch(loginFailure(error));
          });
        } else if (response.status === 503) {
          response.text().then((error) => {
            dispatch(loginFailure(error));
          });
        }
      })
      .catch((error) => {
        dispatch(loginFailure(error));
      });
  };
};

export const getDatabasesRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/login/getDBS');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryDBSSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryDBSFail(error));
      });
  };
};

export const assignJWT = (jwt) => {
  return (dispatch) => {
    dispatch(loginSuccess(jwt));
  };
};

//Change user password
export const changePass = (pass, oldPass) => {
  return (dispatch) => {
    dispatch(changePassRequest());
    fetch(process.env.REACT_APP_API_URL + '/changePass', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        pass,
        oldPass,
      }),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status);
        } else {
          dispatch(changePassSuccess(response));
        }
      })
      .catch((error) => {
        dispatch(changePassFailure(error));
      });
  };
};

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
