import {
  QUERY_LOGINS_SUCCESS,
  QUERY_LOGINS_FAIL,
  ADD_LOGINS_SUCCESS,
  ADD_LOGINS_FAIL,
  DELETE_FACILITY_LOGINS_SUCCESS,
  DELETE_FACILITY_LOGINS_FAIL,
  UPDATE_FACILITY_LOGINS_SUCCESS,
  UPDATE_FACILITY_LOGINS_FAIL,
} from '../constants/facilityLoginConstants';

import Swal from 'sweetalert2';

export const queryLoginsSuccess = (logins) => ({
  type: QUERY_LOGINS_SUCCESS,
  logins: logins,
});
export const queryLoginsFail = () => ({
  type: QUERY_LOGINS_FAIL,
});
export const addFacilityLoginsSuccess = () => ({
  type: ADD_LOGINS_SUCCESS,
});

export const addFacilityLoginFail = () => ({
  type: ADD_LOGINS_FAIL,
});
export const deleteFacilityLoginsSuccess = () => ({
  type: DELETE_FACILITY_LOGINS_SUCCESS,
});

export const deleteFacilityLoginsfail = () => ({
  type: DELETE_FACILITY_LOGINS_FAIL,
});
export const updateFacilityLoginsSuccess = () => ({
  type: UPDATE_FACILITY_LOGINS_SUCCESS,
});

export const updateFacilityLoginsfail = () => ({
  type: UPDATE_FACILITY_LOGINS_FAIL,
});

export const getLoginsRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/readLogins');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryLoginsSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryLoginsFail(error));
      });
  };
};
export const addFacilityLoginRequest = (json) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/addFacilityLogin'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getLoginsRequest());
      })
      .catch((error) => {
        dispatch(addFacilityLoginFail(error));
      });
  };
};
export const deleteFacilityLoginRequest = (username) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/deleteFacilityLogin'
    );
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ username: username }),
    })
      .then(() => {
        dispatch(deleteFacilityLoginsSuccess());
      })
      .then(() => {
        dispatch(getLoginsRequest());
      })
      .catch((error) => {
        dispatch(deleteFacilityLoginsfail(error));
      });
  };
};
export const updateFacilityLoginRequest = (json) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/updateFacilityLogin'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getLoginsRequest());
      })
      .catch((error) => {
        dispatch(updateFacilityLoginsfail(error));
      });
  };
};
