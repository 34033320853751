export const PHYSICIANLIST_SUCCESS = 'PHYSICIANLIST_SUCCESS';
export const PHYSICIANLIST_FAIL = 'PHYSICIANLIST_FAIL';
export const ADD_PHYSICIAN = 'ADD_PHYSICIAN';
export const ADD_PHYSICIAN_SUCCESS = 'ADD_PHYSICIAN_SUCCESS';
export const ADD_PHYSICIAN_FAIL = 'ADD_PHYSICIAN_FAIL';
export const EDIT_PHYSICIAN = 'EDIT_PHYSICIAN';
export const EDIT_PHYSICIAN_SUCCESS = 'EDIT_PHYSICIAN_SUCCESS';
export const EDIT_PHYSICIAN_FAIL = 'EDIT_PHYSICIAN_FAIL';
export const DELETE_PHYSICIAN = 'DELETE_PHYSICIAN';
export const DELETE_PHYSICIAN_SUCCESS = 'DELETE_PHYSICIAN_SUCCESS';
export const DELETE_PHYSICIAN_FAIL = 'DELETE_PHYSICIAN_FAIL';
