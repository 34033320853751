import React from 'react';
import { connect } from 'react-redux';
import {
  getFileHistoryRequest,
  getFileRequest,
} from '../actions/fileHistoryActions';
import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import { Button } from '@rmwc/button';
import { Typography } from '@rmwc/typography';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import '../css/labHistoryComponent.css';
import { openNewTabFile } from '../utils/Utils';
import { TableVirtuoso } from 'react-virtuoso';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import '../css/resultComponent.css';

class FileHistoryComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      count: 0,
      ohPage: 0,
      ordersPerPage: 10,
      ordersPage: 0,
      ordersTotal: 100,
      offset: 0,
      tabIndex: 0,
      columns: [
        {
          width: 200,
          label: 'File Path',
          dataKey: 'FilePath',
          numeric: false,
        },
        {
          width: 120,
          label: 'Date Uploaded',
          dataKey: 'DateUploaded',
          numeric: false,
        },
        {
          width: 120,
          label: 'File Status',
          dataKey: 'FileStatus',
          numeric: false,
        },
        {
          width: 120,
          label: 'Download',
          dataKey: 'Download',
          numeric: false,
        },
      ],
    };
  }

  componentDidMount() {
    this.props.getFileHistory();
  }

  fhTableInfo() {
    let patientData = [];

    if (this.props.history.length === 0) {
      let json = {
        FilePath: '',
        DateUploaded: '',
        FileStatus: '',
        Download: '',
      };
      patientData.push(json);
    } else {
      patientData.push(
        this.props.history.map((history, i) => {
          var epoch =
            history.fileDateIngested + new Date().getTimezoneOffset() * -1;
          var hrDate = new Date(epoch);
          var newDate =
            hrDate.getMonth() +
            1 +
            '/' +
            hrDate.getDate() +
            '/' +
            hrDate.getFullYear();
          const getColor = (abnormal) => {
            if (abnormal === false) return 'rgb(236, 133, 133)';
            return '';
          };
          let background = getColor(history.fileStatus);
          let status = '';
          if (history.fileStatus) {
            status = 'Successful';
          } else {
            status = 'Unsuccessful';
          }
          let json = {
            FilePath: history.filename,
            DateUploaded: newDate,
            FileStatus: status,
            Download: history.fileNumber,
          };
          return json;
        })
      );
      return patientData[0];
    }
  }
  rowContent(_index, row) {
    console.log(_index);
    console.log(row);
    return (
      <React.Fragment>
        {this.state.columns.map((column) => {
          console.log(column);
          console.log(row[column.dataKey]);
          return (
            <TableCell
              key={column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
            >
              {row[column.dataKey]}
            </TableCell>
          );
        })}
      </React.Fragment>
    );
  }

  render() {
    let VirtuosoTableComponents = () => {
      return {
        Scroller: React.forwardRef((props, ref) => (
          <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
          <Table
            {...props}
            sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
          />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => (
          <TableBody {...props} ref={ref} />
        )),
      };
    };
    let rows = this.fhTableInfo();
    return (
      <div>
        <Grid>
          <GridRow>
            <GridCell span={9}>
              <div id="ohHead">
                <Typography use="headline4">File History</Typography>
              </div>
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={12}>
              <div id="fhTable">
                <Paper style={{ height: '100%', width: '100%' }}>
                  {this.props.fileHistory !== 0 ? (
                    <TableVirtuoso
                      data={rows}
                      components={VirtuosoTableComponents()}
                      fixedHeaderContent={() => {
                        return (
                          <TableRow>
                            {this.state.columns.map((column) => (
                              <TableCell
                                key={column.dataKey}
                                variant="head"
                                align={
                                  column.numeric || false ? 'right' : 'left'
                                }
                                style={{
                                  width: column.width,
                                  backgroundColor: 'white',
                                }}
                                sx={{
                                  backgroundColor: 'background.paper',
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      }}
                      itemContent={(index, row) => {
                        return (
                          <React.Fragment>
                            {this.state.columns.map((column) => {
                              if (column.dataKey !== 'Download') {
                                return (
                                  <TableCell
                                    key={column.dataKey}
                                    align={
                                      column.numeric || false ? 'right' : 'left'
                                    }
                                  >
                                    {row[column.dataKey]}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    key={column.dataKey}
                                    align={
                                      column.numeric || false ? 'right' : 'left'
                                    }
                                  >
                                    <Button
                                      icon="download"
                                      variant="contained"
                                      onClick={() => {
                                        this.props.getFile(row[column.dataKey]);
                                      }}
                                    >
                                      download
                                    </Button>
                                  </TableCell>
                                );
                              }
                            })}
                          </React.Fragment>
                        );
                      }}
                    ></TableVirtuoso>
                  ) : (
                    <Typography id="noResult" use="headline5">
                      No Results
                    </Typography>
                  )}
                </Paper>
              </div>
            </GridCell>
          </GridRow>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  decodedJWT: state.login.decodedJWT,
  history: state.fileHistory.history,
  file: state.fileHistory.file,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFileHistory: (condition, col) =>
      dispatch(getFileHistoryRequest(condition, col)),
    getFile: (condition) => dispatch(getFileRequest(condition)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileHistoryComponent);
