import { GROUP_QUERY_SUCCESS } from '../constants/groupConstants';
import {
  ADMIN_FACILITYLIST_SUCCESS,
  ADMIN_AVAILFACILITYLIST_SUCCESS,
} from '../constants/userConstants';

const initialState = {
  groups: [],
  facilities: [],
};
const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_QUERY_SUCCESS:
      return {
        ...state,
        groups: action.groups,
      };
    case ADMIN_FACILITYLIST_SUCCESS:
      return {
        ...state,
        facilities: action.facilityList,
      };
    case ADMIN_AVAILFACILITYLIST_SUCCESS:
      return {
        ...state,
        availFacilities: action.facilityList,
      };
    default:
      return state;
  }
};
export default groupsReducer;
