import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import '../css/patientComponent.css';
import '../css/VitalsTabComponent.css';

class VitalsTabComponent extends Component {
  constructor() {
    super();
    this.state = {
      vitals: [],
    };
  }
  componentDidMount() {}
  render() {
    let vitalsTableInfo = () => {
      const sortedVitals = this.props.vitals.sort(
        (a, b) => b.vitalDate - a.vitalDate
      );

      if (sortedVitals.length === 0) {
        return (
          <Typography id="noResult" use="headline6" style={{ right: '45%' }}>
            No current vitals
          </Typography>
        );
      } else {
        let vitalsData = this.props.vitals.map((vitals, i) => {
          if (
            i === 0 &&
            vitals.vitalString === null &&
            vitals.vitalType === null
          ) {
            return (
              <Typography
                id="noResult"
                use="headline6"
                style={{ right: '45%' }}
              >
                No current vitals
              </Typography>
            );
          }
          var epoch = vitals.vitalDate + new Date().getTimezoneOffset() * -1;
          var hrDate = new Date(epoch);
          var newDate =
            hrDate.getMonth() +
            1 +
            '/' +
            hrDate.getDate() +
            '/' +
            hrDate.getFullYear();

          return (
            <DataTableRow>
              <DataTableCell>{vitals.vitalType}</DataTableCell>
              <DataTableCell>
                {vitals.vitalType === 'Weight'
                  ? vitals.vitalString + ' lbs'
                  : vitals.vitalString}
              </DataTableCell>
              <DataTableCell>
                {vitals.vitalDate === 0 ? '' : newDate}
              </DataTableCell>
            </DataTableRow>
          );
        });
        return vitalsData;
      }
    };
    return (
      <div id="vitalsTable">
        <Grid>
          <GridRow>
            <GridCell span={12}>
              <DataTable style={{ width: '100%' }}>
                <DataTableContent>
                  <DataTableHead>
                    <DataTableRow>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        Vital
                      </DataTableHeadCell>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        Result
                      </DataTableHeadCell>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        Date
                      </DataTableHeadCell>
                    </DataTableRow>
                  </DataTableHead>
                  <DataTableBody>{vitalsTableInfo()}</DataTableBody>
                </DataTableContent>
              </DataTable>
            </GridCell>
          </GridRow>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VitalsTabComponent);
