import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@rmwc/typography';
import { Tab, TabBar } from '@rmwc/tabs';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import { Button } from '@rmwc/button';
import Swal from 'sweetalert2';
import { openNewTabFile } from '../utils/Utils';
import {
  dmvRequest,
  updateDMVRequest,
} from '../actions/patientActions';
import GeneralTabComponent from '../components/GeneralTabComponent';
import DiagnosisTabComponent from '../components/DiagnosisTabComponent';
import MedicationsTabComponent from './MedicationsTabComponent';
import VitalsTabComponent from '../components/VitalsTabComponent';
import HistoryTabComponent from './HistoryTabComponent';
import { closeDialog } from '../actions/dialogActions';

class PatientInfoComponent extends Component {
  constructor() {
    super();
    this.state = {
      patientInfoDialog: true,
      medications: [],
      tabIndex: 0,
      tab: 'General',
    };
  }
  componentDidMount() {}
  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      patient: props.currentPatient,
      currentPatient: props.currentPatient,
      dmv: props.dmv,
      diagnosis: props.diagnosis,
      vitals: props.vitals,
      medications: props.medications,
    };
  }
  render() {
    let patientDetails = () => {
      return (
        <div>
          <TabBar activeTabIndex={this.state.tabIndex}>
            <Tab
              activeTabIndex={0}
              onClick={() => this.setState({ tab: 'General', tabIndex: 0 })}
            >
              General
            </Tab>
            <Tab
              activeTabIndex={1}
              onClick={() => {
                this.setState({ tab: 'Diagnosis', tabIndex: 1 });
                this.props.dmvRequest(
                  this.state.currentPatient.patientID,
                  'Diagnosis.diagnosisCode'
                );
              }}
            >
              Diagnosis
            </Tab>
            <Tab
              activeTabIndex={2}
              onClick={() => {
                this.setState({ tab: 'Medications', tabIndex: 2 });
                this.props.dmvRequest(
                  this.state.currentPatient.patientID,
                  'Medications.medication'
                );
              }}
            >
              Medications
            </Tab>
            <Tab
              activeTabIndex={3}
              onClick={() => {
                this.setState({ tab: 'Vitals', tabIndex: 3 });
                this.props.dmvRequest(
                  this.state.currentPatient.patientID,
                  'Vitals.vitalType'
                );
              }}
            >
              Vitals
            </Tab>
            <Tab
              activeTabIndex={4}
              onClick={() => {
                this.setState({ tab: 'History', tabIndex: 4 });
                this.props.dmvRequest(
                  this.state.currentPatient.patientID,
                  'Vitals.vitalType'
                );
              }}
            >
              History
            </Tab>
          </TabBar>
          {this.state.tab === 'General' ? (
            <GeneralTabComponent
              patient={this.state.patient}
              currentPatient={this.state.currentPatient}
            ></GeneralTabComponent>
          ) : this.state.tab === 'Diagnosis' ? (
            <DiagnosisTabComponent dmv={this.state.dmv}></DiagnosisTabComponent>
          ) : this.state.tab === 'Medications' ? (
            <MedicationsTabComponent
              medications={this.state.dmv}
            ></MedicationsTabComponent>
          ) : this.state.tab === 'Vitals' ? (
            <VitalsTabComponent vitals={this.state.dmv}></VitalsTabComponent>
          ) : this.state.tab === 'History' ? (
            <HistoryTabComponent dmv={this.state.dmv}></HistoryTabComponent>
          ) : 
          null}
        </div>
      );
    };

    return (
      <div>
        <Dialog
          id="patientInfoDialog"
          open={this.props.patientInfoDialog}
          onClose={(evt) => {
            this.props.closeDialog('patientInfo');
            this.setState({ tab: 'General', tabIndex: 0 });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle id="addTitle">
            {this.state.currentPatient !== undefined ? (
              <Typography use="headline4">
                Patient Information -{' '}
                {this.state.currentPatient.patientFirstName +
                  ' ' +
                  this.state.currentPatient.patientLastName}
              </Typography>
            ) : null}
          </DialogTitle>
          {this.state.currentPatient.patientAllergies !== undefined &&
          this.state.currentPatient.patientAllergies !== null ? (
            <div>
              <Typography style={{ color: 'red' }}>
                Allergies:{' '}
                {JSON.parse(this.state.currentPatient.patientAllergies).map(
                  (allergy, i, array) => {
                    if (array.length - 1 === i) {
                      return allergy;
                    } else {
                      return allergy + ', ';
                    }
                  }
                )}
              </Typography>
            </div>
          ) : null}
          <div>
            <Typography
              use="headline6"
              style={{ left: '1%', position: 'absolute' }}
            >
              Facesheet:
            </Typography>

            {this.state.currentPatient.patientFacesheet !== undefined &&
            this.state.currentPatient.patientFacesheet !== null ? (
              <Button
                id="fileButton"
                icon="file_present"
                onClick={() => {
                  openNewTabFile(
                    'test pdf',
                    this.state.currentPatient.patientFacesheet
                  );
                }}
              ></Button>
            ) : (
              <div>
                &nbsp;{' '}
                <Typography
                  use="headline6"
                  style={{ left: '12%', position: 'absolute' }}
                >
                  none
                </Typography>{' '}
              </div>
            )}
          </div>
          <DialogContent style={{ width: '100%', height: '400px' }}>
            {patientDetails()}
          </DialogContent>
          <DialogActions>
            <Button
              icon="update"
              style={{ left: '0%' }}
              onClick={() => {
                if (this.state.currentPatient.facilityLoginID === null) {
                  Swal.fire({
                    title:
                      "This patient's facility is not associated with an EMR Login.",
                    text: 'An admin can update this from the EMR Login Management page.',
                    icon: 'warning',
                  });
                } else {
                  Swal.fire({
                    title:
                      'Are you sure you want to update DMV for ' +
                      this.state.currentPatient.patientFirstName +
                      ' ' +
                      this.state.currentPatient.patientLastName +
                      '?',
                    text: 'You will not be able to revert this!',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, update it!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.updateDMV();
                      Swal.fire({
                        title: 'Patient DMV are being updated!',
                        icon: 'success',
                      });
                    }
                  });
                }
              }}
            >
              Update DMV
            </Button>

            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.props.closeDialog('patientInfo');
                this.setState({ tab: 'General', tabIndex: 0 });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    diagnosis: state.patient.diagnosis,
    vitals: state.patient.vitals,
    medications: state.patient.medications,
    dmv: state.patient.dmv,
    patientInfoDialog: state.result.patientInfoDialog,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dmvRequest: (term, count) => dispatch(dmvRequest(term, count)),
    updateDMVRequest: (json) => dispatch(updateDMVRequest(json)),
    closeDialog: (dialog) => dispatch(closeDialog(dialog)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInfoComponent);
