import { FILE_HISTORY_REQUEST_SUCCESS } from '../constants/fileHistoryConstants';
import { FILE_REQUEST_SUCCESS } from '../constants/fileHistoryConstants';

const initialState = {
  history: [],
  file: [],
};
const fileHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILE_HISTORY_REQUEST_SUCCESS:
      return {
        ...state,
        history: action.history,
      };
    case FILE_REQUEST_SUCCESS:
      return {
        ...state,
        file: action.file,
      };
    default:
      return { ...state };
  }
};
export default fileHistoryReducer;
