import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import '@rmwc/data-table/styles';
import { Typography } from '@rmwc/typography';

class DiagnosisTabComponent extends Component {
  constructor() {
    super();
    this.state = {
      diagnosisSort: 0,
      codeSort: 0,
      dmv: [],
    };
  }
  componentDidMount() {}

  render() {
    let diagnosisTableInfo = () => {
      if (this.props.dmv.length === 0) {
        return (
          <Typography id="noResult" use="headline6" style={{ right: '40%' }}>
            No current diagnosis
          </Typography>
        );
      } else {
        let diagnosisData = this.props.dmv.map((dmv, i) => {
          if (i === 0 && dmv.code === null) {
            return (
              <Typography
                id="noResult"
                use="headline6"
                style={{ right: '40%' }}
              >
                No current diagnosis
              </Typography>
            );
          } else {
            var epoch = dmv.diagnosisDate + new Date().getTimezoneOffset() * -1;
            var hrDate = new Date(epoch);
            var newDate =
              hrDate.getMonth() +
              1 +
              '/' +
              hrDate.getDate() +
              '/' +
              hrDate.getFullYear();
            return (
              <DataTableRow key={i}>
                <DataTableCell>{dmv.code}</DataTableCell>
                <DataTableCell>{dmv.description}</DataTableCell>

                <DataTableCell>
                  {dmv.diagnosisDate === 0 ? 'Date Unknown' : newDate}
                </DataTableCell>
              </DataTableRow>
            );
          }
        });
        return diagnosisData;
      }
    };
    return (
      <div id="diagnosisTable">
        <Grid>
          <GridRow>
            <GridCell span={12}>
              <DataTable style={{ width: '100%' }}>
                <DataTableContent>
                  <DataTableHead>
                    <DataTableRow>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        ICD-10 Code
                      </DataTableHeadCell>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        Description
                      </DataTableHeadCell>
                      <DataTableHeadCell style={{ fontWeight: 'bold' }}>
                        Date
                      </DataTableHeadCell>
                    </DataTableRow>
                  </DataTableHead>
                  <DataTableBody>{diagnosisTableInfo()}</DataTableBody>
                </DataTableContent>
              </DataTable>
            </GridCell>
          </GridRow>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosisTabComponent);
