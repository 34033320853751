import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TopAppBar,
} from '@rmwc/top-app-bar';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import { TextField } from '@rmwc/textfield';
import Swal from 'sweetalert2';
import {
  updateUserPassRequest,
  getCurrentUserRequest,
} from '../actions/userActions';

class ProfileComponent extends Component {
  constructor() {
    super();
    this.state = {
      current: [],
      updatePasswordDialogOpen: false,
      user: [],
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (props.profileLoadingSuccess) {
      return {
        current: props.current,
        user: props.user[0],
      };
    } else {
      return {
        current: props.current,
      };
    }
  }
  componentDidMount() {
    this.props.getUser(this.state.current.username);

  }

  updateUserPass = () => {
    if (this.state.newPassword1 !== this.state.newpassword2) {
    } else {
      let userJson = {
        username: this.state.user.username,
        newPassword: this.state.newPassword,
        currentPassword: this.state.currentPassword,
        role: this.state.userRole,
      };
      this.props.updateUserPass(userJson);
    }
  };

  render() {
    let updatePassword = () => {
      return (
        <Dialog
          open={this.state.updatePasswordDialogOpen}
          onClose={(evt) => {
            this.setState({ updatePasswordDialogOpen: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            {'Modify Patient'}
          </DialogTitle>

          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Current Password:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    type="password"
                    style={{ width: '300px' }}
                    placeholder="Current Password"
                    outlined
                    name="currentPassword"
                    value={this.state.currentPassword}
                    onChange={(event) => {
                      this.setState({
                        currentPassword: event.currentTarget.value,
                      });
                    }}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>New Password:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    type="password"
                    style={{ width: '300px' }}
                    placeholder="New Password"
                    outlined
                    name="newPassword"
                    value={this.state.newPassword}
                    onChange={(event) => {
                      this.setState({
                        newPassword: event.currentTarget.value,
                      });
                    }}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Reenter New Password:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    type="password"
                    style={{ width: '300px' }}
                    placeholder="New Password"
                    outlined
                    name="newPassword2"
                    value={this.state.newPassword2}
                    onChange={(event) => {
                      this.setState({
                        newPassword2: event.currentTarget.value,
                      });
                    }}
                  ></TextField>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <Button
              action="update"
              unelevated
              icon="save"
              onClick={() => {
                if (this.state.newPassword !== this.state.newPassword2) {
                  Swal.fire({
                    title: 'New passwords do not match.',
                    text: 'Please reenter the new passwords.',
                    icon: 'warning',
                  });
                } else {
                  this.updateUserPass();
                  Swal.fire({
                    icon: 'success',
                    text: 'Password Changed Successfully.',
                  });
                  this.setState({
                    updatePasswordDialogOpen: false,
                    newPassword: '',
                    newPassword2: '',
                    currentPassword: '',
                  });
                }
              }}
            >
              Update
            </Button>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({
                  updatePasswordDialogOpen: false,
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    return (
      <div>
        {!this.props.profileLoading && this.state.user !== undefined ? (
          <div>
            <div id="userdiv">
              <TopAppBar
                prominent
                style={{
                  top: '10%',
                  position: 'absolute',
                  width: '50%',
                  left: '25%',
                }}
              >
                <Typography
                  use="headline4"
                  style={{ top: '15%', position: 'absolute', left: '45%' }}
                >
                  {this.state.user.username}
                </Typography>
              </TopAppBar>
            </div>
            <div
              style={{
                top: '30%',
                position: 'absolute',
                width: '50%',
                left: '25%',
              }}
            >
              <Grid>
                <GridRow>
                  <GridCell span={12}></GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={6}>
                    <Typography id="textBold">Username: </Typography>
                  </GridCell>
                  <GridCell span={6}>{this.state.user.username}</GridCell>
                </GridRow>
                <hr></hr>
                <GridRow>
                  <GridCell span={6}>
                    <Typography id="textBold">User Role: </Typography>
                  </GridCell>
                  <GridCell span={6}>{this.state.user.role}</GridCell>
                </GridRow>
                <hr></hr>
                <GridRow>
                  <GridCell span={6}>
                    <Typography id="textBold">User Full Name: </Typography>
                  </GridCell>
                  <GridCell span={6}>
                    {this.state.user.userFirstName +
                      ' ' +
                      this.state.user.userLastName}
                  </GridCell>
                </GridRow>
                <hr></hr>
              </Grid>
            </div>{' '}
          </div>
        ) : null}

        <div style={{ top: '90%', position: 'absolute', right: '25%' }}>
          <Button
            onClick={() => {
              this.setState({
                updatePasswordDialogOpen: true,
              });
            }}
          >
            Update Password
          </Button>
        </div>
        {updatePassword()}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserPass: (json) => dispatch(updateUserPassRequest(json)),
    getUser: (where) => dispatch(getCurrentUserRequest(where)),
  };
};

const mapStateToProps = (state) => {
  return {
    current: state.login.decodedJWT,
    role: state.login.decodedJWT.role,
    user: state.admin.user,
    profileLoadingSuccess: state.admin.profileLoadingSuccess,
    profileLoading: state.admin.profileLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
