import { CODELIST_SUCCESS } from '../constants/labOrderManagerConstants';
const initialState = {
  codes: [],
};
const labOrderManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CODELIST_SUCCESS:
      return {
        ...state,
        codes: action.codes,
      };
    default:
      return state;
  }
};
export default labOrderManagerReducer;
