import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/index';
import LoginComponent from './components/LoginComponent';
import 'material-components-web/dist/material-components-web.min.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import AppComponent from './components/AppComponent';
import './css/index.css';
require('dotenv').config();

window.store = store;

function App() {
  return (
    <BrowserRouter>
      <CookiesProvider>
        <Provider store={store}>
          <Switch>
            <Route exact path="/login" component={LoginComponent} />
            <Route path="/app" component={AppComponent} />
            <Redirect from="/" to="login" />
          </Switch>
        </Provider>
      </CookiesProvider>
    </BrowserRouter>
  );
}

render(<App />, document.getElementById('root'));
