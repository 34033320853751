import {
  CODELIST_SUCCESS,
  CODELIST_FAIL,
  DELETE_TEST_FAIL,
  DELETE_TEST_SUCCESS,
  ADD_TEST_FAIL,
  ADD_TEST_SUCCESS,
} from '../constants/labOrderManagerConstants';
import { getLabsRequest } from './labManagementActions';
import { labTestsList } from './reportActions';

export const queryCodeListSuccess = (codeList) => ({
  type: CODELIST_SUCCESS,
  codes: codeList,
});

export const queryCodeListFail = (error) => ({
  type: CODELIST_FAIL,
});
export const addTestSuccess = (codeList) => ({
  type: ADD_TEST_SUCCESS,
});

export const addTestFail = (error) => ({
  type: ADD_TEST_FAIL,
});
export const deleteTestSuccess = (codeList) => ({
  type: DELETE_TEST_SUCCESS,
});

export const deleteTestFail = (error) => ({
  type: DELETE_TEST_FAIL,
});
export const getCodesRequest = () => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/data/getAvailableCodes'
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((codeList) => {
        dispatch(queryCodeListSuccess(codeList));
      })
      .catch((error) => {
        dispatch(queryCodeListFail(error));
      });
  };
};
export const addTestRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/addLabTest');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(json),
      credentials: 'include',
    })
      .then(() => {
        dispatch(addTestSuccess());
        dispatch(labTestsList(json.labID));
      })
      .catch((error) => {
        dispatch(queryCodeListFail(error));
      });
  };
};
export const updateTestRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/updateLabTest');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(json),
      credentials: 'include',
    })
      .then(() => {
        dispatch(addTestSuccess());
        dispatch(labTestsList(json.labID));
      })
      .catch((error) => {
        dispatch(queryCodeListFail(error));
      });
  };
};
export const deleteTestRequest = (json) => {
  let test = JSON.parse(json);

  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/deleteLabTest');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(test.labTestID),
      credentials: 'include',
    })
      .then(() => {
        dispatch(deleteTestSuccess());
        dispatch(labTestsList(test.labID));
      })
      .catch((error) => {
        dispatch(queryCodeListFail(error));
      });
  };
};
