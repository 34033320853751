import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    DataTable,
    DataTableBody,
    DataTableHeadCell,
    DataTableRow,
    DataTableHead,
    DataTableCell,
    DataTableContent,
} from '@rmwc/data-table';
import { TextField } from '@rmwc/textfield';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogButton,
    DialogTitle,
} from '@rmwc/dialog';
import { Button } from '@rmwc/button';
import { Select } from '@rmwc/select';
import { Typography } from '@rmwc/typography';
import '../css/PhysicianComponent.css';
import Swal from 'sweetalert2';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { getNotifications, getWatchList, updateUserWatchListRequest, deleteNotification } from '../actions/userActions';
import {
    patientLabResults,
    queryPatientReport,
    patientResultRequest,
    patientNewResults,
} from '../actions/patientActions';
import { ThemeProvider } from '@material-ui/core';
import { Badge, BadgeAnchor } from '@rmwc/badge';

import '@rmwc/badge/styles';


class WatchListComponent extends Component {
    constructor() {
        super();
        this.state = {
            physicians: [],
            facilities: [],
            addDialogOpen: false,
            updatePhysican: false,
            currentPhysician: [],
            sortCol: '',
            physicianLastSort: null,
            sortDir: 'ASC',
            rightList: [],
            leftList: [],
            watchList: [],
            editWatchList: false,
            newPatient: [],
            notifications: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        let patientList = [];
        let temp = [];
        let match = false;
        props.patients.forEach((patient) => {
            temp = props.watchList.slice();
            for (var i = 0; i < temp.length; i++) {
                if (temp[i].patientID === patient.patientID)
                    match = true;
            }
            if (!match) {
                patientList.push(
                    patient
                );
            }
            match = false;
        });
        if (state.editWatchList) {
            return {
                ...state,
                patients: props.patients,
                patientList: patientList,
                notifications: props.notifications,
            }

        }
        else {
            return {
                ...state,
                watchList: props.watchList,
                patients: props.patients,
                patientList: patientList,
                notifications: props.notifications,

            };
        }

    }

    componentDidMount() {
        this.props.getWatchList();
        this.props.queryPatient(987654321, 0, "", "");
        this.props.getNotifications();
    }


    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value }, () => { });
    }
    handleChangeMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ [event.target.name]: value }, () => { });
    };


    isEmpty(str) {
        return !str || str.length === 0;
    }

    removeWatchList = (evt) => {
        let newState = [];
        newState = this.state.watchList.slice();
        for (var i = 0; i < newState.length; i++) {
            if (newState[i].patientID === evt.patientID) {
                newState.splice(i, 1);
                i--;
            }
        }
        this.setState({
            watchList: newState,
        }, () => this.props.updateUserWatchListRequest(this.state.watchList));


        newState = this.state.watchList.slice();
        newState.push(evt);
        this.setState({
            watchList: newState,
        }, () => this.props.updateUserWatchListRequest(this.state.watchList));

    };
    addWatchList = (evt) => {
        let newState = [];
        let match = false;
        newState = this.state.watchList.slice();
        for (var i = 0; i < newState.length; i++) {
            if (newState[i].patientID === evt.patientID) {
                newState.splice(i, 1);
                i--;
                match = true;
            }
        }

        if (!match) {
            newState = this.state.watchList.slice();
            newState.push(evt);
        }
        this.setState({
            watchList: newState,
        }, () => this.props.updateUserWatchListRequest(newState));

    };
    containsID(id) {
        let contains = false;
        for (var i = 0; i < this.state.notifications.length; i++) {
            if (this.state.notifications[i].patientID === id) {
                contains = true;
            }
        }
        return contains;
    }
    render() {
        let addDialog = () => {
            return (
                <Dialog
                    open={this.state.addDialogOpen}
                    onClose={(evt) => {
                        this.setState({
                            addDialogOpen: false,
                        });
                    }}
                    onClosed={(evt) => { }}
                >
                    <DialogTitle
                        id="addTitle"
                        style={{
                            borderTop: 'solid',
                            borderLeft: 'solid',
                            borderRight: 'solid',
                            borderColor: 'dodgerblue',
                        }}
                    >
                        Add Patient to Watchlist
                    </DialogTitle>

                    <DialogContent
                        style={{
                            borderLeft: 'solid',
                            borderRight: 'solid',
                            borderColor: 'dodgerblue',
                        }}
                    >
                        <div>
                            <Grid id="addGrid">
                                <GridRow>
                                    <GridCell span={3}>
                                        <Typography>Patient Name:</Typography>
                                    </GridCell>
                                    <GridCell span={9}>
                                        <Select
                                            id="patientSelect"
                                            label="Patient"
                                            outlined
                                            native
                                            displayEmpty
                                            value={this.state.name}
                                            onChange={(event) => {
                                                // this.setState({
                                                //     checked: event.currentTarget.value,
                                                //     name: event.currentTarget.name,
                                                //     title:
                                                //         JSON.parse(event.currentTarget.value)
                                                //             .patientFirstName +
                                                //         ' ' +
                                                //         JSON.parse(event.currentTarget.value).patientLastName,
                                                //     id: JSON.parse(event.currentTarget.value).patientID,
                                                // });

                                                this.setState({ newPatient: JSON.parse(event.currentTarget.value) })

                                            }}
                                            labelId="Patient"
                                            inputProps={{
                                                name: 'checked',
                                            }}
                                        >
                                            {this.state.patientList.map((state) => (
                                                <option
                                                    name={state.patientID}
                                                    value={JSON.stringify(state)}
                                                >
                                                    {state.patientLastName + ', ' + state.patientFirstName}
                                                </option>
                                            ))}
                                        </Select>
                                    </GridCell>
                                </GridRow>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions
                        style={{
                            borderBottom: 'solid',
                            borderLeft: 'solid',
                            borderRight: 'solid',
                            color: 'dodgerblue',
                        }}
                    >
                        {!this.state.updatePhysican ? (
                            <DialogButton
                                action="add"
                                icon="add"
                                unelevated
                                onClick={(evt) => {
                                    //     if (
                                    //         this.isEmpty(this.state.physicianNPI) ||
                                    //         this.isEmpty(this.state.physicianFirstName.trim()) ||
                                    //         this.isEmpty(this.state.physicianLastName.trim())
                                    //     ) {
                                    //         evt.stopPropagation();
                                    //         Swal.fire({
                                    //             icon: 'error',
                                    //             title: 'Oops...',
                                    //             text: 'you must fill out the required fields',
                                    //         });
                                    //     } else {
                                    //         this.addphysician();
                                    //     }

                                    this.addWatchList(this.state.newPatient)

                                }}
                            >
                                Add
                            </DialogButton>
                        ) : (
                            <DialogButton
                                action="add"
                                icon="add"
                                unelevated
                                onClick={(evt) => {
                                    if (
                                        this.isEmpty(this.state.physicianNPI) ||
                                        this.isEmpty(this.state.physicianFirstName.trim()) ||
                                        this.isEmpty(this.state.physicianLastName.trim())
                                    ) {
                                        evt.stopPropagation();
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'you must fill out the required fields',
                                        });
                                    } else {
                                        this.updatephysician();
                                    }
                                }}
                            >
                                Update
                            </DialogButton>
                        )}
                        <DialogButton
                            icon="close"
                            outlined
                            onClick={() => {
                                this.setState({ addDialogOpen: false });
                            }}
                        >
                            Close
                        </DialogButton>
                    </DialogActions>
                </Dialog>
            );
        };

        let physiciansData = () => {
            if (this.state.watchList.length === 0) {
                return (
                    <Typography id="noResult" use="headline5">
                        No Patients on Watchlist
                    </Typography>
                );
            }
            else {
                let data = this.state.watchList.map((watchList, i) => {
                    let id = this.state.watchList[i].patientID
                    let contains = false;
                    contains = this.containsID(this.state.watchList[i].patientID)
                    return (
                        <DataTableRow key={i}>
                            <DataTableCell
                                style={{ backgroundColor: '#DBF1FF' }}
                                onClick={() => {
                                    this.setState({
                                        physicianInfoDialog: true,
                                        current: watchList,
                                    });
                                }}
                            >
                                {this.state.watchList[i].patientLastName + ', ' + this.state.watchList[i].patientFirstName}
                            </DataTableCell>
                            <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                                <BadgeAnchor>
                                    <Button
                                        icon="search"
                                        disabled={!contains}
                                        onClick={() => {
                                            let reportList = [];
                                            if (contains === true) {
                                                for (var i = 0; i < this.state.notifications.length; i++) {
                                                    if (this.state.notifications[i].patientID === id) {
                                                        reportList.push(this.state.notifications[i].reportID)
                                                    }
                                                }
                                            }
                                            this.props.deleteNotification(id)
                                            this.props.queryPatientReport();
                                            this.props.patientNewResults(
                                                reportList,
                                            );
                                            this.props.history.push('/app/result');

                                        }}
                                    >
                                        View New Results
                                    </Button>
                                    {contains === true ? (<Badge align="inline" label="New" style={{ backgroundColor: 'red', color: 'white' }} />
                                    ) : null}
                                </BadgeAnchor>
                            </DataTableCell>
                            <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                                <Button
                                    icon="delete"
                                    onClick={() => {
                                        Swal.fire({
                                            title:
                                                'Remove "' +
                                                this.state.watchList[i].patientFirstName +
                                                ' ' +
                                                this.state.watchList[i].patientLastName +
                                                '" from your Watchlist?',
                                            icon: 'question',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Continue',
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                this.setState({ editWatchList: true }, () =>
                                                    this.addWatchList(this.state.watchList[i]),
                                                    this.props.deleteNotification(this.state.watchList[i].patientID)
                                                )
                                                Swal.fire(
                                                    'Removed!',
                                                    'This patient is no longer a part of your Watchlist.',
                                                    'success'
                                                );
                                            }
                                        });
                                    }}
                                >
                                    Remove
                                </Button>
                            </DataTableCell>
                        </DataTableRow>
                    );
                });
                return data;
            }
        }
        return (
            <div>
                <div id="physicianHead">
                    <div id="physicians">
                        <Typography use="headline4"> Watchlist </Typography>
                    </div>
                </div>

                <DataTable id="physicianTable" stickyRows={1} stickyColumns={1}>
                    <DataTableContent>
                        <DataTableHead>
                            <DataTableRow>
                                <DataTableHeadCell
                                    style={{
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                    value={this.state.physicianLastSort}
                                    sort={this.state.physicianLastSort}
                                    onSortChange={(sortDir) => {
                                        this.setState(
                                            {
                                                physicianLastSort: sortDir,
                                            },
                                            () => {
                                                this.sortData(sortDir);
                                            }
                                        );
                                    }}
                                >
                                    Patient Name
                                </DataTableHeadCell>
                                <DataTableHeadCell
                                    style={{
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                >
                                    View Lab Results
                                </DataTableHeadCell>
                                <DataTableCell
                                    style={{
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                >
                                    Delete
                                </DataTableCell>
                            </DataTableRow>
                        </DataTableHead>
                        <DataTableBody>{physiciansData()}</DataTableBody>
                    </DataTableContent>
                </DataTable>
                <div>
                    {this.props.decodedJwt.role !== 'Tsalta Admin' ? (
                        <Button
                            id="disabledButton"
                            variant="contained"
                            disabled
                            onClick={() => this.setState({ addDialogOpen: true })}
                        >
                            Add Patient
                        </Button>
                    ) : (
                        <Button
                            id="addButton"
                            variant="contained"
                            onClick={() => this.setState({ addDialogOpen: true })}
                        >
                            Add Patient
                        </Button>
                    )}
                </div>
                {addDialog()}
                {/* {physicianInfoDialog()} */}
                { }
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getWatchList: () => dispatch(getWatchList()),
        getNotifications: () => dispatch(getNotifications()),
        updateUserWatchListRequest: (json) => dispatch(updateUserWatchListRequest(json)),
        queryPatientReport: () => dispatch(queryPatientReport()),
        patientLabResults: (patientID, facilityID) =>
            dispatch(patientLabResults(patientID, facilityID)),
        queryPatient: (limit, offset, sort, dir) =>
            dispatch(patientResultRequest(limit, offset, sort, dir)),
        deleteNotification: (patientID) => dispatch(deleteNotification(patientID)),
        patientNewResults: (reportID) =>
            dispatch(patientNewResults(reportID)),

    };
};

const mapStateToProps = (state) => ({
    physicians: state.physician.physicians,
    facilities: state.admin.facilities,
    decodedJwt: state.login.decodedJWT,
    watchList: state.admin.watchList,
    patients: state.patient.patients,
    notifications: state.admin.notifications,
});

export default connect(mapStateToProps, mapDispatchToProps)(WatchListComponent);
