import {
  QUERY_PATIENTS,
  QUERY_PATIENTS_SUCCESS,
  QUERY_PATIENTS_FAIL,
  QUERY_LAB_SUCCESS,
  QUERY_LAB_FAIL,
  Query_LAB,
  PATIENT_SEARCH_SUCCESS,
  PATIENT_SEARCH_FAIL,
  PATIENT_REPORT_QUERY,
  PATIENT_REPORT_SUCCESS,
  PATIENT_REPORT_FAIL,
  DIAGNOSIS_REQUEST_SUCCESS,
  DIAGNOSIS_REQUEST_FAIL,
  VITALS_REQUEST_SUCCESS,
  VITALS_REQUEST_FAIL,
  MEDICATIONS_REQUEST_SUCCESS,
  MEDICATIONS_REQUEST_FAIL,
  FACESHEET_REQUEST_SUCCESS,
  FACESHEET_REQUEST_FAIL,
  DMV_REQUEST_FAIL,
  DMV_REQUEST_SUCCESS,
  UPDATE_DMV_FAIL,
  UPDATE_DMV_SUCCESS,
  DELETE_PATIENT_FAIL,
  DELETE_PATIENT_SUCCESS,
  ADD_PATIENT_FAIL,
  UPDATE_PATIENT,
  UPDATE_PATIENT_FAIL,
  PATIENT_LOADING,
  PATIENT_LOADING_SUCCESS,
  PATIENT_LOADING_FAIL,
} from '../constants/patientConstants';

export const queryPatients = () => ({
  type: QUERY_PATIENTS,
});
export const querySuccess = (results) => ({
  type: QUERY_PATIENTS_SUCCESS,
  patientResults: JSON.parse(results.results),
  count: results.count,
});
export const queryFail = () => ({
  type: QUERY_PATIENTS_FAIL,
});
export const queryLab = () => ({
  type: Query_LAB,
});

export const queryLabFail = () => ({
  type: QUERY_LAB_FAIL,
});

export const queryLabSuccess = (results) => ({
  type: QUERY_LAB_SUCCESS,
  labResults: results,
});
export const patientSearchSuccess = (results) => ({
  type: PATIENT_SEARCH_SUCCESS,
  patientResults: JSON.parse(results.results),
  count: results.count,
});
export const patientSearchFail = () => ({
  type: PATIENT_SEARCH_FAIL,
});
export const queryPatientReport = () => ({
  type: PATIENT_REPORT_QUERY,
});
export const queryReportSuccess = (results) => ({
  type: PATIENT_REPORT_SUCCESS,
  patientResults: JSON.parse(results.results),
  count: results.count,
});
export const queryReportFail = () => ({
  type: PATIENT_REPORT_FAIL,
});
export const diagnosisRequestSuccess = (diagnosis) => ({
  type: DIAGNOSIS_REQUEST_SUCCESS,
  diagnosis: diagnosis,
});
export const diagnosisRequestFail = () => ({
  type: DIAGNOSIS_REQUEST_FAIL,
});
export const vitalsRequestSuccess = (vitals) => ({
  type: VITALS_REQUEST_SUCCESS,
  vitals: vitals,
});
export const vitalsRequestFail = () => ({
  type: VITALS_REQUEST_FAIL,
});
export const medicationsRequestSuccess = (medications) => ({
  type: MEDICATIONS_REQUEST_SUCCESS,
  medications: medications,
});
export const medicationsRequestFail = () => ({
  type: MEDICATIONS_REQUEST_FAIL,
});
export const dmvRequestSuccess = (dmv) => ({
  type: DMV_REQUEST_SUCCESS,
  dmv: dmv,
});
export const dmvRequestFail = () => ({
  type: DMV_REQUEST_FAIL,
});
export const facesheetRequestSuccess = (facesheet) => ({
  type: FACESHEET_REQUEST_SUCCESS,
  facesheet: facesheet,
});
export const facesheetRequestFail = () => ({
  type: FACESHEET_REQUEST_FAIL,
});
export const updateDMVSuccess = () => ({
  type: UPDATE_DMV_SUCCESS,
});
export const updateDMVFail = () => ({
  type: UPDATE_DMV_FAIL,
});
export const deletePatientSuccess = () => ({
  type: DELETE_PATIENT_SUCCESS,
});
export const addPatientFail = () => ({
  type: ADD_PATIENT_FAIL,
});
export const deletePatientFail = () => ({
  type: DELETE_PATIENT_FAIL,
});
export const updatePatient = () => ({
  type: UPDATE_PATIENT,
});
export const updatePatientFail = () => ({
  type: UPDATE_PATIENT_FAIL,
});
export const loadPatient = () => ({
  type: PATIENT_LOADING,
});
export const loadPatientFail = () => ({
  type: PATIENT_LOADING_FAIL,
});

export const loadPatientSuccess = () => ({
  type: PATIENT_LOADING_SUCCESS,
});

export const patientResultRequest = (limit, offset, sort, dir) => {
  return (dispatch) => {
    let url = null;

    url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/patientDrQuery?col=*&' +
        'limit=' +
        limit +
        '&offset=' +
        offset +
        '&orderby=' +
        sort +
        '&dir=' +
        dir +
        '&param=Patients.doctorNPI=Doctors.doctorNPI' +
        '&where='
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(querySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const labRequest = (count, reportid, offset) => {
  return (dispatch) => {
    let url = null;
    if (reportid !== null) {
      url = new URL(
        process.env.REACT_APP_API_URL + '/data/reportIDQuery?col=*'
      );
    } else {
      url = new URL(
        process.env.REACT_APP_API_URL +
          '/data/paginationQuery?col=*&limit=350&offset=' +
          offset +
          '&t=res'
      );
    }
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        reportID: reportid,
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(querySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const patientLabResults = (patientID, facilityID) => {
  return (dispatch) => {
    let url = null;
    let param = null;

    param =
      'Patients.patientID="' +
      patientID +
      '" AND Patients.patientFacilityID = "' +
      facilityID +
      '"';
    let limit = 10;
    let offset = 0;
    let sort = 'reportID';
    let dir = 'ASC';

    url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/resultsQuery?col=*' +
        '&limit=' +
        limit +
        '&offset=' +
        offset +
        '&orderby=' +
        sort +
        '&dir=' +
        dir +
        '&where=' +
        param
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryReportSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryReportFail(error));
      });
  };
};
export const patientNewResults = (reportID) => {
  return (dispatch) => {
    let reports = '';
    for (var i=0; i<reportID.length-1; i++){
      reports = reports + "Reports.reportID = '" + reportID[i] + "' OR ";
    }
    reports = reports + "Reports.reportID = '" + reportID[reportID.length-1] + "'"
    let url = null;
    let param = null;

    param =
      reports;
    let limit = 10;
    let offset = 0;
    let sort = 'reportID';
    let dir = 'ASC';

    url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/resultsQuery?col=*' +
        '&limit=' +
        limit +
        '&offset=' +
        offset +
        '&orderby=' +
        sort +
        '&dir=' +
        dir +
        '&where=' +
        param
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        console.log("search Result")
        console.log(searchResult)
        dispatch(queryReportSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryReportFail(error));
      });
  };
};
export const patientDoctorJoin = (count, offset) => {
  return (dispatch) => {
    let url = null;

    url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/patientQuery?col=*&limit=' +
        count +
        '&offset=' +
        offset +
        '&t=rep' +
        '&where='
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(querySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const searchRequest = (col, term, limit, offset, sort, dir) => {
  return (dispatch) => {
    let url = null;
    let where = null;
    switch (col) {
      case 'Patient Last Name':
        where = 'patientLastName="' + term + '"';
        break;
      case 'Facility':
        where = 'facilityName="' + encodeURIComponent(term) + '"';
        break;
      default:
        break;
    }

    url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/patientDrQuery?col=*&' +
        'limit=' +
        limit +
        '&offset=' +
        offset +
        '&orderby=' +
        sort +
        '&dir=' +
        dir +
        '&param=Patients.doctorNPI=Doctors.doctorNPI' +
        '&where=' +
        where
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(patientSearchSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(patientSearchFail(error));
      });
  };
};
export const dmvRequest = (term, groupBy) => {
  return (dispatch) => {
    let url = null;
    let where = null;
    where = 'Patients.patientID="' + term + '"';
    url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/dmvQuery?col=' +
        '&where=' +
        where +
        '&groupBy=' +
        groupBy
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(dmvRequestSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(dmvRequestFail(error));
      });
  };
};
export const facesheetRequest = (term, count, offset, type) => {
  return (dispatch) => {
    let url = null;
    let where = null;
    where = 'Patients.patientID="' + term + '"';

    url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/patientQuery?col=*&limit=' +
        count +
        '&offset=' +
        offset +
        '&where=' +
        where
    );

    dispatch(loadPatient());
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(facesheetRequestSuccess(searchResult));
        if (type === '') {
          dispatch(loadPatientSuccess());
        }
      })
      .catch((error) => {
        dispatch(facesheetRequestFail(error));
      });
  };
};

export const updateDMVRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/updateDMV');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(updateDMVSuccess());
      })
      .catch((error) => {
        dispatch(updateDMVFail(error));
      });
  };
};

export const deletePatientRequest = (patientID) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/deletePatient'
    );
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ patientID: patientID }),
    })
      .then(() => {
        dispatch(deletePatientSuccess());
      })
      .then(() => {
        dispatch(patientResultRequest(10, 0, 'lastName', 'ASC'));
      })
      .catch((error) => {
        dispatch(deletePatientFail(error));
      });
  };
};
export const addPatientRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/addPatient');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(patientResultRequest(10, 0, 'lastName', 'ASC'));
      })
      .catch((error) => {
        dispatch(addPatientFail(error));
      });
  };
};
export const updatePatientRequest = (json) => {
  return (dispatch) => {
    dispatch(updatePatient());
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/updatePatient'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(patientResultRequest(10, 0, 'lastName', 'ASC'));
      })
      .catch((error) => {
        dispatch(updatePatientFail(error));
      });
  };
};
export const closePatient = () => {
  return (dispatch) => {
    dispatch(updatePatient());
  };
};
export const addPatientNote = (patientID, history) => {
  let myJSON = {};
  myJSON.patientHistory = history;
  myJSON.patientID = patientID;
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/addPatientNote'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(myJSON),
    })
      .then((response) => {
        dispatch(facesheetRequest(patientID, 25, 0));
      })
      .catch((error) => {
        dispatch(updatePatientFail(error));
      });
  };
};
