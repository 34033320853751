export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_DBS_FAIL = 'LOGIN_DBS_FAILURE';
export const LOGIN_DBS_SUCCESS = 'LOGIN_DBS_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE';
export const VERIFY_PASS_REQUEST = 'VERIFY_PASS_REQUEST';
export const VERIFY_PASS_SUCCESS = 'VERIFY_PASS_SUCCESS';
export const VERIFY_PASS_FAILURE = 'VERIFY_PASS_FAILURE';
export const CHANGE_PASS_REQUEST = 'CHANGE_PASS_REQUEST';
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const CHANGE_PASS_FAILURE = 'CHANGE_PASS_FAILURE';
export const LOGOUT = 'LOGOUT';
