import { ControlCameraOutlined, SmsOutlined } from '@material-ui/icons';
import {
  METRICS_REQUEST,
  METRICS_SUCCESS,
  METRICS_FAILURE,
  TRENDS_FAILURE,
  TRENDS_REQUEST,
  TRENDS_SUCCESS,
  PA_REQUEST,
  PA_SUCCESS,
  PA_FAILURE,
  CLEAR_METRICS,
  METRICS_REPORT_SUCCESS,
  METRICS_REPORT_FAILURE,
  METRICS_REPORT,
  GROUP_QUERY_FAIL,
  GROUP_QUERY_SUCCESS,
  SUBTEST_REPORT_SUCCESS,
  SUBTEST_REPORT_FAIL,
  SUBTEST_REPORT_QUERY,
  POPUP_SUCCESS,
  POPUP_LOADING_FAIL,
  POPUP_LOADING,
} from '../constants/metricsConstants';
import { downloadFile, openNewTabFile } from '../utils/Utils';
import $ from 'jquery';
import Swal from 'sweetalert2';

export const metricsRequest = () => ({
  type: METRICS_REQUEST,
});

export const metricsSuccess = (data) => ({
  metrics: data,
  type: METRICS_SUCCESS,
});

export const metricsFail = (error) => ({
  type: METRICS_FAILURE,
});

export const paRequest = () => ({
  type: PA_REQUEST,
});

export const paSuccess = (data) => ({
  metrics: data,
  type: PA_SUCCESS,
});

export const paFail = (error) => ({
  type: PA_FAILURE,
});
export const trendsRequest = () => ({
  type: TRENDS_REQUEST,
});

export const trendsSuccess = (data) => ({
  trends: data,
  type: TRENDS_SUCCESS,
});
export const popupSuccess = (data) => ({
  popup: data,
  type: POPUP_SUCCESS,
});
export const loadPopup = () => ({
  type: POPUP_LOADING,
});
export const loadPopupFail = () => ({
  type: POPUP_LOADING_FAIL,
});

export const trendsFail = (error) => ({
  type: TRENDS_FAILURE,
});

export const clearMetrics = () => ({
  type: CLEAR_METRICS,
});
export const metricsReport = () => ({
  type: METRICS_REPORT,
});
export const groupQuerySuccess = (results) => ({
  type: GROUP_QUERY_SUCCESS,
  groups: results,
});
export const groupQueryFail = () => ({
  type: GROUP_QUERY_FAIL,
});
export const queryReportSuccess = (results) => ({
  type: SUBTEST_REPORT_SUCCESS,
  subtestResults: JSON.parse(results.results),
  count: results.count,
});
export const queryReportFail = () => ({
  type: SUBTEST_REPORT_FAIL,
});
export const querySubtestReport = () => ({
  type: SUBTEST_REPORT_QUERY,
});

export const metricsReportSuccess = (reports) => {
  if ($.isEmptyObject(reports)) {
    Swal.fire({
      icon: 'info',
      text: 'There were no search results',
    });
  }
  return {
    type: METRICS_REPORT_SUCCESS,
    report: reports,
  };
};
export const metricsReportFailure = () => ({
  type: METRICS_REPORT_FAILURE,
});

export const getMetricsRequest = (bodyFiller) => {
  console.log(bodyFiller)
  return (dispatch) => {
    dispatch(metricsRequest());
    let url = new URL(process.env.REACT_APP_API_URL + '/data/metricsQuery');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(bodyFiller),
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((metricResult) => {
        dispatch(metricsSuccess(metricResult));
      })
      .catch((error) => {
        dispatch(metricsFail(error));
      });
  };
};
export const getQRMetricsRequest = (bodyFiller) => {
  console.log(bodyFiller)
  return (dispatch) => {
    dispatch(metricsRequest());
    let url = new URL(process.env.REACT_APP_API_URL + '/report/qrMetricsQuery');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(bodyFiller),
      credentials: 'include',
    })
      .then((response) => {
        return response.text();
      })
      .then((metricResult) => {
        let json = JSON.parse(metricResult);
        if (json.file) {
          dispatch(addARRequest(json));
          openNewTabFile('testPDF', json.file);
        }
        dispatch(metricsReportSuccess(json));
      })
      .catch((error) => {
        dispatch(metricsFail(error));
      });
  };
};

export const getQRTrendsRequest = (bodyFiller) => {
  return (dispatch) => {
    dispatch(trendsRequest());
    let url = new URL(process.env.REACT_APP_API_URL + '/report/qrTrendsQuery');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(bodyFiller),
      credentials: 'include',
    })
      .then((response) => {
        return response.text();
      })
      .then((searchResult) => {
        if (searchResult.length === 0) {
          if ($.isEmptyObject(searchResult)) {
            Swal.fire({
              icon: 'info',
              text: 'There were no search results',
            });
          }
        }
        let json = JSON.parse(searchResult);
        if (json.file) {
          dispatch(addARRequest(json));
          openNewTabFile('testPDF', json.file);
        }
        dispatch(metricsReportSuccess(json));
      })
      .catch((error) => {
        dispatch(metricsReportFailure(error));
      });
  };
};
export const addARRequest = (json) => {
  console.log(json);
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/addAutoReport');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        // dispatch(getQuickReports());
      })
      .catch((error) => {
        dispatch(metricsReportFailure(error));
      });
  };
};
export const printMetrics = (body) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/report/printMetricsQuery'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    })
      .then((response) => {
        return response.text();
      })
      .then((searchResult) => {
        let json = JSON.parse(searchResult);
        if (json.file) {
          openNewTabFile('testPDF', json.file);
        }
        dispatch(metricsReportSuccess(json));
      })
      .catch((error) => {
        dispatch(metricsReportFailure(error));
      });
  };
};


export const getPARequest = (bodyFiller) => {
  return (dispatch) => {
    dispatch(paRequest());
    let url = new URL(
      process.env.REACT_APP_API_URL + '/data/patientAnalyticsQuery'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(bodyFiller),
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((metricResult) => {
        dispatch(paSuccess(metricResult));
      })
      .catch((error) => {
        dispatch(paFail(error));
      });
  };
};

export const getTrendsRequest = (bodyFiller) => {
  return (dispatch) => {
    dispatch(trendsRequest());
    let url = new URL(process.env.REACT_APP_API_URL + '/data/trendsQuery');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(bodyFiller),
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((trendResult) => {
        dispatch(trendsSuccess(trendResult));
      })
      .catch((error) => {
        dispatch(trendsFail(error));
      });
  };
};
export const getPopup = (bodyFiller, test) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/getPopup?param=' + test.replaceAll("_", " "));
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(bodyFiller),
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((trendResult) => {
        dispatch(popupSuccess(trendResult));
      })
      .catch((error) => {
        dispatch(loadPopupFail(error));
      });
  };
};
export const trendPopup = (bodyFiller, test) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/trendPopup?param=' + test);
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(bodyFiller),
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((trendResult) => {
        dispatch(popupSuccess(trendResult));
      })
      .catch((error) => {
        dispatch(loadPopupFail(error));
      });
  };
};

export const printPTA = (body) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/report/printPTAQuery'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    })
      .then((response) => {
        return response.text();
      })
      .then((searchResult) => {
        let json = JSON.parse(searchResult);
        if (json.file) {
          openNewTabFile('testPDF', json.file);
        }
        dispatch(metricsReportSuccess(json));
      })
      .catch((error) => {
        dispatch(metricsReportFailure(error));
      });
  };
};
export const printTrends = (body) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/report/printTrendsQuery'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    })
      .then((response) => {
        return response.text();
      })
      .then((searchResult) => {
        let json = JSON.parse(searchResult);
        if (json.file) {
          openNewTabFile('testPDF', json.file);
        }
        dispatch(metricsReportSuccess(json));
      })
      .catch((error) => {
        dispatch(metricsReportFailure(error));
      });
  };
};
export const getGroupsRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/readGroups');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(groupQuerySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(groupQueryFail(error));
      });
  };
};
export const queryLabResults = (subtest, id, type, start, end) => {
  return (dispatch) => {
    let url = null;
    let param = null;

    subtest = subtest.replaceAll("_", " ")

    if (type === 'facility') {
      param =
        'Facilities.facilityName="' +
        id +
        '" AND OBX.subtest = "' +
        subtest +
        '" AND OBX.reportedDate BETWEEN "' + start + ' 00:00:00" AND "' + end + ' 23:59:59"';

    }
    else if (type === 'patient') {
      param =
        'Patients.patientID="' +
        id +
        '" AND OBX.subtest = "' +
        subtest +
        '" AND OBX.reportedDate BETWEEN "' + start + ' 00:00:00" AND "' + end + ' 23:59:59"';
    }
    else if (type === 'group') {
      param =
        'Facilities.facilityGroupID="' +
        id +
        '" AND OBX.subtest = "' +
        subtest +
        '" AND OBX.reportedDate BETWEEN "' + start + ' 00:00:00" AND "' + end + ' 23:59:59"';
    }
    else if (type === 'state') {
      param =
        'Facilities.facilityState="' +
        id +
        '" AND OBX.subtest = "' +
        subtest +
        '" AND OBX.reportedDate BETWEEN "' + start + ' 00:00:00" AND "' + end + ' 23:59:59"';
    }

    let limit = 10;
    let offset = 0;
    let sort = 'reportID';
    let dir = 'ASC';

    url = new URL(
      process.env.REACT_APP_API_URL +
      '/data/resultsQuery?col=*' +
      '&limit=' +
      limit +
      '&offset=' +
      offset +
      '&orderby=' +
      sort +
      '&dir=' +
      dir +
      '&where=' +
      encodeURIComponent(param)
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryReportSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryReportFail(error));
      });
  };
};
