import React from 'react';
import { connect } from 'react-redux';
import {
  patientResultRequest,
  searchRequest,
  patientLabResults,
  queryPatientReport,
  facesheetRequest,
  dmvRequest,
  updateDMVRequest,
  deletePatientRequest,
  addPatientRequest,
  updatePatientRequest,
  closePatient,
} from '../actions/patientActions';
import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import '@rmwc/dialog/styles';
import TablePagination from '@material-ui/core/TablePagination';
import '../css/patientComponent.css';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { getFacilityRequest } from '../actions/userActions';
import Swal from 'sweetalert2';
import { generateLabReq } from '../actions/reportActions';
import { getLabsRequest } from '../actions/labManagementActions';
import { labTestsList } from '../actions/reportActions';
import { Paper, ListItemIcon, ListItemText } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Checkbox } from '@rmwc/checkbox';
import FormControl from '@material-ui/core/FormControl';
import { LinearProgress } from '@rmwc/linear-progress';
import { getPhysiciansRequest } from '../actions/physicianActions';
import 'react-calendar/dist/Calendar.css';
import PatientInfoComponent from './PatientInfoComponent';
import { openDialog } from '../actions/dialogActions';
import ResultComponent from './ResultComponent';
import { Icon } from '@rmwc/icon';
import {
  getWatchList,
  updateUserWatchListRequest,
  deleteNotification,
} from '../actions/userActions';

class PatientComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      query: '',
      patients: [],
      editPatient: false,
      editWatchList: false,
      patientInfoDialog: false,
      patientLabDialog: false,
      currentPatient: [],
      patientFirstSort: null,
      patientLastSort: 1,
      facilitySort: null,
      pagnatedPatients: [],
      patientsPerPage: 10,
      patientsPage: 0,
      patientsTotal: 100,
      filteredPatients: [],
      results: [],
      facilityList: [],
      diagnosis: [],
      vitals: [],
      medications: [],
      offset: 0,
      facesheet: [],
      sortCol: 'lastName',
      sortDir: 'ASC',
      searching: false,
      tabIndex: 0,
      dmv: [],
      orderList: [],
      eFOpen: false,
      addPatientDialogOpen: false,
      fName: '',
      physicians: [],
      panelList: null,
      watchList: [],
    };
    this.keyPress = this.keyPress.bind(this);
  }
  static getDerivedStateFromProps(props, state) {
    let facilityList = [''];
    props.facilities.forEach((facility) => {
      if (!facilityList.includes(facility)) {
        facilityList.push(facility);
      }
    });
    let physicianList = [''];
    props.physicians.forEach((dr) => {
      physicianList.push(dr);
    });
    let labList = [];
    props.labs.forEach((lab) => {
      labList.push(lab);
    });

    let testOptions = [];
    if (props.labTests.size !== 0) {
      let keys = props.labTests.keys();
      for (let i = 0; i < props.labTests.size; i++) {
        testOptions.push(keys.next().value);
      }
    }

    if (props.patientLoadingSuccess && !state.editPatient) {
      return {
        ...state,
        editPatient: true,
        count: props.patientTotal,
        patients: props.patients,
        results: props.results,
        facilities: props.facilities,
        facilityList: facilityList,
        diagnosis: props.diagnosis,
        vitals: props.vitals,
        medications: props.medications,
        facesheet: props.facesheet,
        dmv: props.dmv,
        labList: labList,
        testOptions: testOptions,
        currentPatient: props.facesheet,
        physicians: props.physicians,
        physicianList: physicianList,
        patientFirstName: props.facesheet.patientFirstName,
        patientLastName: props.facesheet.patientLastName,
        patientAddress: props.facesheet.patientAddress,
        patientCity: props.facesheet.patientCity,
        patientState: props.facesheet.patientState,
        patientZipcode: props.facesheet.patientZipcode,
        patientSex: props.facesheet.patientSex,
        patientRoomNum: props.facesheet.patientRoomNum,
        patientDOB: new Date(props.facesheet.patientDOB)
          .toISOString()
          .slice(0, 10),
        doctorNPI: props.facesheet.doctorNPI,
        patientFacilityID: props.facesheet.patientFacilityID,
        payerName: props.facesheet.payerName,
        groupID: props.facesheet.groupID,
        policyID: props.facesheet.policyID,
        editPatientDialogOpen: true,
        role: props.decodedJWT.role,
      };
    } else if (state.editWatchList) {
      return {
        ...state,
        count: props.patientTotal,
        patients: props.patients,
        results: props.results,
        facilities: props.facilities,
        facilityList: facilityList,
        diagnosis: props.diagnosis,
        vitals: props.vitals,
        medications: props.medications,
        facesheet: props.facesheet,
        dmv: props.dmv,
        labList: labList,
        currentPatient: props.facesheet,
        physicians: props.physicians,
        physicianList: physicianList,
        testOptions: testOptions,
        role: props.decodedJWT.role,
      };
    } else {
      return {
        ...state,
        count: props.patientTotal,
        patients: props.patients,
        results: props.results,
        facilities: props.facilities,
        facilityList: facilityList,
        diagnosis: props.diagnosis,
        vitals: props.vitals,
        medications: props.medications,
        facesheet: props.facesheet,
        dmv: props.dmv,
        labList: labList,
        currentPatient: props.facesheet,
        physicians: props.physicians,
        physicianList: physicianList,
        testOptions: testOptions,
        role: props.decodedJWT.role,
        watchList: props.watchList,
      };
    }
  }
  componentDidMount() {
    this.props.queryPatient(
      this.state.patientsPerPage,
      this.state.offset,
      this.state.sortCol,
      this.state.sortDir
    );
    this.props.getFacilities();
    this.props.getLabsRequest();
    this.props.getphysicians();
    this.props.getWatchList();
  }
  keyPress(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.props.searchResults(
        this.state.searchCol,
        this.state.query,
        this.state.patientsPerPage,
        this.state.offset,
        this.state.sortCol,
        this.state.sortDir
      );
    }
  }
  handleCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();
    window.location.reload(false);
  }
  componentDidUpdate() {
    this.render();
  }
  clearSearch() {
    this.setState({
      noResults: false,
      filteredPatients: [],
      searchCol: '',
      query: '',
      offset: 0,
      patientsPage: 0,
      searching: false,
    });
    this.props.queryPatient(
      this.state.patientsPerPage,
      this.state.offset,
      this.state.sortCol,
      this.state.sortDir
    );
  }
  createChecked(formType) {
    let temp = [];
    temp = [];
    this.setState({ testType: this.state.orderList }, () => {
      this.submitForm(formType);
    });
  }
  submitForm(formType) {
    if (formType === 'patient') {
      this.props.generateLabReq(
        this.state.reportSelect,
        {},
        this.state.testType,
        this.state.selectedLab,
        this.state.test
      );
    }
  }
  handleChangePage = (event, newPage) => {
    let offset = this.state.patientsPerPage * newPage;
    this.setState(
      {
        patientsPage: newPage,
        offset: offset,
      },
      () => {
        let offset = this.state.patientsPerPage * this.state.patientsPage;
        if (this.state.searching) {
          this.props.searchResults(
            this.state.searchCol,
            this.state.query,
            this.state.patientsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        } else {
          this.props.queryPatient(
            this.state.patientsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        }
      }
    );
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        patientsPage: 0,
        patientsPerPage: parseInt(event.target.value, 10),
        patientFirstSort: 0,
        patientLastSort: 1,
        facilitySort: 0,
        offset: 0,
      },
      () => {
        if (this.state.searching) {
          this.props.searchResults(
            this.state.searchCol,
            this.state.query,
            this.state.patientsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        } else {
          this.props.queryPatient(
            this.state.patientsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        }
      }
    );
  };
  sortData(sortDir, column) {
    if (sortDir === 1) {
      this.setState({ sortCol: column, sortDir: 'ASC' }, () => {
        if (this.state.searching) {
          this.props.searchResults(
            this.state.searchCol,
            this.state.query,
            this.state.patientsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        } else {
          this.props.queryPatient(
            this.state.patientsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        }
      });
    } else if (sortDir === -1) {
      this.setState({ sortCol: column, sortDir: 'DESC' }, () => {
        if (this.state.searching) {
          this.props.searchResults(
            this.state.searchCol,
            this.state.query,
            this.state.patientsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        } else {
          this.props.queryPatient(
            this.state.patientsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        }
      });
    }
  }
  updateDMV = () => {
    let dmvJson = {
      patientFirstName: this.state.currentPatient.patientFirstName,
      patientLastName: this.state.currentPatient.patientLastName,
      patientID: this.state.currentPatient.patientID,
      facilityName: this.state.currentPatient.facilityName,
      facilityLoginID: this.state.facesheet.facilityLoginID,
    };
    this.props.updateDMVRequest(dmvJson);
  };
  orderList(evt, checkname) {
    if (!this.state.orderList.includes(evt.currentTarget.value)) {
      var newState = this.state.orderList.slice();
      newState.push(evt.currentTarget.value);
      this.setState({
        orderList: newState,
        [checkname]: true,
      });
    } else {
      newState = this.state.orderList.slice();
      for (var i = 0; i < newState.length; i++) {
        if (newState[i] === evt.currentTarget.value) {
          newState.splice(i, 1);
          i--;
        }
      }
      this.setState({
        orderList: newState,
        [checkname]: false,
      });
    }
  }
  addPatient = () => {
    let roomNum = 0;
    {
      this.state.patientRoomNum === ''
        ? (roomNum = 0)
        : (roomNum = this.state.patientRoomNum);
    }
    let patientJson = {
      patientFirstName: this.state.patientFirstName,
      patientLastName: this.state.patientLastName,
      patientAddress: this.state.patientAddress,
      patientCity: this.state.patientCity,
      patientState: this.state.patientState,
      patientZipcode: this.state.patientZipcode,
      patientEmail: this.state.patientEmail,
      patientFacilityID: this.state.patientFacilityID,
      patientDOB: this.state.patientDOB,
      doctorNPI: this.state.doctorNPI,
      patientSex: this.state.patientSex,
      patientRoomNum: roomNum,
      payerName: this.state.payerName,
      groupID: this.state.groupID,
      policyID: this.state.policyID,
    };
    this.props.addPatientRequest(patientJson);
  };
  updatePatient = (patientID) => {
    let patientJson = {
      patientID: patientID,
      patientFirstName: this.state.patientFirstName,
      patientLastName: this.state.patientLastName,
      patientAddress: this.state.patientAddress,
      patientCity: this.state.patientCity,
      patientState: this.state.patientState,
      patientZipcode: this.state.patientZipcode,
      patientEmail: this.state.patientEmail,
      patientFacilityID: this.state.patientFacilityID,
      patientDOB: this.state.patientDOB,
      doctorNPI: this.state.doctorNPI,
      patientSex: this.state.patientSex,
      payerName: this.state.payerName,
      groupID: this.state.groupID,
      policyID: this.state.policyID,
      patientRoomNum: this.state.patientRoomNum,
    };
    this.props.updatePatientRequest(patientJson);
  };
  isEmpty(value) {
    if (
      value == null ||
      value.length === 0 ||
      value == undefined ||
      value == ''
    ) {
      return true;
    } else {
      return false;
    }
  }
  clearChecks = () => {
    Object.keys(this.state).map((key) => {
      let regex = new RegExp('checkd*');
      this.setState({ orderList: [] });
      if (regex.test(key)) {
        this.setState({ [key]: false });
      }
      return null;
    });
  };
  testSelect() {
    let panelList = [];
    if (this.state.test) {
      this.setState({
        panelList: this.props.labTests.get(this.state.test),
      });
    }
  }

  addWatchList = (evt) => {
    let newState = [];
    let match = false;
    newState = this.state.watchList.slice();
    for (var i = 0; i < newState.length; i++) {
      if (newState[i].patientID === evt.patientID) {
        newState.splice(i, 1);
        i--;
        match = true;
      }
    }

    if (!match) {
      newState = this.state.watchList.slice();
      newState.push(evt);
    }
    this.setState(
      {
        watchList: newState,
      },
      () => this.props.updateUserWatchListRequest(this.state.watchList)
    );
  };
  containsID(id) {
    for (var i = 0; i < this.state.watchList.length; i++) {
      if (this.state.watchList[i].patientID === id) {
        return true;
      }
    }
    return false;
  }
  render() {
    const testTypeList = ['Serum', 'Plasma', 'Urine', 'Wound', 'Stool'];
    const states = [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ];
    let selectTestList = () => {
      return (
        <div>
          <Paper id="generalPaper" variant="outlined">
            <Typography
              use="headline6"
              style={{
                color: 'dodgerblue',
                left: '30%',
                position: 'relative',
              }}
            >
              Test Select
            </Typography>
            <List
              style={{
                height: '90%',
                position: 'absolute',
                overflow: 'auto',
                width: '100%',
              }}
              id="list1"
              dense
              component="div"
              role="list"
            >
              {console.log(this.props.labTests.get(this.state.test))}
              {this.state.test !== null &&
              this.state.test !== undefined &&
              this.state.test !== '' &&
              this.state.test.length !== 0 &&
              this.props.labTests.get(this.state.test) !== undefined ? (
                this.props.labTests.get(this.state.test).map((labTest) => {
                  let test = JSON.stringify(labTest);
                  let checkname = 'check' + labTest.panelName;

                  if (labTest.type === this.state.test) {
                    return (
                      <ListItem role="listitem">
                        <ListItemIcon>
                          <Checkbox
                            name={labTest.panelName}
                            tabIndex={-1}
                            disableRipple
                            checked={this.state[checkname]}
                            value={test}
                            onChange={(evt) => {
                              this.orderList(evt, checkname);
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={labTest.panelName} />
                      </ListItem>
                    );
                  }
                })
              ) : (
                <Typography>There are no tests of this type</Typography>
              )}
              <ListItem />
            </List>
          </Paper>
        </div>
      );
    };
    let searchField = () => {
      if (this.state.searchCol === 'Facility')
        return (
          <GridRow>
            <GridCell span={9}>
              <Select
                label="facilitySearch"
                outlined
                value={this.state.query}
                onChange={(event) => {
                  this.setState({
                    query: event.currentTarget.value,
                  });
                }}
              >
                {this.state.facilityList.map((facility) => {
                  return (
                    <option
                      value={facility.facilityName}
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                      {facility.facilityName}
                    </option>
                  );
                })}
              </Select>
            </GridCell>

            <GridCell span={3}>
              <Button
                style={{ position: 'absolute', top: '4%' }}
                id="facilitySearchButton"
                icon="search"
                onClick={() => {
                  this.setState({ searching: true, offset: 0 }, () => {
                    this.props.searchResults(
                      this.state.searchCol,
                      this.state.query,
                      this.state.patientsPerPage,
                      this.state.offset,
                      this.state.sortCol,
                      this.state.sortDir
                    );
                  });
                }}
              ></Button>
            </GridCell>
          </GridRow>
        );
    };
    let addPatientDialog = () => {
      let facilityList = this.state.facilityList;
      return (
        <Dialog
          open={this.state.addPatientDialogOpen}
          onClose={(evt) => {
            this.setState({ addPatientDialogOpen: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            {'Add Patient'}
          </DialogTitle>

          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Patient Name:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    required
                    style={{ width: '300px' }}
                    placeholder="First Name"
                    outlined
                    name="patientFirstName"
                    value={this.state.patientFirstName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    required
                    style={{ width: '300px' }}
                    placeholder="Last Name"
                    outlined
                    name="patientLastName"
                    value={this.state.patientLastName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>DOB / Sex: </Typography>
                </GridCell>
                <GridCell span={4}>
                  <form noValidate>
                    <TextField
                      required
                      style={{ width: '300px' }}
                      id="patientDOB"
                      name="patientDOB"
                      type="date"
                      outlined
                      value={this.state.patientDOB}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handleChange}
                    />
                  </form>
                </GridCell>
                <GridCell span={4}>
                  <FormControl style={{ width: '300px' }}>
                    <Select
                      required
                      label="Patient Sex"
                      outlined
                      options={['M', 'F']}
                      value={this.state.patientSex}
                      onChange={(event) => {
                        this.setState({
                          patientSex: event.currentTarget.value,
                        });
                      }}
                    ></Select>
                  </FormControl>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Address:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '610px' }}
                    outlined
                    placeholder="Address"
                    name="patientAddress"
                    value={this.state.patientAddress}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>City / State / Zip:</Typography>
                </GridCell>
                <GridCell span={3}>
                  <TextField
                    style={{ width: '200px' }}
                    outlined
                    placeholder="City"
                    name="patientCity"
                    value={this.state.patientCity}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
                <GridCell
                  span={3}
                  style={{ left: '-10%', position: 'relative', width: '185px' }}
                >
                  <Select
                    outlined
                    native
                    displayEmpty
                    value={this.state.patientState}
                    onChange={(event) =>
                      this.setState({
                        patientState: event.currentTarget.value,
                      })
                    }
                    placeholder="State"
                    inputProps={{
                      name: 'labState',
                    }}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </GridCell>
                <GridCell span={3}>
                  <TextField
                    style={{
                      left: '-27%',
                      position: 'relative',
                      width: '200px',
                    }}
                    outlined
                    placeholder="Zipcode"
                    name="patientZipcode"
                    value={this.state.patientZipcode}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Email Address:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '610px' }}
                    placeholder="Email Address"
                    outlined
                    name="patientEmail"
                    value={this.state.patientEmail}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Facility / Physician:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <FormControl style={{ width: '375px' }}>
                    <Select
                      required
                      id="facilitySelect"
                      label="Patient Facility"
                      outlined
                      onChange={(event) =>
                        this.setState({
                          patientFacilityID: event.currentTarget.value,
                        })
                      }
                      labelId="Facility"
                      value={this.state.patientFacilityID}
                      inputProps={{
                        name: 'patientFacilityID',
                      }}
                    >
                      {facilityList.map((state) => (
                        <option key={state.facilityID} value={state.facilityID}>
                          {state.facilityName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridCell>
                <GridCell span={4}>
                  <FormControl
                    style={{
                      width: '220px',
                      position: 'relative',
                      left: '30%',
                    }}
                  >
                    <Select
                      required
                      id="physicianSelect"
                      label="Patient Physician"
                      outlined
                      native
                      displayEmpty
                      onChange={(event) =>
                        this.setState({ doctorNPI: event.currentTarget.value })
                      }
                      labelId="Physician"
                      value={this.state.doctorNPI}
                      inputProps={{
                        name: 'doctorNPI',
                      }}
                    >
                      {this.state.physicians.map((state) => (
                        <option key={state.doctorNPI} value={state.doctorNPI}>
                          {state.doctorFirstName + ' ' + state.doctorLastName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Room Number:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '610px', top: '10px' }}
                    placeholder="Room Number"
                    outlined
                    name="patientRoomNum"
                    value={this.state.patientRoomNum}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <hr style={{ color: 'black', borderTop: 'solid' }}></hr>
              <GridRow>
                <GridCell span={12}>
                  <Typography
                    use={'headline6'}
                    style={{ left: '40%', position: 'relative' }}
                  >
                    Insurance Information
                  </Typography>
                </GridCell>
              </GridRow>
              <hr style={{ color: 'transparent' }}></hr>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Payer Name:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '610px', top: '10px' }}
                    placeholder="Payer Name"
                    outlined
                    name="payerName"
                    value={this.state.payerName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow style={{ top: '10px', position: 'relative' }}>
                <GridCell span={3}>
                  <Typography>Group ID / Policy ID</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '300px' }}
                    placeholder="Group ID"
                    outlined
                    name="groupID"
                    value={this.state.groupID}
                    onChange={(event) => {
                      this.setState({
                        groupID: event.currentTarget.value,
                      });
                    }}
                  ></TextField>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '300px' }}
                    placeholder="Policy ID"
                    outlined
                    name="policyID"
                    value={this.state.policyID}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <Button
              action="Add"
              unelevated
              icon="add"
              onClick={() => {
                if (
                  this.isEmpty(this.state.patientFirstName) ||
                  this.isEmpty(this.state.patientLastName) ||
                  this.isEmpty(this.state.patientDOB) ||
                  this.isEmpty(this.state.patientSex) ||
                  this.isEmpty(this.state.patientFacilityID) ||
                  this.isEmpty(this.state.doctorNPI)
                ) {
                  Swal.fire({
                    icon: 'error',
                    text: 'Please fill out all required fields fields',
                  });
                } else {
                  this.addPatient();
                  this.setState({ addPatientDialogOpen: false });
                }
              }}
            >
              Add
            </Button>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({
                  addPatientDialogOpen: false,
                  patientState: '',
                  patientAddress: '',
                  patientName: '',
                  patientCity: '',
                  patientZipcode: '',
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let editPatientDialog = () => {
      let facilityList = this.state.facilityList;
      return (
        <Dialog
          open={this.state.editPatientDialogOpen}
          onClose={(evt) => {
            this.setState({ editPatientDialogOpen: false, editPatient: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            {'Modify Patient'}
          </DialogTitle>

          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Patient Name:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    required
                    style={{ width: '300px' }}
                    placeholder="First Name"
                    outlined
                    name="patientFirstName"
                    value={this.state.patientFirstName}
                    onChange={(event) => {
                      this.setState({
                        patientFirstName: event.currentTarget.value,
                      });
                    }}
                  ></TextField>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    required
                    style={{ width: '300px' }}
                    placeholder="Last Name"
                    outlined
                    name="patientLastName"
                    value={this.state.patientLastName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>DOB / Sex: </Typography>
                </GridCell>
                <GridCell span={4}>
                  <form noValidate>
                    <TextField
                      required
                      style={{ width: '300px' }}
                      id="patientDOB"
                      name="patientDOB"
                      type="date"
                      outlined
                      value={this.state.patientDOB}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handleChange}
                    />
                  </form>
                </GridCell>
                <GridCell span={4}>
                  <FormControl style={{ width: '300px' }}>
                    <Select
                      placeholder="Patient Sex"
                      outlined
                      options={['M', 'F']}
                      value={this.state.patientSex}
                      onChange={(event) => {
                        this.setState({
                          patientSex: event.currentTarget.value,
                        });
                      }}
                    ></Select>
                  </FormControl>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Address:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '610px' }}
                    outlined
                    placeholder="Address"
                    name="patientAddress"
                    value={this.state.patientAddress}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>City / State / Zip:</Typography>
                </GridCell>
                <GridCell span={3}>
                  <TextField
                    style={{ width: '200px' }}
                    outlined
                    placeholder="City"
                    name="patientCity"
                    value={this.state.patientCity}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
                <GridCell
                  span={3}
                  style={{ left: '-10%', position: 'relative', width: '185px' }}
                >
                  <Select
                    outlined
                    native
                    displayEmpty
                    value={this.state.patientState}
                    onChange={(event) =>
                      this.setState({
                        patientState: event.currentTarget.value,
                      })
                    }
                    placeholder="State"
                    inputProps={{
                      name: 'patientState',
                    }}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </GridCell>
                <GridCell span={3}>
                  <TextField
                    style={{
                      left: '-27%',
                      position: 'relative',
                      width: '200px',
                    }}
                    outlined
                    placeholder="Zipcode"
                    name="patientZipcode"
                    value={this.state.patientZipcode}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Email Address:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '610px' }}
                    placeholder="Email Address"
                    outlined
                    name="patientEmail"
                    value={this.state.patientEmail}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Facility / Physician:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <FormControl style={{ width: '375px' }}>
                    <Select
                      required
                      id="facilitySelect"
                      placeholder="Patient Facility"
                      outlined
                      native
                      displayEmpty
                      onChange={(event) =>
                        this.setState({
                          patientFacilityID: event.currentTarget.value,
                        })
                      }
                      labelId="Facility"
                      value={this.state.patientFacilityID}
                      inputProps={{
                        name: 'patientFacilityID',
                      }}
                    >
                      {facilityList.map((state) => (
                        <option key={state.facilityID} value={state.facilityID}>
                          {state.facilityName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridCell>
                <GridCell span={4}>
                  <FormControl
                    style={{
                      width: '220px',
                      position: 'relative',
                      left: '30%',
                    }}
                  >
                    <Select
                      required
                      id="physicianSelect"
                      placeholder="Patient Physician"
                      outlined
                      native
                      displayEmpty
                      onChange={(event) =>
                        this.setState({ doctorNPI: event.currentTarget.value })
                      }
                      labelId="Physician"
                      value={this.state.doctorNPI}
                      inputProps={{
                        name: 'doctorNPI',
                      }}
                    >
                      {this.state.physicians.map((state) => (
                        <option key={state.doctorNPI} value={state.doctorNPI}>
                          {state.doctorFirstName + ' ' + state.doctorLastName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Room Number:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '610px', top: '10px' }}
                    placeholder="Room Number"
                    outlined
                    name="patientRoomNum"
                    value={
                      this.state.patientRoomNum === 0
                        ? ''
                        : this.state.patientRoomNum
                    }
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <hr style={{ color: 'black', borderTop: 'solid' }}></hr>
              <GridRow>
                <GridCell span={12}>
                  <Typography
                    use={'headline6'}
                    style={{ left: '40%', position: 'relative' }}
                  >
                    Insurance Information
                  </Typography>
                </GridCell>
              </GridRow>
              <hr style={{ color: 'transparent' }}></hr>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Payer Name:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '610px', top: '10px' }}
                    placeholder="Payer Name"
                    outlined
                    name="payerName"
                    value={this.state.payerName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow style={{ top: '10px', position: 'relative' }}>
                <GridCell span={3}>
                  <Typography>Group ID / Policy ID</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '300px' }}
                    placeholder="Group ID"
                    outlined
                    name="groupID"
                    value={this.state.groupID}
                    onChange={(event) => {
                      this.setState({
                        groupID: event.currentTarget.value,
                      });
                    }}
                  ></TextField>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '300px' }}
                    placeholder="Policy ID"
                    outlined
                    name="policyID"
                    value={this.state.policyID}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <Button
              action="update"
              unelevated
              icon="save"
              onClick={() => {
                if (
                  this.isEmpty(this.state.patientFirstName) ||
                  this.isEmpty(this.state.patientLastName)
                ) {
                  Swal.fire({
                    icon: 'error',
                    text: 'Please fill out all search fields',
                  });
                } else {
                  this.updatePatient(this.state.currentPatient.patientID);
                  this.setState({
                    editPatientDialogOpen: false,
                    editPatient: false,
                  });
                }
              }}
            >
              Update
            </Button>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.props.closePatient();
                this.setState({
                  editPatientDialogOpen: false,
                  editPatient: false,
                  patientState: '',
                  patientAddress: '',
                  patientName: '',
                  patientCity: '',
                  patientZipcode: '',
                  patientSex: '',
                  patientEmail: '',
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let patientLabDialog = () => {
      return (
        <Dialog
          open={this.state.patientLabDialog}
          onClose={(evt) => {
            this.setState({ patientLabDialog: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogContent>{patientLabResults()}</DialogContent>
          <DialogActions>
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ patientLabDialog: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let patientTableInfo = () => {
      if (this.state.patients.length === 0) {
        return (
          <Typography id="noResult" use="headline5">
            No Results
          </Typography>
        );
      } else {
        let patientData = this.state.patients.map((patient, i) => {
          return (
            <DataTableRow key={i}>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                <Button
                  onClick={() => {
                    if (!this.containsID(patient.patientID)) {
                      Swal.fire({
                        title:
                          'Add "' +
                          patient.patientFirstName +
                          ' ' +
                          patient.patientLastName +
                          '" to your Watchlist?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Continue',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.setState({ editWatchList: true }, () =>
                            this.addWatchList(patient)
                          );
                          Swal.fire(
                            'Added!',
                            'This patient is now a part of your Watchlist.',
                            'success'
                          );
                        }
                      });
                    } else {
                      Swal.fire({
                        title:
                          'Remove "' +
                          patient.patientFirstName +
                          ' ' +
                          patient.patientLastName +
                          '" from your Watchlist?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Continue',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.setState({ editWatchList: true }, () =>
                            this.addWatchList(patient)
                          );
                          this.props.deleteNotification(patient.patientID);
                          Swal.fire(
                            'Removed!',
                            'This patient is no longer a part of your Watchlist.',
                            'success'
                          );
                        }
                      });
                    }
                  }}
                >
                  {this.containsID(patient.patientID) ? (
                    <Icon icon="star"></Icon>
                  ) : (
                    <Icon icon="star_outline"></Icon>
                  )}
                </Button>
              </DataTableCell>
              <DataTableCell
                style={{ backgroundColor: '#DBF1FF' }}
                onClick={() => {
                  this.props.facesheetRequest(patient.patientID, 25, 0, 'info');
                  this.props.openDialog('patientInfo');
                  this.setState({
                    patientInfoDialog: true,
                    currentPatient: patient,
                    tab: 'General',
                    tabIndex: 0,
                  });
                }}
              >
                {patient.patientLastName + ',  ' + patient.patientFirstName}
              </DataTableCell>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                {patient.facilityName}
              </DataTableCell>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                <Button
                  disabled={this.props.decodedJWT.role !== 'Tsalta Admin'}
                  icon="edit"
                  label="Modify"
                  onClick={() => {
                    this.props.facesheetRequest(patient.patientID, 25, 0, '');
                    this.setState({
                      editPatient: false,
                      currentPatient: patient,
                      tab: 'General',
                      tabIndex: 0,
                    });
                  }}
                ></Button>
              </DataTableCell>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                <Button
                  icon="search"
                  label="View"
                  onClick={() => {
                    this.props.queryPatientReport();
                    this.props.patientLabResults(
                      patient.patientID,
                      patient.facilityID
                    );
                    this.props.history.push('/app/result');
                  }}
                ></Button>
              </DataTableCell>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                <Button
                  icon="create"
                  label="Generate"
                  onClick={() => {
                    let patJson = {};
                    patJson = {
                      patientID: patient.patientID,
                      patientFirstName: patient.patientFirstName,
                      patientLastName: patient.patientLastName,
                      patientDOB: patient.patientDOB,
                    };
                    this.setState({
                      labReqDialog: true,
                      reportSelect: JSON.stringify(patJson),
                      patientName:
                        patient.patientFirstName +
                        ' ' +
                        patient.patientLastName,
                    });
                  }}
                ></Button>
              </DataTableCell>
              <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                <Button
                  disabled={this.props.decodedJWT.role !== 'Tsalta Admin'}
                  icon="delete"
                  onClick={() => {
                    Swal.fire({
                      title:
                        'Are you sure you want to delete "' +
                        patient.patientFirstName +
                        ' ' +
                        patient.patientLastName +
                        '" ?',
                      text: 'You will not be able to revert this!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.setState(
                          { editWatchList: true },
                          () => this.addWatchList(patient),
                          this.props.deletePatientRequest(patient.patientID),
                          this.props.deleteNotification(patient.patientID)
                        );
                        Swal.fire(
                          'Deleted!',
                          'Your file has been deleted.',
                          'success'
                        );
                      }
                    });
                  }}
                >
                  Delete
                </Button>
              </DataTableCell>
            </DataTableRow>
          );
        });
        return patientData;
      }
    };
    let labReqDialog = () => {
      return (
        <div id="labReqTest">
          <Dialog
            id="labReqDialog"
            open={this.state.labReqDialog}
            onClose={(evt) => {
              this.setState({ labReqDialog: false });
            }}
          >
            <DialogTitle
              id="reportTitle"
              style={{
                borderTop: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              {'Generate Lab Request for \'' + this.state.patientName + '\''}
            </DialogTitle>

            <DialogContent
              style={{
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              <GridRow id="labNamePatientRow">
                <GridCell span={3}>
                  <Typography>Select Lab Name:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <Select
                    placeholder="Lab Name"
                    outlined
                    options={this.state.labList.map((lab) => {
                      let json = {};
                      json.value = JSON.stringify(lab);
                      json.label = lab.labName;

                      return json;
                    })}
                    value={this.state.selectedLab}
                    name="test"
                    onChange={(event) => {
                      let value = JSON.parse(event.currentTarget.value);
                      this.props.labTestsList(value.labID);
                      this.setState({ test: '' }, () => {
                        this.setState({
                          labName: value.labName,
                          selectedLab: event.currentTarget.value,
                        });
                      });
                    }}
                  ></Select>
                </GridCell>
              </GridRow>
              <GridRow id="typePatientRow">
                <GridCell span={3}>
                  <Typography>Select Request Type:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <Select
                    value={this.state.test}
                    label="Test Type"
                    outlined
                    options={testTypeList}
                    name="test"
                    onChange={(event) => {
                      let value = event.currentTarget.value;
                      this.setState({}, () => {
                        this.clearChecks();
                        this.setState({
                          test: value,
                          checked: value,
                          orderList: [],
                          medsList: [],
                        });
                      });
                    }}
                  ></Select>
                </GridCell>
              </GridRow>
              {selectTestList()}

              <GridRow id="reportButtonRow">
                <GridCell>
                  <Button
                    id="patReportButton"
                    variant="contained"
                    disabled={this.props.reportGenerating}
                    onClick={() => {
                      this.createChecked('patient');
                    }}
                  >
                    Generate Request
                  </Button>
                </GridCell>
              </GridRow>
            </DialogContent>
            <DialogActions
              style={{
                borderBottom: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                color: 'dodgerblue',
              }}
            >
              <DialogButton
                icon="close"
                cancel
                outlined
                onClick={() => {
                  this.setState({
                    editPatientDialogOpen: false,
                    labReqDialog: false,
                    testType: '',
                    test: '',
                    reportType: '',
                  });
                }}
              >
                Close
              </DialogButton>
            </DialogActions>
          </Dialog>
        </div>
      );
    };

    return (
      <div id="patientComponent">
        <Grid>
          <GridRow>
            <GridCell span={5}></GridCell>

            <GridCell span={3}>
              <div id="patientSearchCol">
                <Select
                  outlined
                  label="Field"
                  options={['Patient Last Name', 'Facility']}
                  value={this.state.searchCol}
                  onChange={(event) => {
                    this.setState({
                      searchCol: event.currentTarget.value,
                      query: '',
                    });
                  }}
                />
              </div>
              {this.state.filteredPatients.length !== 0 ? (
                <Button id="clearResultsBut" onClick={() => this.clearSearch()}>
                  {' '}
                  clear results
                </Button>
              ) : null}
            </GridCell>
            <GridCell span={3}>
              {this.state.searchCol === 'Facility' ? (
                <div>{searchField()}</div>
              ) : (
                <form onSubmit={this.handleSubmit}>
                  <TextField
                    style={{ width: '100%' }}
                    outlined
                    trailingIcon={{
                      icon: 'search',
                      tabIndex: 0,
                      onClick: () => {
                        this.setState({ searching: true, offset: 0 }, () => {
                          this.props.searchResults(
                            this.state.searchCol,
                            this.state.query,
                            this.state.patientsPerPage,
                            this.state.offset,
                            this.state.sortCol,
                            this.state.sortDir
                          );
                        });
                      },
                    }}
                    name="query"
                    value={this.state.query}
                    label="Search Term"
                    onChange={this.handleChange}
                    onKeyDown={this.keyPress}
                  />
                </form>
              )}
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={9}>
              <div id="patientHead">
                <Typography use="headline4"> Patients</Typography>
              </div>
            </GridCell>
            <GridCell span={3}>
              <Button
                style={{ left: '79%', width: '15%' }}
                id="clearSearch"
                icon="clear"
                label="Clear Search"
                onClick={() => this.clearSearch()}
              ></Button>
            </GridCell>
          </GridRow>
        </Grid>
        <div>
          <div>
            <Button
              id="addButton"
              disabled={this.props.decodedJWT.role !== 'Tsalta Admin'}
              variant="contained"
              onClick={() => {
                this.setState({
                  addPatientDialogOpen: true,
                  patientFirstName: '',
                  patientLastName: '',
                  patientAddress: '',
                  patientCity: '',
                  patientState: '',
                  patientZipcode: '',
                  patientEmail: '',
                  patientFacilityID: '',
                  patientDOB: '',
                  doctorNPI: '',
                  patientSex: '',
                  patientRoomNum: '',
                });
              }}
            >
              Add Patient
            </Button>
          </div>
        </div>
        <PatientInfoComponent
          currentPatient={this.state.currentPatient}
          dmv={this.state.dmv}
          patientInfoDialog={this.state.patientInfoDialog}
        ></PatientInfoComponent>
        {patientLabDialog()}
        {labReqDialog()}
        {!this.props.patientLoading ? (
          editPatientDialog()
        ) : (
          <LinearProgress></LinearProgress>
        )}
        {addPatientDialog()}

        <div>
          <DataTable
            id="patientTable"
            stickyRows={1}
            stickyColumns={1}
            style={{ backgroundColor: 'white' }}
          >
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>
                  <DataTableHeadCell
                    style={{
                      borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                      width: '10px',
                    }}
                  >
                    Watchlist
                  </DataTableHeadCell>
                  <DataTableHeadCell
                    value={this.state.patientLastSort}
                    sort={this.state.patientLastSort}
                    onSortChange={(sortDir) => {
                      this.setState(
                        {
                          patientLastSort: sortDir,
                          patientFirstSort: null,
                          facilitySort: null,
                        },
                        () => {
                          this.sortData(sortDir, 'lastName');
                        }
                      );
                    }}
                    style={{
                      borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                    }}
                  >
                    Patient Name
                  </DataTableHeadCell>
                  <DataTableHeadCell
                    sort={this.state.facilitySort}
                    value={this.state.facilitySort}
                    onSortChange={(sortDir) => {
                      this.setState({
                        facilitySort: sortDir,
                        patientFirstSort: null,
                        patientLastSort: null,
                      });
                      this.sortData(sortDir, 'facility');
                    }}
                    style={{
                      borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                    }}
                  >
                    Facility
                  </DataTableHeadCell>
                  <DataTableHeadCell
                    style={{
                      borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                    }}
                  >
                    Actions
                  </DataTableHeadCell>
                  <DataTableHeadCell
                    style={{
                      borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                    }}
                  >
                    Lab Results
                  </DataTableHeadCell>
                  <DataTableHeadCell
                    style={{
                      borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                    }}
                  >
                    Lab Request
                  </DataTableHeadCell>
                  <DataTableHeadCell
                    style={{
                      borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                    }}
                  >
                    Delete
                  </DataTableHeadCell>
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>{patientTableInfo()}</DataTableBody>
            </DataTableContent>
          </DataTable>
          <TablePagination
            id="patientPag"
            component="div"
            count={this.state.count}
            page={this.state.patientsPage}
            onChangePage={this.handleChangePage}
            rowsPerPage={this.state.patientsPerPage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100, 2500]}
          ></TablePagination>
        </div>

        {this.state.noResults === true ? (
          <Typography id="noResults" style={{ color: 'red' }}>
            No Results
          </Typography>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  decodedJWT: state.login.decodedJWT,
  patients: state.patient.patients,
  patientTotal: state.patient.patientTotal,
  results: state.patient.labResults,
  reports: state.patient.reports,
  facilities: state.patient.facilities,
  diagnosis: state.patient.diagnosis,
  vitals: state.patient.vitals,
  medications: state.patient.medications,
  facesheet: state.patient.facesheet,
  dmv: state.patient.dmv,
  labs: state.labs.labs,
  labTests: state.report.labTests,
  patientLoading: state.patient.patientLoading,
  patientLoadingSuccess: state.patient.patientLoadingSuccess,
  physicians: state.physician.physicians,
  watchList: state.admin.watchList,
});
const mapDispatchToProps = (dispatch) => {
  return {
    queryPatientReport: () => dispatch(queryPatientReport()),
    queryPatient: (limit, offset, sort, dir) =>
      dispatch(patientResultRequest(limit, offset, sort, dir)),
    // queryLab: (count, reportid, offset) =>
    //   dispatch(labRequest(count, reportid, offset)),
    searchResults: (col, term, limit, offset, sort, dir) =>
      dispatch(searchRequest(col, term, limit, offset, sort, dir)),
    patientLabResults: (patientID, facilityID) =>
      dispatch(patientLabResults(patientID, facilityID)),
    getFacilities: () => dispatch(getFacilityRequest()),
    facesheetRequest: (term, count, offset, type) =>
      dispatch(facesheetRequest(term, count, offset, type)),
    dmvRequest: (term, count, offset) =>
      dispatch(dmvRequest(term, count, offset)),
    updateDMVRequest: (json) => dispatch(updateDMVRequest(json)),
    labTestsList: (labName) => dispatch(labTestsList(labName)),
    generateLabReq: (patientName, facility, testType, labName, sampleType) =>
      dispatch(
        generateLabReq(patientName, facility, testType, labName, sampleType)
      ),
    getLabsRequest: () => dispatch(getLabsRequest()),
    deletePatientRequest: (patientID) =>
      dispatch(deletePatientRequest(patientID)),
    addPatientRequest: (json) => dispatch(addPatientRequest(json)),
    updatePatientRequest: (json) => dispatch(updatePatientRequest(json)),
    getphysicians: () => dispatch(getPhysiciansRequest()),
    closePatient: () => dispatch(closePatient()),
    openDialog: (dialog) => dispatch(openDialog(dialog)),
    getWatchList: () => dispatch(getWatchList()),
    updateUserWatchListRequest: (json) =>
      dispatch(updateUserWatchListRequest(json)),
    deleteNotification: (patientID) => dispatch(deleteNotification(patientID)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientComponent);
