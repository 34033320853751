import React from 'react';
import { connect } from 'react-redux';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { login, getDatabasesRequest } from '../actions/loginActions';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import { Typography } from '@rmwc/typography';
import tsalta_logo from '../images/TSalta_Solutions_Transparent_SuperFrench.png';
import '../css/LoginComponent.css';

import { FormControl, InputLabel } from '@material-ui/core';
import { Select } from '@rmwc/select';

class LoginComponent extends React.Component {
  /*
   *Contains components for the login page.
   */

  constructor() {
    super();

    this.state = {
      username: '',
      password: '',
      dbList: [],
      db: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { username, password, db } = this.state;
    if (username && password && db) {
      this.props.login(username, password, db);
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.cookies.get('jwt')) {
      this.props.history.push('/app/dashboard');
    }
  }
  componentDidMount() {
    this.props.getDBS();
  }
  componentDidUpdate() {
    if (this.props.jwt) {
      this.props.history.push('/app/dashboard');
    }
    if (this.state.dbList.length !== this.props.dbList.length) {
      this.setState({ dbList: this.props.dbList });
    }
  }

  render() {
    return (
      <div id="loginDivOuter">
        <div id="loginDivInner">
          <img
            src={tsalta_logo}
            alt="Tsalta Logo"
            id="tsaltaLogo"
            style={{ backgroundColor: 'clear' }}
          />
          <form onSubmit={this.handleSubmit}>
            <div>
              <Typography use="caption">
                <span hidden={this.props.loginSuccess} id="invalidCred">
                  Invalid username and/or password.
                </span>
              </Typography>
            </div>
            <TextField
              style={{ position: 'absolute', top: '25%' }}
              inputStyle={{
                textAlign: 'right',
              }}
              label="Username"
              outlined
              required
              trailingIcon="account_box"
              name="username"
              type="text"
              onChange={this.handleChange}
            />
            <TextField
              outlined
              style={{ position: 'absolute', top: '40%' }}
              label="Password"
              required
              trailingIcon="lock"
              name="password"
              type="password"
              onChange={this.handleChange}
            />

            <TextField
              outlined
              style={{ position: 'absolute', top: '55%' }}
              label="Database"
              required
              name="db"
              onChange={this.handleChange}
            />
            <div
              style={{
                paddingLeft: '5%',
                position: 'absolute',
                top: '55%',
                width: '100%',
              }}
            ></div>
            <Button
              elevated
              style={{
                backgroundColor: 'navy',
                color: 'white',
                position: 'absolute',
                top: '65%',
              }}
            >
              Login
            </Button>
          </form>
        </div>
        <div></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password, db) => dispatch(login(username, password, db)),
    getDBS: () => dispatch(getDatabasesRequest()),
  };
};

const mapStateToProps = (state) => {
  return {
    jwt: state.login.jwt,
    loginSuccess: state.login.success,
    dbList: state.login.dbList,
  };
};

LoginComponent.propTypes = {
  login: PropTypes.func.isRequired,
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
);
