export const FACILITY_REPORT = 'FACILITY_REPORT';
export const FACILITY_REPORT_SUCCESS = 'FACILITY_REPORT_SUCCESS';
export const FACILITY_REPORT_FAILURE = 'FACILITY_REPORT_FAILURE';
export const PATIENT_REPORT = 'PATIENT_REPORT';
export const PATIENT_REPORT_SUCCESS = 'PATIENT_REPORT_SUCCESS';
export const PATIENT_REPORT_FAILURE = 'PATIENT_REPORT_FAILURE';
export const LOAD_REPORTS_SUCCESS = 'LOAD_REPORTS_SUCCESS';
export const LOAD_REPORTS_FAILURE = 'LOAD_REPORTS_FAILURE';
export const UPLOAD_LAB = 'UPLOAD_LAB';
export const UPLOAD_LAB_SUCCESS = 'UPLOAD_LAB_SUCCESS';
export const UPLOAD_LAB_FAIL = 'UPLOAD_LAB_FAIL';
export const QUARTERLY_REPORT = 'QUARTERLY_REPORT';
export const QUARTERLY_REPORT_SUCCESS = 'QUARTERLY_REPORT_SUCCESS';
export const QUARTERLY_REPORT_FAILURE = 'QUARTERLY_REPORT_FAILURE';
export const FILE_DIALOG_CONTROL = 'FILE_DIALOG_CONTROL';
export const LAB_REQUEST_SUCCESS = 'LAB_REQUEST_SUCCESS';
export const LAB_REQUEST_FAILURE = 'LAB_REQUEST_FAILURE';
export const LAB_REQUEST = 'LAB_REQUEST';
export const PATIENT_REQUEST_SUCCESS = 'PATIENT_REQUEST_SUCCESS';
export const PATIENT_REQUEST_FAILURE = 'PATIENT_REQUEST_FAILURE';
export const PATIENT_REQUEST = 'PATIENT_REQUEST';
export const FACESHEET_REQUEST_FAIL = 'FACESHEET_REQUEST_FAIL';
export const FACESHEET_REQUEST_SUCCESS = 'FACESHEET_REQUEST_SUCCESS';
export const FACESHEET_REQUEST = 'FACESHEET_REQUEST';
export const UPDATE_FACESHEET_REQUEST_SUCCESS =
  'UPDATE_FACESHEET_REQUEST_SUCCESS';
export const UPDATE_FACESHEET_REQUEST_FAIL = 'UPDATE_FACESHEET_REQUEST_FAIL';
export const PATIENT_NAME_QUERY_SUCCESS = 'PATIENT_NAME_QUERY_SUCCESS';
export const PATIENT_NAME_QUERY_FAIL = 'PATIENT_NAME_QUERY_FAIL';
export const LAB_TESTS_QUERY_SUCCESS = 'LAB_TESTS_QUERY_SUCCESS';
export const LAB_TESTS_QUERY_FAIL = 'LAB_TESTS_QUERY_FAIL';
