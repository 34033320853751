import {
  QUERY_LABS_SUCCESS,
  QUERY_LABS_FAIL,
  ADD_LABS_FAIL,
  ADD_LABS_SUCCESS,
  DELETE_LABS_FAIL,
  DELETE_LABS_SUCCESS,
  UPDATE_LAB_FAIL,
} from '../constants/labManagementConstants';

export const queryLabsSuccess = (labs) => ({
  type: QUERY_LABS_SUCCESS,
  labs: labs,
});
export const queryLabsFail = () => ({
  type: QUERY_LABS_FAIL,
});
export const addLabSuccess = () => ({
  type: ADD_LABS_SUCCESS,
});

export const addLabFail = () => ({
  type: ADD_LABS_FAIL,
});
export const deleteLabSuccess = () => ({
  type: DELETE_LABS_SUCCESS,
});

export const deleteLabfail = () => ({
  type: DELETE_LABS_FAIL,
});
export const updateLabFail = () => ({
  type: UPDATE_LAB_FAIL,
});

export const getLabsRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/readLabs');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryLabsSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryLabsFail(error));
      });
  };
};
export const addLabRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/addLab');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getLabsRequest());
      })
      .catch((error) => {
        dispatch(addLabFail(error));
      });
  };
};
export const deleteLabRequest = (labID) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/deleteLab');
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ labID: labID }),
    })
      .then(() => {
        dispatch(deleteLabSuccess());
      })
      .then(() => {
        dispatch(getLabsRequest());
      })
      .catch((error) => {
        dispatch(deleteLabfail(error));
      });
  };
};
export const updateLabRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/updateLab');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getLabsRequest());
      })
      .catch((error) => {
        dispatch(updateLabFail(error));
      });
  };
};
