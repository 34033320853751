import { CLOSE_DIALOG, OPEN_DIALOG } from '../constants/dialogConstants';

const initialState = {
  dialog: null,
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        dialog: action.dialog,
      };
    case CLOSE_DIALOG:
      return initialState;

    default:
      return state;
  }
};

export default dialogReducer;
