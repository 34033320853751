export const ADMIN_GET_USERS = 'ADMIN_QUERY_WEB';
export const ADMIN_QUERY_WEBUSERS_SUCCESS = 'ADMIN_QUERY_WEBUSERS_SUCCESS';
export const ADMIN_QUERY_WEBUSERS_FAIL = 'ADMIN_QUERY_WEBUSERS_FAIL';
export const ADMIN_FACILITYLIST_SUCCESS = 'ADMIN_FACILITYLIST_SUCCESS';
export const ADMIN_FACILITYLIST_FAIL = 'ADMIN_FACILITYLIST_FAIL';
export const ADMIN_AVAILFACILITYLIST_SUCCESS =
  'ADMIN_AVAILFACILITYLIST_SUCCESS';
export const ADMIN_AVAILFACILITYLIST_FAIL = 'ADMIN_AVAILFACILITYLIST_FAIL';
export const QUERY_CURRENT_WEBUSER_SUCCESS = 'QUERY_CURRENT_WEBUSER_SUCCESS';
export const ADMIN_COUNT_WEB = 'ADMIN_COUNT_WEB';
export const ADMIN_ADD_USER = 'ADMIN_ADD_USER';
export const ADMIN_ADD_USER_SUCCESS = 'ADMIN_ADD_USER_SUCCESS';
export const ADMIN_ADD_USER_FAIL = 'ADMIN_ADD_USER_FAIL';
export const ADMIN_EDIT_USER = 'ADMIN_EDIT_USER';
export const ADMIN_EDIT_USER_SUCCESS = 'ADMIN_EDIT_USER_SUCCESS';
export const ADMIN_EDIT_USER_FAIL = 'ADMIN_EDIT_USER_FAIL';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';
export const ADMIN_DELETE_USER_SUCCESS = 'ADMIN_DELETE_USER_SUCCESS';
export const ADMIN_DELETE_USER_FAIL = 'ADMIN_DELETE_USER_FAIL';
export const ADMIN_DELETE_FACILITY = 'ADMIN_DELETE_FACILITY';
export const ADMIN_DELETE_FACILITY_SUCCESS = 'ADMIN_DELETE_FACILITY_SUCCESS';
export const ADMIN_DELETE_FACILITY_FAIL = 'ADMIN_DELETE_FACILITY_FAIL';
export const ADMIN_TSALTA_ADMIN = 'Tsalta Admin';
export const ADMIN_TSALTA_USER = 'Tsalta User';
export const ADMIN_TSALTA_FACADMIN = 'Facility Mgr';
export const ADMIN_TSALTA_FACUSER = 'Facility User';
export const ADMIN_TSALTA_LAB = 'Lab';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_LOADING_SUCCESS = 'PROFILE_LOADING_SUCCESS';
export const PROFILE_LOADING_FAIL = 'PROFILE_LOADING_FAIL';
export const PROFILE_UPDATE_PASSWORD_FAIL = 'PROFILE_UPDATE_PASSWORD_FAIL';
export const QUERY_WATCHLIST_SUCCESS = 'QUERY_WATCHLIST_SUCCESS';
export const QUERY_NOTIFICATIONS_SUCCESS = 'QUERY_NOTIFICATIONS_SUCCESS';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const QUERY_QUICKREPORTS_SUCCESS = 'QUERY_QUICKREPORTS_SUCCESS';
export const DELETE_QUICKREPORTS_SUCCESS = 'DELETE_QUICKREPORTS_SUCCESS';
