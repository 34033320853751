import {
  FILE_HISTORY_REQUEST,
  FILE_HISTORY_REQUEST_SUCCESS,
  FILE_HISTORY_REQUEST_FAIL,
  FILE_REQUEST_SUCCESS,
} from '../constants/fileHistoryConstants';
import { downloadFile, openNewTabFile } from '../utils/Utils';

export const FileHistoryRequest = () => ({
  type: FILE_HISTORY_REQUEST,
});
export const fileHistoryRequestSuccess = (results) => ({
  type: FILE_HISTORY_REQUEST_SUCCESS,
  history: results,
});
export const fileHistoryRequestFail = () => ({
  type: FILE_HISTORY_REQUEST_FAIL,
});
export const fileRequestSuccess = (results) => ({
  type: FILE_REQUEST_SUCCESS,
  file: results,
});
export const getFileHistoryRequest = () => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/getFileHistory'
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(fileHistoryRequestSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(fileHistoryRequestFail(error));
      });
  };
};
export const getFileRequest = (condition) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/getFile?where=' + condition
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        downloadFile(searchResult.filename, searchResult.fileString, 'HL7');

        dispatch(fileRequestSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(fileHistoryRequestFail(error));
      });
  };
};
