export const QUERY_PATIENTS = 'QUERY_PATIENTS';
export const QUERY_PATIENTS_SUCCESS = 'QUERY_PATIENTS_SUCCESS';
export const QUERY_PATIENTS_FAIL = 'QUERY_PATIENTS_FAIL';
export const COUNT_PATIENTS = 'COUNT_PATIENTS';
export const COUNT_PATIENTS_FAIL = 'COUNT_PATIENTS_FAIL';
export const COUNT_PATIENTS_SUCCESS = 'COUNT_PATIENTS_SUCCESS';
export const Query_LAB = 'QUERY_LAB';
export const QUERY_LAB_SUCCESS = 'QUERY_LAB_SUCCESS';
export const QUERY_LAB_FAIL = 'QUERY_LAB_FAIL';
export const PATIENT_SEARCH_SUCCESS = 'PATIENT_SEARCH_SUCCESS';
export const PATIENT_SEARCH_FAIL = 'PATIENT_SEARCH_FAIL';
export const PATIENT_REPORT_SUCCESS = 'PATIENT_REPORT_SUCCESS';
export const PATIENT_REPORT_QUERY = 'PATIENT_REPORT_QUERY';
export const PATIENT_REPORT_FAIL = 'PATIENT_REPORT_FAIL';
export const DIAGNOSIS_REQUEST_SUCCESS = 'DIAGNOSIS_REQUEST_SUCCESS';
export const DIAGNOSIS_REQUEST_FAIL = 'DIAGNOSIS_REQEST_FAIL';
export const VITALS_REQUEST_SUCCESS = 'VITALS_REQUEST_SUCCESS';
export const VITALS_REQUEST_FAIL = 'VITALS_REQUEST_FAIL';
export const MEDICATIONS_REQUEST_SUCCESS = 'MEDICATIONS_REQUEST_SUCCESS';
export const MEDICATIONS_REQUEST_FAIL = 'MEDICATIONS_REQUEST_FAIL';
export const FACESHEET_REQUEST_SUCCESS = 'FACESHEET_REQUEST_SUCCESS';
export const FACESHEET_REQUEST_FAIL = 'FACESHEET_REQUEST_FAIL';
export const DMV_REQUEST_SUCCESS = 'DMV_REQUEST_SUCCESS';
export const DMV_REQUEST_FAIL = 'DMV_REQUEST_FAIL';
export const UPDATE_DMV_SUCCESS = 'UPDATE_DMV_SUCCESS';
export const UPDATE_DMV_FAIL = 'UPDATE_DMV_FAIL';
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS';
export const DELETE_PATIENT_FAIL = 'DELETE_PATIENT_FAIL';
export const ADD_PATIENT_FAIL = 'ADD_PATIENT_FAIL';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_FAIL = 'UPDATE_PATIENT_FAIL';
export const PATIENT_LOADING = 'PATIENT_LOADING';
export const PATIENT_LOADING_SUCCESS = 'PATIENT_LOADING_SUCCESS';
export const PATIENT_LOADING_FAIL = 'PATIENT_LOADING_FAIL';
