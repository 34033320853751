export const downloadFile = (title, file, type) => {
  let a = document.createElement('a');
  a.href = URL.createObjectURL(
    new Blob(
      [
        Uint8Array.from(
          atob(file)
            .split('')
            .map((char) => char.charCodeAt(0))
        ),
      ],
      { type: 'octet/stream' }
    )
  );
  if (type === 'CSV') {
    a.download = title + '.csv';
  }
  if (type === 'TXT') {
    a.download = title + '.txt';
  }
  if (type === 'XLSX') {
    a.download = title + '.xlsx';
  }
  if (type === 'HL7') {
    a.download = title;
  }
  a.target = '_blank';
  a.click();
  URL.revokeObjectURL(a.href);
};
export const downloadJSONFile = (title, file) => {
  let a = document.createElement('a');
  a.href = URL.createObjectURL(new Blob([file], { type: 'octet/stream' }));
  a.download = title + '.pdf';
  a.target = '_blank';
  a.click();
  URL.revokeObjectURL(a.href);
};
export const openNewTabFile = (title, file) => {
  let a = document.createElement('a');
  a.href = URL.createObjectURL(blobPdfFromBase64String(file));
  a.target = '_blank';
  a.click();
  // URL.revokeObjectURL(a.href);
};
export const openNewTabBlob = (title, blob) => {
  let a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.target = '_blank';
  a.click();
  // URL.revokeObjectURL(a.href);
};

const blobPdfFromBase64String = (base64String) => {
  const byteArray = Uint8Array.from(Buffer.from(base64String, 'base64'));
  return new Blob([byteArray], { type: 'application/pdf' });
};
