import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import '../css/DashboardComponent.css';
import { logout } from '../actions/loginActions';
import lab_logo from '../images/Labs.png';
import facilities_logo from '../images/Facilities.png';
import patients_logo from '../images/Patients.png';
import physicians_logo from '../images/Physicians.png';
import tools_logo from '../images/Tools.png';
import users_logo from '../images/Users.png';
import { resultClicked } from '../actions/resultsActions';

class DashboardComponent extends Component {
  constructor() {
    super();
    this.state = {};

    this.handleLogout = this.handleLogout.bind(this);
    this.handleLab = this.handleLab.bind(this);
    this.handlePatient = this.handlePatient.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
    this.handleResult = this.handleResult.bind(this);
    this.handleFacilities = this.handleFacilities.bind(this);
    this.handleReport = this.handleReport.bind(this);
  }
  componentDidMount() { }

  componentWillUnmount() { }

  handleLogout() {
    document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; //remove jwt cookie
    this.props.logout(); //logout from redux state
    this.props.history.push('/login');
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      role: props.role,
    };
  }

  handleLab() {
    this.props.history.push('/app/lab');
  }

  handlePatient() {
    this.props.history.push('/app/patient');
  }
  handleUsers() {
    this.props.history.push('/app/Admin/users');
  }
  handleProfile() {
    this.props.history.push('/app/Profile');
  }
  handleResult() {
    this.props.history.push('/app/result');
    this.props.resultClicked();
  }
  handleFacilities() {
    this.props.history.push('/app/admin/facilities');
  }
  handleReport() {
    this.props.history.push('/app/report');
  }
  handlePhysicans() {
    this.props.history.push('/app/physicans');
  }
  handleHover = () => {
    if (this.state.hover === 'true') {
    }
    this.setState({ hover: true });
  };
  handleHoverExit = () => {
    this.setState({ hover: false });
  };

  render() {
    if (this.state.role !== 'Lab')
      return (
        <div id="buttons">
          <Grid container spacing={3} style={{ width: '100%', height: '300px' }}>
            <Grid item xs={4}>
              <div
                id="dashboardIconDiv"
                onClick={() => this.handleResult()}
                onMouseOver={(evt) => {
                  this.setState({ hoverPrint: true });
                }}
                onMouseOut={(evt) => {
                  this.setState({ hoverPrint: false });
                }}
              >
                {this.state.hoverPrint === true ? (
                  <div id="dashboardIconBoarder">
                    <img
                      src={lab_logo}
                      alt="lab Result Logo"
                      id="labResultLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Lab Results</Typography>
                  </div>
                ) : (
                  <div id="dashboardIconBoarder" style={{ width: '50%' }}>
                    <img
                      src={lab_logo}
                      alt="lab Result Logo"
                      id="labResultLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Lab Results</Typography>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                id="dashboardIconDiv"
                onClick={() => this.handlePatient()}
                onMouseOver={(evt) => {
                  this.setState({ hoverPatient: true });
                }}
                onMouseOut={(evt) => {
                  this.setState({ hoverPatient: false });
                }}
              >
                {this.state.hoverPatient === true ? (
                  <div id="dashboardIconBoarder">
                    <img
                      src={patients_logo}
                      alt="patient Logo"
                      id="patientLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Patients</Typography>
                  </div>
                ) : (
                  <div id="dashboardIconBoarder" style={{ width: '50%' }}>
                    <img
                      src={patients_logo}
                      alt="patient Logo"
                      id="patientLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Patients</Typography>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                id="dashboardIconDiv"
                onClick={() => this.handleFacilities()}
                onMouseOver={(evt) => {
                  this.setState({ hoverFac: true });
                }}
                onMouseOut={(evt) => {
                  this.setState({ hoverFac: false });
                }}
              >
                {this.state.hoverFac === true ? (
                  <div id="dashboardIconBoarder">
                    <img
                      src={facilities_logo}
                      alt="facility Logo"
                      id="facilityLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Facilities</Typography>
                  </div>
                ) : (
                  <div id="dashboardIconBoarder" style={{ width: '50%' }}>
                    <img
                      src={facilities_logo}
                      alt="facility Logo"
                      id="facilityLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Facilities</Typography>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ width: '100%', height: '200px' }}>
            <Grid item xs={4}>
              <div
                id="dashboardIconDiv"
                onClick={() => this.handlePhysicans()}
                onMouseOver={(evt) => {
                  this.setState({ hoverDoc: true });
                }}
                onMouseOut={(evt) => {
                  this.setState({ hoverDoc: false });
                }}
              >
                {this.state.hoverDoc === true ? (
                  <div id="dashboardIconBoarder">
                    <img
                      src={physicians_logo}
                      alt="physican Logo"
                      id="physicianLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Physicians</Typography>
                  </div>
                ) : (
                  <div id="dashboardIconBoarder" style={{ width: '50%' }}>
                    <img
                      src={physicians_logo}
                      alt="physican Logo"
                      id="physicianLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Physicians</Typography>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                id="dashboardIconDiv"
                onClick={() => this.handleReport()}
                onMouseOver={(evt) => {
                  this.setState({ hoverRep: true });
                }}
                onMouseOut={(evt) => {
                  this.setState({ hoverRep: false });
                }}
              >
                {this.state.hoverRep === true ? (
                  <div id="dashboardIconBoarder">
                    <img
                      src={tools_logo}
                      alt="tools Logo"
                      id="toolsLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Tools</Typography>
                  </div>
                ) : (
                  <div id="dashboardIconBoarder" style={{ width: '50%' }}>
                    <img
                      src={tools_logo}
                      alt="tools Logo"
                      id="toolsLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Tools</Typography>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                id="dashboardIconDiv"
                onClick={() => this.handleProfile()}
                onMouseOver={(evt) => {
                  this.setState({ hoverUser: true });
                }}
                onMouseOut={(evt) => {
                  this.setState({ hoverUser: false });
                }}
              >
                {this.state.hoverUser === true ? (
                  <div id="dashboardIconBoarder">
                    <img
                      src={users_logo}
                      alt="users Logo"
                      id="usersLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Profile</Typography>
                  </div>
                ) : (
                  <div id="dashboardIconBoarder" style={{ width: '50%' }}>
                    <img
                      src={users_logo}
                      alt="users Logo"
                      id="usersLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Profile</Typography>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      );
    else {
      return (
        <div id="buttons">
          <Grid container spacing={3} style={{ width: '100%', height: '300px' }}>
            <Grid item xs={4}>
              <div
                id="dashboardIconDiv"
                onClick={() => this.handleResult()}
                onMouseOver={(evt) => {
                  this.setState({ hoverPrint: true });
                }}
                onMouseOut={(evt) => {
                  this.setState({ hoverPrint: false });
                }}
              >
                {this.state.hoverPrint === true ? (
                  <div id="dashboardIconBoarder">
                    <img
                      src={lab_logo}
                      alt="lab Result Logo"
                      id="labResultLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Lab Results</Typography>
                  </div>
                ) : (
                  <div id="dashboardIconBoarder" style={{ width: '50%' }}>
                    <img
                      src={lab_logo}
                      alt="lab Result Logo"
                      id="labResultLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Lab Results</Typography>
                  </div>
                )}
              </div>
            </Grid>

  
            <Grid item xs={4}>
              <div
                id="dashboardIconDiv"
                onClick={() => this.handleReport()}
                onMouseOver={(evt) => {
                  this.setState({ hoverRep: true });
                }}
                onMouseOut={(evt) => {
                  this.setState({ hoverRep: false });
                }}
              >
                {this.state.hoverRep === true ? (
                  <div id="dashboardIconBoarder">
                    <img
                      src={tools_logo}
                      alt="tools Logo"
                      id="toolsLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Tools</Typography>
                  </div>
                ) : (
                  <div id="dashboardIconBoarder" style={{ width: '50%' }}>
                    <img
                      src={tools_logo}
                      alt="tools Logo"
                      id="toolsLogo"
                      style={{ backgroundColor: 'clear', width: '90%' }}
                    />
                    <Typography>Tools</Typography>
                  </div>
                )}
              </div>
            </Grid>
           
          </Grid>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    resultClicked: () => dispatch(resultClicked()),
  };
};
const mapStateToProps = (state) => {
  return {
    role: state.login.decodedJWT.role,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
