import React from 'react';
import '../css/DrawerComponent.css';
import DashboardComponent from './DashboardComponent';
import PatientComponent from './PatientComponent';
import UserComponent from './UserComponent';
import ProfileComponent from './ProfileComponent';
import ResultComponent from './ResultComponent';
import FacilityComponent from './FacilityComponent';
import ReportComponent from './ReportComponent';
import TopAppBarComponent from './TopAppBarComponent';
import RootDialogComponent from './RootDialogComponent';
import PhysiciansComponent from './PhysicianComponent';
import MetricsComponent from './MetricsComponent';
import { Route, Switch } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { assignJWT } from '../actions/loginActions';
import { connect } from 'react-redux';
import GroupComponent from './GroupComponent';
import TrendDashboardComponent from './TrendDashboardComponent';
import FacilityLoginComponent from './FacilityLoginComponent';
import LabManagementComponent from './LabManagementComponent';
import LabReqComponent from './LabReqComponent';
import LabHistoryComponent from './LabHistoryComponent';
import FileHistoryComponent from './FileHistoryComponent';
import PatientAnalyticsComponent from './PatientAnalyticsComponent';
import LabOrderManagerComponent from './LabOrderManagerComponent';
import WatchListComponent from './WatchListComponent';
import QuickReportsComponent from './QuickReportsComponent';

class AppComponent extends React.Component {
  UNSAFE_componentWillMount() {
    let jwt = this.props.cookies.get('jwt');
    if (jwt) {
      this.props.assignJWT(jwt);
    } else {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div id="app">
        <TopAppBarComponent />
        <RootDialogComponent />
        <div id="content" className={'content'}>
          <Switch>
            <Route path="/app/dashboard" component={DashboardComponent} />
            <Route path="/app/patient" component={PatientComponent} />
            <Route path="/app/admin/users" component={UserComponent} />
            <Route path="/app/admin/facilities" component={FacilityComponent} />
            <Route path="/app/profile" component={ProfileComponent} />
            <Route path="/app/result" component={ResultComponent} />
            <Route path="/app/report" component={ReportComponent} />
            <Route path="/app/metrics" component={MetricsComponent} />
            <Route
              path="/app/patientAnalytics"
              component={PatientAnalyticsComponent}
            />
            <Route path="/app/trends" component={TrendDashboardComponent} />
            <Route path="/app/physicans" component={PhysiciansComponent} />
            <Route path="/app/admin/groups" component={GroupComponent} />
            <Route path="/app/admin/labs/" component={LabManagementComponent} />
            <Route path="/app/labReq" component={LabReqComponent} />
            <Route path="/app/labHistory" component={LabHistoryComponent} />
            <Route
              path="/app/admin/fileHistory"
              component={FileHistoryComponent}
            />
            <Route
              path="/app/admin/facilityLogins"
              component={FacilityLoginComponent}
            />
            <Route
              path="/app/admin/codeMap"
              component={LabOrderManagerComponent}
            />
            <Route path="/app/quickReports" component={QuickReportsComponent} />
            <Route path="/app/watchList" component={WatchListComponent} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  assignJWT: (jwt) => dispatch(assignJWT(jwt)),
});

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(AppComponent)
);
