import {
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  GROUP_QUERY_SUCCESS,
  GROUP_QUERY_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  UPDATE_GROUP_FAIL,
} from '../constants/groupConstants';
import { ADMIN_FACILITYLIST_SUCCESS } from '../constants/userConstants';

export const createGroupSuccess = (groups) => ({
  type: CREATE_GROUP_SUCCESS,
  groups: groups,
});
export const createGroupFail = () => ({
  type: CREATE_GROUP_FAILURE,
});
export const querySuccess = (groups) => ({
  type: GROUP_QUERY_SUCCESS,
  groups: groups,
});

export const queryFail = () => ({
  type: GROUP_QUERY_FAIL,
});
export const queryFacilityListSuccess = (facilityList) => ({
  type: ADMIN_FACILITYLIST_SUCCESS,
  facilityList: facilityList,
});
export const deleteGroupSuccess = () => ({
  type: DELETE_GROUP_SUCCESS,
});

export const deleteGroupfail = () => ({
  type: DELETE_GROUP_FAIL,
});
export const updateGroupFail = () => ({
  type: UPDATE_GROUP_FAIL,
});

export const addGroupRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/addGroup');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getGroupsRequest());
      })
      .catch((error) => {
        dispatch(createGroupFail(error));
      });
  };
};

export const getGroupsRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/readGroups');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(querySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const deleteGroupRequest = (groupName, groupID) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/deleteGroup');
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ groupName: groupName, groupID: groupID }),
    })
      .then(() => {
        dispatch(deleteGroupSuccess());
      })
      .then(() => {
        dispatch(getGroupsRequest());
      })
      .catch((error) => {
        dispatch(deleteGroupfail(error));
      });
  };
};

export const updateGroupRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/updateGroup');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getGroupsRequest());
      })
      .catch((error) => {
        dispatch(updateGroupFail(error));
      });
  };
};
