import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/metricsComponent.css';
import { Typography } from '@rmwc/typography';
import { getFacilityRequest } from '../actions/userActions';
import { getLabsRequest } from '../actions/labManagementActions';
import { patientNameList, labTestsList } from '../actions/reportActions';
import '../css/LabReqComponent.css';
import { GridCell, GridRow } from '@rmwc/grid';
import { Select } from '@rmwc/select';
import { LinearProgress } from '@rmwc/linear-progress';
import { Button } from '@material-ui/core';
import Swal from 'sweetalert2';
import { Checkbox } from '@rmwc/checkbox';
import { Paper, ListItemIcon, ListItemText } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { generateLabReq } from '../actions/reportActions';
import { type } from 'jquery';

class LabReqComponent extends Component {
  constructor() {
    super();
    this.state = {
      labs: [],
      facilities: [],
      facilityList: [],
      patients: [],
      labTests: [],
      ama: [],
      inHouse: [],
      womenHealth: [],
      common: [],
      microbiology: [],
      orderList: [],
      testType: [],
      medsList: [],
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      panelList: null,
    };
    this.clearChecks = this.clearChecks.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let facilityList = [];
    props.facilities.forEach((facility) => {
      facilityList.push(facility);
    });
    let patientList = [];
    props.patients.forEach((patient) => {
      patientList.push(patient);
    });
    let labList = [];
    props.labs.forEach((lab) => {
      labList.push(lab);
    });
    let testOptions = [];
    if (props.labTests.size !== 0) {
      let keys = props.labTests.keys();
      for (let i = 0; i < props.labTests.size; i++) {
        testOptions.push(keys.next().value);
      }
    }
    return {
      ...state,
      facilities: props.facilities,
      patients: props.patients,
      facilityList: facilityList,
      labList: labList,
      patientList: patientList,
      testOptions: testOptions,
    };
  }

  createChecked(formType) {
    let temp = [];
    temp = [];
    this.setState({ testType: this.state.orderList }, () => {
      this.submitForm(formType);
    });
  }
  clearChecks = () => {
    Object.keys(this.state).map((key) => {
      let regex = new RegExp('checkd*');
      this.setState({ orderList: [] });
      if (regex.test(key)) {
        this.setState({ [key]: false });
      }
      return null;
    });
  };
  checkOne(id, question) {
    for (var i = 1; i <= 4; i++) {
      document.getElementById(question + i).checked = false;
    }
    document.getElementById(id).checked = true;
  }

  submitForm(formType) {
    if (formType === 'patient') {
      if (this.state.checked === 'COVID-19') {
        this.props.generateLabReq(
          this.state.reportSelect,
          {},
          this.state.testType,
          this.state.selectedLab,
          this.state.test
        );
      } else {
        this.props.generateLabReq(
          this.state.reportSelect,
          {},
          this.state.testType,
          this.state.selectedLab,
          this.state.test
        );
      }
    }

    if (formType === 'facility') {
      this.props.generateLabReq(
        {},
        this.state.reportSelect,
        this.state.testType,
        this.state.selectedLab,
        this.state.test
      );
    }
  }

  componentDidMount() {
    this.props.getLabsRequest();
    this.props.getFacilities();
    this.props.patientNameList();
  }

  componentDidUpdate() {}
  orderList(evt, checkname) {
    if (!this.state.orderList.includes(evt.currentTarget.value)) {
      var newState = this.state.orderList.slice();
      newState.push(evt.currentTarget.value);
      this.setState({
        orderList: newState,
        [checkname]: true,
      });
    } else {
      newState = this.state.orderList.slice();
      for (var i = 0; i < newState.length; i++) {
        if (newState[i] === evt.currentTarget.value) {
          newState.splice(i, 1);
          i--;
        }
      }
      this.setState({
        orderList: newState,
        [checkname]: false,
      });
    }
  }
  medsList(evt) {
    if (!this.state.medsList.includes(evt.currentTarget.name)) {
      var newState = this.state.medsList.slice();
      newState.push(evt.currentTarget.name);
      this.setState({
        medsList: newState,
      });
    } else {
      newState = this.state.medsList.slice();
      for (var i = 0; i < newState.length; i++) {
        if (newState[i] === evt.currentTarget.name) {
          newState.splice(i, 1);
          i--;
        }
      }
      this.setState({
        medsList: newState,
      });
    }
  }
  testSelect() {
    let panelList = [];
    if (this.state.test) {
      this.setState({
        panelList: this.props.labTests.get(this.state.test),
      });
    }
  }

  render() {
    const meds = [
      'Norco',
      'Percocet',
      'Suboxone',
      'Subutex',
      'Methadose',
      'Ultram',
      'Valium',
      'Klonopin',
      'Xanax',
      'Abilify',
      'Risperdal',
      'Seroquel',
      'Celexa/Lexapro',
      'Prozac',
      'Trazodone',
      'Wellbutrin',
      'Zoloft',
      'Adderall',
      'Ambien',
      'Neurontin',
    ];
    let labTests = () => {
      return (
        <GridRow id="labNameRow">
          <GridCell span={3} style={{ left: '48%', position: 'absolute' }}>
            <Typography>Select Test Type:</Typography>
          </GridCell>
          <GridCell
            span={3}
            style={{
              left: '57%',
              position: 'absolute',
              width: '35%',
            }}
          >
            <Select
              value={this.state.test}
              label="Test Type"
              outlined
              options={this.state.testOptions}
              name="test"
              onChange={(event) => {
                let value = event.currentTarget.value;
                this.clearChecks();
                this.setState({}, () => {
                  this.setState({
                    test: value,
                    checked: value,
                    orderList: [],
                    medsList: [],
                  });
                });
              }}
            ></Select>
          </GridCell>
        </GridRow>
      );
    };
    let testType = () => {
      if (this.state.test !== null && this.state.test !== undefined)
        return (
          <GridRow id="uwRequestTypeRow">
            <GridCell span={3} style={{ left: '3%', position: 'absolute' }}>
              <Typography>Select Report Type:</Typography>
            </GridCell>
            <GridCell
              span={9}
              style={{ position: 'absolute', left: '11%', width: '35%' }}
            >
              <Select
                label="Report Type"
                outlined
                options={['Request By Facility', 'Request By Patient']}
                value={this.state.reportType}
                name="reportType"
                onChange={(event) => {
                  let value = event.currentTarget.value;
                  this.setState({ reportSelect: '' }, () => {
                    this.setState({ reportType: value });
                  });
                }}
              ></Select>
            </GridCell>
          </GridRow>
        );
    };
    let selectTestList = () => {
      return (
        <div>
          <Paper id="reqPaper" variant="outlined">
            <Typography
              use="headline6"
              style={{
                color: 'dodgerblue',
                left: '30%',
                position: 'relative',
              }}
            >
              Test Select
            </Typography>
            <List
              style={{
                height: '90%',
                position: 'absolute',
                overflow: 'auto',
                width: '100%',
              }}
              id="list1"
              dense
              component="div"
              role="list"
            >
              {this.state.test !== null &&
              this.state.test !== undefined &&
              this.state.test !== ''
                ? this.props.labTests.get(this.state.test).map((labTest) => {
                    let test = JSON.stringify(labTest);
                    let checkname = 'check' + labTest.labTestID;

                    if (labTest.type === this.state.test) {
                      return (
                        <ListItem role="listitem">
                          <ListItemIcon>
                            <Checkbox
                              name={labTest.labTestID}
                              tabIndex={-1}
                              disableRipple
                              checked={this.state[checkname]}
                              value={test}
                              onChange={(evt) => {
                                this.orderList(evt, checkname);
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={labTest.panelName} />
                        </ListItem>
                      );
                    }
                  })
                : null}
              <ListItem />
            </List>
          </Paper>
        </div>
      );
    };
    let printType = () => {
      if (
        this.state.reportType !== null &&
        this.state.reportType !== undefined
      ) {
        if (this.state.reportType === 'Request By Facility') {
          return (
            <div>
              <GridRow id="uwFacilityLabReqRow">
                <GridCell
                  span={3}
                  style={{ left: '48%', position: 'absolute' }}
                >
                  <Typography>Select Facility Name:</Typography>
                </GridCell>
                <GridCell
                  span={9}
                  style={{ width: '35%', left: '57%', position: 'absolute' }}
                >
                  <Select
                    value={this.state.reportSelect}
                    label="Facility"
                    outlined
                    options={this.state.facilityList.map((facility) => {
                      let option = {};
                      option.label = facility.facilityName;
                      option.value = JSON.stringify(facility);
                      return option;
                    })}
                    onChange={(event) => {
                      this.setState({
                        reportSelect: event.currentTarget.value,
                      });
                    }}
                  ></Select>
                </GridCell>
              </GridRow>
              {selectTestList()}
            </div>
          );
        } else if (this.state.reportType === 'Request By Patient') {
          return (
            <div>
              <GridRow id="uwFacilityLabReqRow">
                <GridCell
                  span={3}
                  style={{ left: '48%', position: 'absolute' }}
                >
                  <Typography>Select Patient Name:</Typography>
                </GridCell>
                <GridCell
                  span={9}
                  style={{ width: '35%', left: '57%', position: 'absolute' }}
                >
                  <Select
                    value={this.state.reportSelect}
                    label="Patient"
                    outlined
                    options={this.state.patientList.map((patient) => {
                      let option = {};
                      option.label =
                        patient.patientLastName +
                        ', ' +
                        patient.patientFirstName;
                      option.value = JSON.stringify(patient);
                      return option;
                    })}
                    onChange={(event) => {
                      this.setState({
                        reportSelect: event.currentTarget.value,
                      });
                    }}
                  ></Select>
                </GridCell>
              </GridRow>
              {selectTestList()}
            </div>
          );
        }
      }
    };
    return (
      <div>
        <div id="labReqTitle">
          <Typography use="headline4">Generate Lab Request</Typography>
        </div>
        <GridRow id="labNameRow">
          <GridCell span={3} style={{ left: '3%', position: 'absolute' }}>
            <Typography>Select Lab Name:</Typography>
          </GridCell>
          <GridCell
            span={3}
            style={{
              left: '11%',
              position: 'absolute',
              width: '35%',
            }}
          >
            <Select
              placeholder="Lab Name"
              outlined
              options={this.state.labList.map((lab) => {
                let json = {};
                json.value = JSON.stringify(lab);
                json.label = lab.labName;

                return json;
              })}
              value={this.state.selectedLab}
              name="test"
              onChange={(event) => {
                let value = JSON.parse(event.currentTarget.value);
                this.props.labTestsList(value.labID);
                this.setState({ test: '' }, () => {
                  this.setState({
                    labName: value.labName,
                    selectedLab: event.currentTarget.value,
                  });
                });
              }}
            ></Select>
          </GridCell>
        </GridRow>
        {labTests()}
        {testType()}
        {printType()}
        {this.props.reportGenerating ? (
          <LinearProgress id="reportLoadBar"></LinearProgress>
        ) : null}

        <GridRow id="reportButtonRow">
          <GridCell>
            <Button
              id="createReportButton"
              variant="contained"
              disabled={this.props.reportGenerating}
              onClick={() => {
                if (
                  this.state.labName === null ||
                  this.state.labName === undefined ||
                  this.state.test === null ||
                  this.state.test === undefined ||
                  this.state.reportSelect === null ||
                  this.state.reportSelect === undefined ||
                  this.state.testType === null ||
                  this.state.testType === undefined
                ) {
                  Swal.fire({
                    icon: 'error',
                    text: 'Please fill out all test request fields',
                  });
                } else if (
                  this.state.checked === 'Respiratory' &&
                  this.state.checkbox1 === false &&
                  this.state.checkbox2 === false &&
                  this.state.checkbox3 === false &&
                  this.state.checkbox4 === false
                ) {
                  Swal.fire({
                    icon: 'error',
                    text: 'Please make respiratory test selection',
                  });
                } else {
                  if (this.state.reportType === 'Request By Facility') {
                    if (
                      this.state.reportSelect !== null &&
                      this.state.reportSelect !== undefined
                    ) {
                      this.createChecked('facility');
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: 'Please select a facility',
                      });
                    }
                  } else if (this.state.reportType === 'Request By Patient') {
                    if (
                      this.state.reportSelect !== null &&
                      this.state.reportSelect !== undefined
                    ) {
                      this.createChecked('patient');
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: 'Please select a patient',
                      });
                    }
                  }
                }
              }}
            >
              Generate Request
            </Button>
          </GridCell>
        </GridRow>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  labs: state.labs.labs,
  patients: state.report.patients,
  facilities: state.report.facilities,
  labTests: state.report.labTests,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getLabsRequest: () => dispatch(getLabsRequest()),
    getFacilities: () => dispatch(getFacilityRequest()),
    patientNameList: () => dispatch(patientNameList()),
    labTestsList: (labName) => dispatch(labTestsList(labName)),
    generateLabReq: (patientName, facility, testType, labName, sampleType) =>
      dispatch(
        generateLabReq(patientName, facility, testType, labName, sampleType)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabReqComponent);
