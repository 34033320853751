import {
  ADMIN_QUERY_WEBUSERS_SUCCESS,
  ADMIN_FACILITYLIST_SUCCESS,
  QUERY_CURRENT_WEBUSER_SUCCESS,
  PROFILE_LOADING,
  PROFILE_LOADING_FAIL,
  PROFILE_LOADING_SUCCESS,
  QUERY_WATCHLIST_SUCCESS,
  QUERY_NOTIFICATIONS_SUCCESS,
  QUERY_QUICKREPORTS_SUCCESS,
} from '../constants/userConstants';

const initialState = {
  webUsers: [],
  facilities: [],
  user: [],
  profileLoadingSuccess: null,
  profileLoading: false,
  watchList: [],
  notifications: [],
  quickReports: [],
};
const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_QUERY_WEBUSERS_SUCCESS:
      return {
        ...state,
        webUsers: action.webUserResults,
      };
    case ADMIN_FACILITYLIST_SUCCESS:
      return {
        ...state,
        facilities: action.facilityList,
      };
    case QUERY_CURRENT_WEBUSER_SUCCESS:
      return {
        ...state,
        user: action.userResults,
      };
    case PROFILE_LOADING:
      return {
        ...state,
        profileLoading: true,
        profileLoadingSuccess: false,
      };
    case PROFILE_LOADING_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        profileLoadingSuccess: true,
      };
    case PROFILE_LOADING_FAIL:
      return {
        ...state,
        profileLoading: false,
        profileLoadingSuccess: false,
      };
    case QUERY_WATCHLIST_SUCCESS:
      return {
        ...state,
        watchList: action.watchList,
      };
    case QUERY_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case QUERY_QUICKREPORTS_SUCCESS:
      return {
        ...state,
        quickReports: action.quickReports,
      };

    default:
      return state;
  }
};
export default adminReducer;
