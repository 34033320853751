import {
  LAB_HISTORY_REQUEST,
  LAB_HISTORY_REQUEST_SUCCESS,
  LAB_HISTORY_REQUEST_FAIL,
} from '../constants/labHistoryConstants';

export const labHistoryRequest = () => ({
  type: LAB_HISTORY_REQUEST,
});
export const labHistoryRequestSuccess = (results) => ({
  type: LAB_HISTORY_REQUEST_SUCCESS,
  history: results,
});
export const labHistoryRequestFail = () => ({
  type: LAB_HISTORY_REQUEST_FAIL,
});
export const getLabHistoryRequest = (condition, col) => {
  return (dispatch) => {
    let where = '';
    if (condition !== '') {
      if (col === 'Laboratory') {
        where = 'Laboratory.labName="' + condition + '"';
      } else {
        where = 'Patients.patientLastName="' + condition + '"';
      }
    }
    let url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/getOrderHistory?col=*&where=' +
        where
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(labHistoryRequestSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(labHistoryRequestFail(error));
      });
  };
};
