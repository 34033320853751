import React from 'react';
import { connect } from 'react-redux';

class RootDialogComponent extends React.Component {
  getDialog = () => {
    switch (this.props.dialog) {
      default:
        return null; //required because render() must return something
    }
  };

  render() {
    return this.getDialog();
  }
}

const mapStateToProps = (state) => ({
  dialog: state.dialog.dialog,
});

export default connect(mapStateToProps)(RootDialogComponent);
