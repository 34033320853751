import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@rmwc/typography';
import { getFacilityRequest } from '../actions/userActions';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { TextField } from '@rmwc/textfield';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import {
  clearMetrics,
  printPTA,
  getGroupsRequest,
  getPARequest,
} from '../actions/metricsActions';
import { Select } from '@rmwc/select';
import { Radio, RadioGroup } from '@material-ui/core';
import { List, ListItem } from '@rmwc/list';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';
import Grid from '@material-ui/core/Grid';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';

import {
  getSubtestRequest,
  getPanTestRequest,
} from '../actions/resultsActions';
import randomColor from 'randomcolor';
import Swal from 'sweetalert2';
import { patientNameList } from '../actions/reportActions';

class PatientAnalyticsComponent extends Component {
  constructor() {
    super();
    this.state = {
      metricStartDate: '',
      metricEndDate: '',
      facilities: [],
      patients: [],
      testList: [],
      searchModalOpen: false,
      checked: [],
      testChecked: [],
      data: [],
      selectAll: false,
      rv: 'day range',
      searchBy: 'Patient',
      groups: [],
      testType: 'subtest',
    };
    this.clearChecks = this.clearChecks.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let patientList = [];
    props.patients.forEach((patient) => {
      var patientDOB = patient.patientDOB;
      var epoch = parseInt(patientDOB, 10);
      var hrDate = new Date(epoch);
      var month = hrDate.getMonth() + 1;
      patientList.push(patient);
    });
    let facilityList = [];
    props.facilities.forEach((fac) => {
      facilityList.push(fac);
    });
    let groupList = [];
    props.groups.forEach((group) => {
      groupList.push(group);
    });
    if (
      props.data &&
      state.data &&
      JSON.stringify(props.data.sort()) !== JSON.stringify(state.data.sort())
    ) {
      return {
        ...state,
        facilities: props.facilities,
        testList: props.testList,
        data: props.data,
        newData: true,
        patients: props.patients,
        patientList: patientList,
        facilityList: facilityList,
        groups: props.groups,
        groupList: groupList,
        searching: props.searching,
      };
    } else {
      return {
        ...state,
        facilities: props.facilities,
        testList: props.testList,
        newData: false,
        patients: props.patients,
        patientList: patientList,
        facilityList: facilityList,
        groups: props.groups,
        groupList: groupList,
        searching: props.searching,
      };
    }
  }

  componentDidMount() {
    this.setState({ searchModalOpen: true });
    this.props.getFacilities();
    this.props.patientNameList();
    this.props.getSubtests();
    this.props.getPanTestRequest('');
    this.props.getGroupsRequest();
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }
  componentDidUpdate() {
    if (this.state.newData) {
      this.state.facilities.forEach((facility) => {
        let randColor = randomColor({
          seed: facility.facilityID,
          luminosity: 'dark',
        });

        this.setState({ [facility.facilityID]: randColor });
      });
    }
  }

  collapse = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  search = () => {
    let body = {};

    let startDate;
    let endDate;
    let date2;
    let date;

    if (this.state.rv === 'month range') {
      date = new Date(Date.parse(this.state.metricStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(Date.parse(this.state.metricEndDate));
      date2 = new Date(date2.getFullYear(), date2.getMonth() + 2, 0);
      endDate = date2.toISOString().slice(0, 10);
    } else if (this.state.rv === 'month') {
      date = new Date(Date.parse(this.state.metricStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(date.toUTCString());
      date2.setUTCMonth(date2.getUTCMonth() + 1);
      endDate = date2.toISOString().slice(0, 10);
    } else if (this.state.rv === 'day range') {
      date = new Date(Date.parse(this.state.metricStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(Date.parse(this.state.metricEndDate));
      endDate = date2.toISOString().slice(0, 10);
    }
    body = {
      dateFrom: startDate,
      dateTo: endDate,
      facilities: [JSON.parse(this.state.checked)],
      tests: this.state.testChecked,
      searchType: this.state.searchBy,
      searchSpan: this.state.rv,
      testType: this.state.testType,
    };
    this.props.getPA(body);
    this.setState({ searchModalOpen: false, metricEndDate: endDate });
  };

  saveCanvas() {
    let canvasSave = document.getElementsByClassName('graph');
    let graphs = [];
    for (let i = 0; i < canvasSave.length; i++) {
      const d = canvasSave[i].toDataURL('image/png');

      graphs.push(d.replace(/^data:image\/(png|jpg);base64,/, ''));
    }
    this.setState({
      graphImage: graphs,
    });
    return graphs;
  }
  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  selectAll = (e) => {
    if (this.state.testType === 'panel') {
      this.props.panList.map((test) => {
        if (test.panelName !== null) {
          this.setState({ [test.panelName]: true });
          let testChecked = this.state.testChecked;
          if (!this.state[test.panelName]) {
            testChecked.push(test);
          } else {
            testChecked = testChecked.filter(
              (check) => check.panelName !== test.panelName
            );
          }
          this.setState({
            [test.panelName]: !this.state[test.panelName],
            testChecked: testChecked,
          });
        }
        this.setState({ selectAll: !this.state.selectAll });
      });
    } else {
      this.props.testList.map((test) => {
        let testCHK = 'checkd' + test.testName;
        if (test.testName !== null) {
          // this.setState({ [testCHK]: true });
          let testChecked = this.state.testChecked;
          if (!this.state[testCHK]) {
            testChecked.push(test);
          } else {
            testChecked = testChecked.filter(
              (check) => check.testName !== test.testName
            );
          }
          this.setState({
            [testCHK]: !this.state[testCHK],
            testChecked: testChecked,
          });
        }
        this.setState({ selectAll: !this.state.selectAll });
      });
    }
  };
  clearChecks = () => {
    Object.keys(this.state).map((key) => {
      let regex = new RegExp('checkd*');
      this.setState({ testChecked: [] });
      if (regex.test(key)) {
        this.setState({ [key]: false });
      }
      this.setState({ selectAll: false });
      return null;
    });
  };

  render() {
    let datasets = [];
    let name = '';
    let dateRange = '';

    let searchingModal = () => {
      return (
        <div
          id="searchingDialog"
          style={{ paddingLeft: '44%', paddingTop: '5%' }}
        >
          <CircularProgress size={100}></CircularProgress>
        </div>
      );
    };
    let searchModal = () => {
      return (
        <Dialog
          id="metricSearchDialog"
          open={this.state.searchModalOpen}
          onOpen={() => {
            this.props.clearMetrics();
          }}
          onClose={() => {
            this.setState({ searchModalOpen: false, name: '', checked: [] });
          }}
          maxwidth="md"
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Patient Test Analysis
          </DialogTitle>
          <DialogContent
            id="trendsDialogContent"
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
              width: '100%',
            }}
          >
            <Grid>
              <div style={{ height: '15px' }}></div>
            </Grid>
            <Grid container>
              <Grid
                container
                spacing={4}
                alignContent="center"
                justifyContent="center"
              >
                <Grid item xs={3}>
                  <Typography>Select Patient Name: </Typography>
                </Grid>
                <Grid item xs={7}>
                  {this.state.searchBy === 'Patient' ? (
                    <Select
                      id="patientSelect"
                      label="Patient"
                      outlined
                      // native
                      // displayEmpty
                      value={this.state.name}
                      onChange={(event) => {
                        this.setState({
                          checked: event.currentTarget.value,
                          name: event.currentTarget.name,
                        });
                      }}
                      // labelId="Patient"
                      // inputProps={{
                      //   name: 'checked',
                      // }}
                    >
                      {this.state.patientList.map((state) => (
                        <option
                          key={state.patientID}
                          name={state.patientID}
                          value={JSON.stringify(state)}
                        >
                          {state.patientLastName +
                            ', ' +
                            state.patientFirstName}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      style={{ width: '100%' }}
                      label="Select a search type"
                      outlined
                      // native
                      // displayEmpty
                    ></Select>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={4}
                alignContent="center"
                justifyContent="center"
              >
                <Grid item xs={5}>
                  <Typography
                    style={{ top: '20px', left: '0px', position: 'relative' }}
                  >
                    Select Date Search:{' '}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ left: '-150px', position: 'relative', top: '20px' }}
                >
                  <RadioGroup
                    value={this.state.rv}
                    name="rv"
                    onChange={this.handleChange}
                    row
                  >
                    <FormControlLabel
                      value="day range"
                      control={<Radio color="primary" />}
                      label="Day Range"
                    />
                    <FormControlLabel
                      value="month range"
                      control={<Radio color="primary" />}
                      label="Month Range"
                    />
                    <FormControlLabel
                      value="month"
                      control={<Radio color="primary" />}
                      label="Month"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                alignContent="center"
                justifyContent="center"
                style={{
                  top: '20px',
                  position: 'relative',
                  left: '160px',
                  width: '85%',
                }}
              >
                <Grid item xs={4}>
                  {' '}
                  {this.state.rv === 'month' ||
                  this.state.rv === 'month range' ? (
                    <div id="metricStartDate">
                      <Typography style={{ left: '15%', position: 'relative' }}>
                        Start Month
                      </Typography>
                      <form noValidate>
                        <TextField
                          style={{ width: '85%', left: '15%' }}
                          outlined
                          id="metricStartDateCalender"
                          name="metricStartDate"
                          type="month"
                          value={this.state.metricStartDate}
                          onChange={this.handleChange}
                        />
                      </form>
                    </div>
                  ) : (
                    <div id="metricStartDate">
                      <Typography style={{ left: '15%', position: 'relative' }}>
                        Start Day
                      </Typography>
                      <form noValidate>
                        <TextField
                          style={{ width: '85%', left: '15%' }}
                          outlined
                          id="metricStartDateCalender"
                          name="metricStartDate"
                          type="date"
                          value={this.state.metricStartDate}
                          onChange={this.handleChange}
                        />
                      </form>
                    </div>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {this.state.rv === 'month range' ? (
                    <div id="metricEndDate">
                      <Typography style={{ left: '-8%', position: 'relative' }}>
                        End Month
                      </Typography>
                      <form noValidate>
                        <TextField
                          style={{ width: '85%', left: '-8%' }}
                          outlined
                          id="metricEndDateCalender"
                          name="metricEndDate"
                          type="month"
                          value={this.state.metricEndDate}
                          onChange={this.handleChange}
                        />
                      </form>
                    </div>
                  ) : this.state.rv === 'day range' ? (
                    <div id="metricEndDate">
                      <Typography style={{ left: '-8%', position: 'relative' }}>
                        End Day
                      </Typography>
                      <form noValidate>
                        <TextField
                          style={{ width: '85%', left: '-8%' }}
                          outlined
                          id="metricEndDateCalender"
                          name="metricEndDate"
                          type="date"
                          value={this.state.metricEndDate}
                          onChange={this.handleChange}
                        />
                      </form>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                alignContent="center"
                justifyContent="center"
              >
                <Grid item xs={5}>
                  <Typography
                    style={{ top: '20px', left: '0px', position: 'relative' }}
                  >
                    Select Panel or Test:{' '}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ left: '-150px', position: 'relative', top: '20px' }}
                >
                  <RadioGroup
                    value={this.state.testType}
                    name="testType"
                    onChange={(e) =>
                      this.setState({
                        [e.target.name]: e.target.value,
                        testChecked: [],
                      })
                    }
                    row
                  >
                    <FormControlLabel
                      value="panel"
                      control={<Radio color="primary" />}
                      label="Panel"
                    />
                    <FormControlLabel
                      value="subtest"
                      control={<Radio color="primary" />}
                      label="Test"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignContent="center">
                <Grid
                  item
                  xs={9}
                  style={{
                    borderStyle: 'solid',
                    left: '0%',
                    position: 'relative',
                    top: '7%',
                  }}
                >
                  {this.state.testType === 'panel' ? (
                    <Typography>Panels</Typography>
                  ) : (
                    <Typography>Tests</Typography>
                  )}
                  <List
                    id="testList"
                    dense={true}
                    style={{
                      height: '180px',
                      position: 'reletive',
                      overflow: 'auto',
                      width: '100%',
                      top: '20px',
                    }}
                  >
                    {this.props.testList.length > 0 &&
                    this.state.testType === 'subtest'
                      ? this.props.testList.map((test) => {
                          if (test.testName !== null) {
                            let testCHK = 'checkd' + test.testName;
                            return (
                              <ListItem
                                key={test.testName}
                                style={{
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                  color: 'black',
                                  width: '100%',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state[testCHK]
                                          ? this.state[testCHK]
                                          : false
                                      }
                                      onClick={() => {
                                        let testChecked =
                                          this.state.testChecked;
                                        if (!this.state[testCHK]) {
                                          testChecked.push(test);
                                        } else {
                                          testChecked = testChecked.filter(
                                            (check) =>
                                              check.testName !== test.testName
                                          );
                                        }
                                        this.setState({
                                          [testCHK]: !this.state[testCHK],
                                          testChecked: testChecked,
                                        });
                                      }}
                                      name={test.testName}
                                      color="primary"
                                    />
                                  }
                                  label={test.testName}
                                />
                              </ListItem>
                            );
                          }
                        })
                      : null}
                    {this.props.testList.length > 0 &&
                    this.state.testType === 'panel'
                      ? this.props.panList.map((test) => {
                          if (test.testName !== null) {
                            let testCHK = 'checkd' + test.panelName;

                            return (
                              <ListItem
                                style={{
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                  color: 'black',
                                  width: '100%',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={
                                        this.state.testChecked.length !== 0 &&
                                        !this.state[testCHK]
                                      }
                                      checked={
                                        this.state[testCHK]
                                          ? this.state[testCHK]
                                          : false
                                      }
                                      onClick={() => {
                                        let testChecked =
                                          this.state.testChecked;
                                        if (!this.state[testCHK]) {
                                          testChecked.push(test);
                                        } else {
                                          testChecked = testChecked.filter(
                                            (check) =>
                                              check.panelName !== test.panelName
                                          );
                                        }
                                        if (this.state[testCHK]) {
                                          this.setState({
                                            [test.panelName]:
                                              !this.state[test.panelName],
                                            [testCHK]: !this.state[testCHK],

                                            testChecked: testChecked,
                                            panel: test.panelName,
                                          });
                                        } else {
                                          this.setState({
                                            [test.panelName]:
                                              !this.state[test.panelName],
                                            [testCHK]: true,

                                            testChecked: testChecked,
                                            panel: test.panelName,
                                          });
                                        }
                                      }}
                                      name={test.panelName}
                                      color="primary"
                                    />
                                  }
                                  label={test.panelName}
                                />
                              </ListItem>
                            );
                          }
                        })
                      : null}

                    {this.props.panList.length <= 0 &&
                    this.state.testType === 'panel' ? (
                      <Typography variant="h2">No Panels</Typography>
                    ) : null}
                    {this.props.testList.length <= 0 &&
                    this.state.testType === 'subtest' ? (
                      <Typography variant="h2">No Tests</Typography>
                    ) : null}
                  </List>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignContent="center">
                <Grid item xs={3}>
                  <Button
                    id="createMetricsButton"
                    variant="contained"
                    onClick={() => {
                      if (
                        this.state.rv === 'month range' &&
                        (this.isEmpty(this.state.metricStartDate) ||
                          this.isEmpty(this.state.metricEndDate) ||
                          this.isEmpty(this.state.checked) ||
                          this.isEmpty(this.state.testChecked) ||
                          this.isEmpty(this.state.searchBy))
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all fields.',
                        });
                      } else if (
                        this.state.rv === 'day range' &&
                        (this.isEmpty(this.state.metricStartDate) ||
                          this.isEmpty(this.state.checked) ||
                          this.isEmpty(this.state.testChecked) ||
                          this.isEmpty(this.state.searchBy))
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all fields.',
                        });
                      } else {
                        this.search();
                        if (this.state.rv === 'month range') {
                          this.setState({
                            addUserDialogOpen: false,
                            editingUser: false,
                            sliceStart:
                              new Date(this.state.metricStartDate)
                                .toISOString()
                                .slice(5, 7) +
                              '-' +
                              new Date(this.state.metricStartDate)
                                .toISOString()
                                .slice(0, 4),
                            sliceEnd:
                              new Date(this.state.metricEndDate)
                                .toISOString()
                                .slice(5, 7) +
                              '-' +
                              new Date(this.state.metricEndDate)
                                .toISOString()
                                .slice(0, 4),
                          });
                        } else {
                          this.setState({
                            addUserDialogOpen: false,
                            editingUser: false,
                            checked: [],
                            sliceStart:
                              new Date(this.state.metricStartDate)
                                .toISOString()
                                .slice(5, 7) +
                              '-' +
                              new Date(this.state.metricStartDate)
                                .toISOString()
                                .slice(0, 4),
                            sliceEnd: '',
                          });
                        }
                      }
                    }}
                  >
                    Generate Trends
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              icon="close"
              // cancel
              outlined
              onClick={() => {
                this.setState({ searchModalOpen: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          maxheight: '100%',
          maxwidth: '100%',
          displa0y: 'flex',
          position: 'reletive',
        }}
      >
        {this.state.searching === true ? searchingModal() : null}
        <div style={{ zIndex: 100 }}></div>
        {searchModal()}
        <div
          style={{
            height: '90%',
            position: 'absolute',
            width: '95%',
            left: '2%',
            overflowY: 'auto',
          }}
          id="canvas"
        >
          {this.state.data.length > 0
            ? Object.keys(this.state.data[0].patientData).map((test) => {
                let datasets = [];
                let labels = [];
                this.state.data.map((facData) => {
                  var randColor = randomColor({
                    seed: facData.facilityID,
                    luminosity: 'dark',
                  });
                  let jsonTotal = {};
                  let years = Object.keys(facData.patientData);

                  if (facData.searchType === 'patient') {
                    name = facData.patientName;
                  }
                  jsonTotal.label = test + ' for ' + name;
                  if (this.state[facData.facilityID]) {
                    jsonTotal.borderColor = this.state[facData.facilityID];
                    jsonTotal.backgroundColor = this.state[facData.facilityID];
                  } else {
                    jsonTotal.borderColor = randColor;
                    jsonTotal.backgroundColor = randColor;
                  }

                  let yearTotalData = [];
                  let patientTests = JSON.parse(
                    this.state.data[0].patientData[test].tests
                  );
                  patientTests.forEach((test) => {
                    yearTotalData.push(Number(test.subtestValue));
                    let date = new Date(Date.parse(test.reportedDate));

                    labels.push(
                      date.toLocaleString('en-US', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      })
                    );
                  });

                  jsonTotal.data = yearTotalData;

                  datasets.push(jsonTotal);
                });

                let data = {
                  labels,
                  datasets: datasets,
                };
                let SD = new Date(Date.parse(this.state.metricStartDate));
                let ED = new Date(Date.parse(this.state.metricEndDate));
                let title = 'Patient ' + test + ' Analysis';
                let ds =
                  SD.toLocaleDateString() + ' - ' + ED.toLocaleDateString();
                const options = {
                  responsive: true,
                  position: 'relitive',
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: [title, ds],

                      font: {
                        size: 30,
                      },
                    },
                  },
                };
                return (
                  <Line
                    id="graph"
                    className="graph"
                    options={options}
                    data={data}
                    // style={{ height: '25%' }}
                  ></Line>
                );
              })
            : null}
        </div>
        <div>
          <div>
            <Button
              id="addButton"
              icon={<SearchIcon />}
              label="Search Trends"
              onClick={() => {
                this.clearChecks();
                this.setState({
                  searchModalOpen: !this.state.searchModalOpen,
                  data: [],
                  testChecked: [],
                  metricStartDate: '',
                  metricEndDate: '',
                  checked: [],
                  rv: 'day range',
                });
              }}
            >
              Search Trends
            </Button>
          </div>
          <div>
            <Button
              id="mergeButton"
              label="Create PDF Report"
              onClick={() => {
                if (this.isEmpty(this.state.data)) {
                  Swal.fire({
                    icon: 'warning',
                    text: 'Please create a patient analysis search',
                  });
                } else {
                  let json = { data: this.state.data, img: this.saveCanvas() };
                  this.props.printPTA(json);
                }
              }}
            >
              Create PDF Report
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  facilities: state.metrics.facilities,
  data: state.metrics.paData,
  testList: state.metrics.testList,
  panList: state.metrics.panTestList,
  patients: state.report.patients,
  groups: state.metrics.groups,
  searching: state.metrics.searching,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getFacilities: () => dispatch(getFacilityRequest()),
    getSubtests: () => dispatch(getSubtestRequest()),
    getPA: (body) => dispatch(getPARequest(body)),
    clearMetrics: () => dispatch(clearMetrics()),
    printPTA: (body) => dispatch(printPTA(body)),
    patientNameList: () => dispatch(patientNameList()),
    getGroupsRequest: () => dispatch(getGroupsRequest()),
    getPanTestRequest: (labID) => dispatch(getPanTestRequest(labID)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientAnalyticsComponent);
