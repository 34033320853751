import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getCodesRequest,
  addTestRequest,
  deleteTestRequest,
  updateTestRequest,
} from '../actions/labOrderManagerActions';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Select,
} from '@material-ui/core';
import { labTestsList } from '../actions/reportActions';
import { getLabsRequest } from '../actions/labManagementActions';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableContent,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow,
  Dialog,
  DialogActions,
  DialogButton,
  Grid,
  GridCell,
  GridRow,
  MenuItem,
  Typography,
} from 'rmwc';
import { getPanTestRequest } from '../actions/resultsActions';
import Swal from 'sweetalert2';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

class LabOrderManagerComponent extends Component {
  constructor() {
    super();
    this.state = {
      selectedLab: null,
      testType: '',
      selectedCodes: [],
      panelName: '',
      updatingTest: false,
    };
  }

  componentDidMount() {
    this.props.getCodes();
    this.props.getLabsRequest();
  }

  buildJson() {
    let json = {};
    let lab = JSON.parse(this.state.selectedLab);
    json.type = this.state.testType;
    json.cpt = this.state.selectedCodes;
    json.panelName = this.state.panelName;
    json.labID = lab.labID;
    this.props.addLabTest(json);
  }
  updateJson() {
    let json = {};
    let lab = JSON.parse(this.state.selectedLab);
    json.type = this.state.testType;
    json.cpt = this.state.selectedCodes;
    json.panelName = this.state.panelName;
    json.labID = lab.labID;
    json.labTestID = this.state.labTestID;
    this.props.updateLabTest(json);
  }

  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  }

  render() {
    const useStyles = makeStyles({
      listbox: {
        boxSizing: 'border-box',
        '& ul': {
          padding: 0,
          margin: 0,
        },
      },
    });
    const classes = useStyles;
    const filter = createFilterOptions();
    const testTypeList = [
      { testType: 'Serum' },
      { testType: 'Plasma' },
      { testType: 'Urine' },
      { testType: 'Wound' },
      { testType: 'Stool' },
    ];

    let addLabDialog = () => {
      return (
        <Dialog
          open={this.state.addLabTestDialogOpen}
          onClose={(evt) => {
            this.setState({
              addLabTestDialogOpen: false,
              testType: ' ',
              selectedCodes: [],
              panelName: ' ',
              updatingTest: false,
            });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Add Laboratory Test
          </DialogTitle>
          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow style={{ marginTop: '20px' }}>
                <GridCell span={3}>Panel Name:</GridCell>
                <GridCell span={9}>
                  {this.props.panList.length > 0 ? (
                    <Autocomplete
                      value={this.state.panelName}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          this.setState({
                            panelName: newValue,
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          this.setState({
                            panelName: newValue.inputValue,
                          });
                        } else {
                          this.setState(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            panelName: `Add "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="labTestSelector"
                      options={
                        this.props.labTests.size > 0 ? [] : this.props.panList
                      }
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.panelName;
                      }}
                      renderOption={(option) => option.panelName}
                      style={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Panel Name"
                          variant="outlined"
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      name="panelName"
                      outlined
                      label="Panel Name"
                      value={this.state.panelName}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                  )}
                </GridCell>
              </GridRow>
              <GridRow style={{ marginTop: '20px' }}>
                <GridCell span={3}>
                  <Typography>Code Selector:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <Autocomplete
                    multiple
                    id="virtualize-codes"
                    style={{ width: 300 }}
                    disableListWrap
                    classes={classes}
                    value={this.state.selectedCodes}
                    ListboxComponent={ListboxComponent}
                    renderGroup={renderGroup}
                    onChange={(event, newInputValue) => {
                      this.setState({ selectedCodes: newInputValue });
                    }}
                    options={this.props.codes.map((code) => {
                      let option = code.code + ' ' + code.description;
                      return option;
                    })}
                    groupBy={(option) => option[0].toUpperCase()}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="ICD10 Codes"
                        />
                      );
                    }}
                    renderOption={(option) => (
                      <Typography noWrap>{option}</Typography>
                    )}
                  />
                </GridCell>
              </GridRow>
              <GridRow style={{ marginTop: '20px' }}>
                <GridCell span={3}>
                  <Typography>Test Type</Typography>
                </GridCell>
                <GridCell span={4}>
                  <Autocomplete
                    value={this.state.testType}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        this.setState({
                          testType: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        this.setState({
                          testType: newValue.inputValue,
                        });
                      } else {
                        this.setState(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          testType: `Add "${params.inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="testTypeSelect"
                    options={testTypeList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.testType;
                    }}
                    renderOption={(option) => option.testType}
                    style={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Test Type selector"
                        variant="outlined"
                      />
                    )}
                  />
                </GridCell>
                <GridCell
                  span={3}
                  style={{ left: '-20%', position: 'relative', width: '235px' }}
                ></GridCell>
              </GridRow>
              <GridRow></GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            {this.state.updatingTest ? (
              <DialogButton
                action="add"
                icon="add"
                unelevated
                onClick={(evt) => {
                  if (this.isEmpty(this.state.selectedLab.trim())) {
                    evt.stopPropagation();
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'you must fill out the required fields',
                    });
                  } else {
                    this.updateJson();
                    this.setState({
                      addLabTestDialogOpen: true,
                    });
                  }
                }}
              >
                Update Test
              </DialogButton>
            ) : (
              <DialogButton
                action="add"
                icon="add"
                unelevated
                onClick={(evt) => {
                  if (this.isEmpty(this.state.selectedLab.trim())) {
                    evt.stopPropagation();
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'you must fill out the required fields',
                    });
                  } else {
                    this.buildJson();
                    this.setState({
                      addLabTestDialogOpen: true,
                    });
                  }
                }}
              >
                Add Test
              </DialogButton>
            )}

            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({
                  addLabTestDialogOpen: false,
                  testType: '',
                  selectedCodes: [],
                  panelName: '',
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let labTestTable = () => {
      if (this.props.labTests.length === 0 || this.props.labTests.size === 0) {
        return (
          <Typography id="noResult" use="headline6" style={{ right: '40%' }}>
            No current tests
          </Typography>
        );
      } else {
        let keys = this.props.labTests.keys();
        let testsData = [];

        for (let [key, value] of this.props.labTests) {
          let testsData2 = this.props.labTests.get(key).map((test, i) => {
            if (i === 0 && test.pannelName === null) {
              return (
                <Typography
                  id="noResult"
                  use="headline6"
                  style={{ right: '40%' }}
                >
                  No current diagnosis
                </Typography>
              );
            } else {
              let cpt = JSON.parse(test.cpt);
              return (
                <DataTableRow key={test.labTestID}>
                  <DataTableCell>{test.panelName}</DataTableCell>
                  <DataTableCell>{test.labName}</DataTableCell>
                  <DataTableCell>{test.type}</DataTableCell>
                  <DataTableCell>
                    {cpt.length > 0
                      ? cpt.map((code) => {
                          return [code, <br />];
                        })
                      : 'none'}
                  </DataTableCell>
                  <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                    <Button
                      disabled={this.props.role !== 'Tsalta Admin'}
                      icon="delete"
                      onClick={() => {
                        this.setState({
                          addLabTestDialogOpen: true,
                          updatingTest: true,
                          testType: test.type,
                          selectedCodes: cpt,
                          panelName: test.panelName,
                          labTestID: test.labTestID,
                        });
                        // Swal.fire({
                        //   title:
                        //     'Are you sure you want to update the test ' +
                        //     test.panelName +
                        //     ' ?',
                        //   icon: 'warning',
                        //   showCancelButton: true,
                        //   confirmButtonColor: '#3085d6',
                        //   cancelButtonColor: '#d33',
                        //   confirmButtonText: 'Yes, update it!',
                        // }).then((result) => {
                        //   if (result.isConfirmed) {
                        //     // this.props.deleteLabTest(JSON.stringify(test));
                        //     Swal.fire(
                        //       'Updated!',
                        //       'Your file has been updated.',
                        //       'success'
                        //     );
                        //   }
                        // });
                      }}
                    >
                      Update
                    </Button>
                    {this.props.genFacesheetLoading ? (
                      <CircularProgress id="genFacesheet"></CircularProgress>
                    ) : null}
                  </DataTableCell>
                  <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
                    <Button
                      disabled={this.props.role !== 'Tsalta Admin'}
                      icon="delete"
                      onClick={() => {
                        Swal.fire({
                          title:
                            'Are you sure you want to delete the test ' +
                            test.panelName +
                            ' ?',
                          text: 'You will not be able to revert this!',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, delete it!',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.props.deleteLabTest(JSON.stringify(test));
                            Swal.fire(
                              'Deleted!',
                              'Your file has been deleted.',
                              'success'
                            );
                          }
                        });
                      }}
                    >
                      Delete
                    </Button>
                    {this.props.genFacesheetLoading ? (
                      <CircularProgress id="genFacesheet"></CircularProgress>
                    ) : null}
                  </DataTableCell>
                </DataTableRow>
              );
            }
          });
          testsData.push(testsData2);
        }

        return testsData;
      }
    };
    return (
      <div>
        <div>
          <div id="facility" style={{ display: 'flex' }}>
            <Typography use="subtitle1"> Select a Laboratory: </Typography>
            <div style={{ marginLeft: '20px' }}></div>
            <Select
              onChange={(evt) => {
                this.setState({ selectedLab: evt.target.value });
                let lab = JSON.parse(evt.target.value);
                this.props.getPanTests(lab.labID);
                this.props.labTestsList(lab.labID);
              }}
            >
              {this.props.labs.map((lab) => {
                return (
                  <MenuItem value={JSON.stringify(lab)}>{lab.labName}</MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
        <div id="facilityHead">
          <div id="facility">
            <Typography use="headline4"> Laboratory Tests </Typography>
          </div>
        </div>
        <DataTable id="facilityLoginTable" stickyRows={1} stickyColumns={1}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Panel Name
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Lab Name
                </DataTableHeadCell>

                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Test Type
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  ICD10 Codes
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Actions
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                ></DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>{labTestTable()}</DataTableBody>
          </DataTableContent>
        </DataTable>

        <div>
          <div>
            <Button
              id="addButton"
              variant="contained"
              disabled={this.state.selectedLab === null}
              onClick={() => {
                this.setState({
                  addLabTestDialogOpen: true,
                });
              }}
            >
              Add Test
            </Button>
          </div>
        </div>
        {addLabDialog()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCodes: () => dispatch(getCodesRequest()),
    getLabsRequest: () => dispatch(getLabsRequest()),
    labTestsList: (labID) => dispatch(labTestsList(labID)),
    getPanTests: (labID) => dispatch(getPanTestRequest(labID)),
    addLabTest: (json) => dispatch(addTestRequest(json)),
    updateLabTest: (json) => dispatch(updateTestRequest(json)),

    deleteLabTest: (json) => dispatch(deleteTestRequest(json)),
  };
};
const mapStateToProps = (state) => {
  return {
    codes: state.labMapper.codes,
    labs: state.labs.labs,
    panList: state.metrics.panTestList,
    labTests: state.report.labTests,
    role: state.login.decodedJWT.role,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabOrderManagerComponent);
