import {
  QUERY_RESULTS_SUCCESS,
  QUERY_RESULTS_FAILURE,
  QUERY_RESULTS,
  QUERY_REPORTS_SUCCESS,
  QUERY_OBR_SUCCESS,
  PRINT_REPORTS_SUCCESS,
  ANALYZED_REPORTS_SUCCESS,
  SUBTESTLIST_SUCCESS,
  TESTNAMELIST_SUCCESS,
  PRINT_REPORTS_FAILURE,
  PRINT_REPORTS,
  RESULT_CLICKED,
  RESULT_CLICKED_SUCCESS,
  CLEAR_SEARCH,
} from '../constants/resultConstants';
import { ADMIN_FACILITYLIST_SUCCESS } from '../constants/userConstants';

import {
  PATIENT_REPORT_SUCCESS,
  PATIENT_REPORT_QUERY,
} from '../constants/patientConstants';
import {
  SUBTEST_REPORT_SUCCESS,
  SUBTEST_REPORT_QUERY,
} from '../constants/metricsConstants';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../constants/dialogConstants';

const initialState = {
  results: [],
  reports: [],
  obrResults: [],
  printReports: [],
  facilities: [],
  subtest: [],
  testName: [],
  patientQuery: false,
  resultTotal: 100,
  printLoading: false,
  searchCol: '',
  resultClicked: false,
  searchGenerating: false,
  subtestQuery: false,
};
const resultReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case CLEAR_SEARCH:
      return {
        ...state,
        patientQuery: false,
        reports: [],
        resultTotal: 0,
        patientQueryID: '',
        patientQueryFacID: '',
      };
    case QUERY_RESULTS_SUCCESS:
      return {
        ...state,
        reports: action.patientResults,
        resultTotal: action.count,
        searchGenerating: false,
      };
    case QUERY_RESULTS_FAILURE:
      return {
        ...state,
        searchGenerating: false,
      };
    case QUERY_RESULTS:
      return {
        ...state,
        reports: '',
        searchGenerating: true,
      };
    case QUERY_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.reportResults,
      };
    case QUERY_OBR_SUCCESS:
      return {
        ...state,
        obrResults: action.obrResults,
      };
    case PRINT_REPORTS_SUCCESS:
      return {
        ...state,
        printReports: action.printResults,
        printLoading: false,
      };
    case PRINT_REPORTS_FAILURE:
      return {
        ...state,
        printLoading: false,
      };
    case PRINT_REPORTS:
      return {
        ...state,
        printLoading: true,
      };
    case ADMIN_FACILITYLIST_SUCCESS:
      return {
        ...state,
        facilities: action.facilityList.sort(function (a, b) {
          var nameA = a.facilityName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.facilityName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }),
      };
    case ANALYZED_REPORTS_SUCCESS:
      return {
        ...state,
        analyzedReports: action.analyzedReports,
      };
    case SUBTESTLIST_SUCCESS:
      return {
        ...state,
        subtest: action.subtestList,
      };
    case TESTNAMELIST_SUCCESS:
      return {
        ...state,
        testName: action.testNameList,
      };
    case PATIENT_REPORT_SUCCESS:
      return {
        ...state,
        patientQuery: true,
        reports: action.patientResults,
        resultTotal: action.count,
        patientQueryID: action.patientResults[0].patientID,
        patientQueryFacID: action.patientResults[0].facilityID,
      };
    case PATIENT_REPORT_QUERY:
      return {
        ...state,
        patientQuery: true,
      };
    case SUBTEST_REPORT_SUCCESS:
      return {
        ...state,
        subtestQuery: true,
        reports: action.subtestResults,
        resultTotal: action.count,
      };
    case SUBTEST_REPORT_QUERY:
      return {
        ...state,
        subtestQuery: true,
      };
    // case COUNT_RESULTS_SUCCESS:
    //   return {
    //     ...state,
    //     resultTotal: action.resultTotal,
    //   };
    case RESULT_CLICKED:
      return {
        ...state,
        resultClicked: true,
      };
    case RESULT_CLICKED_SUCCESS:
      return {
        ...state,
        resultClicked: false,
      };
    case OPEN_DIALOG:
      if (action.dialog === 'patientInfo') {
        return {
          ...state,
          patientInfoDialog: true,
        };
      }
      break;
    case CLOSE_DIALOG:
      if (action.dialog === 'patientInfo') {
        return {
          ...state,
          patientInfoDialog: false,
        };
      }
      break;
    default:
      return state;
  }
};
export default resultReducer;
