import {
  FACILITY_REPORT,
  FACILITY_REPORT_SUCCESS,
  FACILITY_REPORT_FAILURE,
  PATIENT_REPORT,
  PATIENT_REPORT_SUCCESS,
  PATIENT_REPORT_FAILURE,
  LOAD_REPORTS_SUCCESS,
  UPLOAD_LAB,
  UPLOAD_LAB_SUCCESS,
  UPLOAD_LAB_FAIL,
  FILE_DIALOG_CONTROL,
  LAB_REQUEST_SUCCESS,
  QUARTERLY_REPORT_SUCCESS,
  QUARTERLY_REPORT,
  QUARTERLY_REPORT_FAILURE,
  LAB_REQUEST_FAILURE,
  LAB_REQUEST,
  PATIENT_REQUEST_SUCCESS,
  PATIENT_REQUEST_FAILURE,
  PATIENT_REQUEST,
  FACESHEET_REQUEST_FAIL,
  FACESHEET_REQUEST_SUCCESS,
  FACESHEET_REQUEST,
  PATIENT_NAME_QUERY_SUCCESS,
  LAB_TESTS_QUERY_SUCCESS,
} from '../constants/reportConstants';

import { ADMIN_FACILITYLIST_SUCCESS } from '../constants/userConstants';

const initialState = {
  reports: [],
  labUploading: false,
  labUploadSuccessful: null,
  labSuccessDialogOpen: false,
  facilities: [],
  facilityReportLoading: false,
  patientReportLoading: false,
  quarterlyLoading: false,
  labReqLoading: false,
  patientReqLoading: false,
  facesheet: [],
  genFacesheetLoading: false,
  reportGenerating: false,
  patients: [],
  labTests: new Map(),
};
const reportReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case FACILITY_REPORT_SUCCESS:
      return {
        ...state,
        reportGenerating: false,
      };
    case FACILITY_REPORT_FAILURE:
      return {
        ...state,
        reportGenerating: false,
      };
    case FACILITY_REPORT:
      return {
        ...state,
        reportGenerating: true,
      };

    case PATIENT_REPORT_SUCCESS:
      return {
        ...state,
        reportGenerating: false,
      };
    case PATIENT_REPORT_FAILURE:
      return {
        ...state,
        reportGenerating: false,
      };
    case PATIENT_REPORT:
      return {
        ...state,
        reportGenerating: true,
      };

    case QUARTERLY_REPORT_SUCCESS:
      return {
        ...state,
        reportGenerating: false,
      };
    case QUARTERLY_REPORT:
      return {
        ...state,
        reportGenerating: true,
      };
    case QUARTERLY_REPORT_FAILURE:
      return {
        ...state,
        reportGenerating: false,
      };
    case LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.reportResults,
      };
    case UPLOAD_LAB:
      return {
        ...state,
        labUploading: true,
        labUploadSuccessful: null,
      };
    case UPLOAD_LAB_SUCCESS:
      return {
        ...state,
        labUploading: false,
        // labUploadSuccessful: true,
        labSuccessDialogOpen: true,
      };
    case UPLOAD_LAB_FAIL:
      return {
        ...state,
        labUploading: false,
        labUploadSuccessful: false,
      };
    case FILE_DIALOG_CONTROL:
      return {
        ...state,
        labSuccessDialogOpen: action.fileDialogOpen,
      };
    case ADMIN_FACILITYLIST_SUCCESS:
      return {
        ...state,
        facilities: action.facilityList,
      };
    case LAB_REQUEST:
      return {
        ...state,
        reportGenerating: true,
      };
    case LAB_REQUEST_FAILURE:
      return {
        ...state,
        reportGenerating: false,
      };
    case LAB_REQUEST_SUCCESS:
      return {
        ...state,
        reportGenerating: false,
      };
    case PATIENT_REQUEST:
      return {
        ...state,
        reportGenerating: true,
      };
    case PATIENT_REQUEST_FAILURE:
      return {
        ...state,
        reportGenerating: false,
      };
    case PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        reportGenerating: false,
      };
    case FACESHEET_REQUEST_SUCCESS:
      return {
        ...state,
        facesheet: action.facesheet,
        genFacesheetLoading: false,
      };
    case FACESHEET_REQUEST_FAIL:
      return {
        ...state,
        genFacesheetLoading: false,
      };
    case FACESHEET_REQUEST:
      return {
        ...state,
        genFacesheetLoading: true,
      };
    case PATIENT_NAME_QUERY_SUCCESS:
      return {
        ...state,
        patients: action.patientResults,
      };
    case LAB_TESTS_QUERY_SUCCESS:
      let labMap = new Map();
      action.labTests.forEach((test) => {
        let category = test.type;
        if (labMap.has(category)) {
          let testArray = labMap.get(category);
          testArray.push(test);
          labMap.set(category, testArray);
        } else {
          let testArray = [];
          testArray.push(test);
          labMap.set(category, testArray);
        }
      });
      return {
        ...state,
        labTests: labMap,
      };
    default:
      return state;
  }
};
export default reportReducer;
