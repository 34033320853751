export const QUERY_RESULTS = 'QUERY_RESULTS';
export const QUERY_RESULTS_SUCCESS = 'QUERY_RESULTS_SUCCESS';
export const QUERY_RESULTS_FAILURE = 'QUERY_RESULTS_FAILURE';
export const QUERY_REPORTS_SUCCESS = 'QUERY_REPORTS_SUCCESS';
export const QUERY_REPORTS_FAILURE = 'QUERY_REPORTS_FAILURE';
export const QUERY_REPORT = 'QUERY_REPORT';
export const QUERY_OBR = 'QUERY_OBR';
export const QUERY_OBR_SUCCESS = 'QUERY_OBR_SUCCESS';
export const QUERY_OBR_FAILURE = 'QUERY_OBR_FAILURE';
export const PRINT_REPORTS = 'PRINT_REPORTS';
export const PRINT_REPORTS_SUCCESS = 'PRINT_REPORTS_SUCCESS';
export const PRINT_REPORTS_FAILURE = 'PRINT_REPORTS_FAILURE';
export const ANALYZED_REPORTS_SUCCESS = 'ANALYZED_REPORTS_SUCCESS';
export const ANALYZED_REPORTS_FAILURE = 'ANALYZED_REPORTS_FAILURE';
export const PANTESTLIST_SUCCESS = 'PANTESTLIST_SUCCESS';
export const PANTESTLIST_FAILURE = 'PANTESTLIST_FAILURE';
export const SUBTESTLIST_SUCCESS = 'SUBTESTLIST_SUCCESS';
export const SUBTESTLIST_FAILURE = 'SUBTESTLIST_FAILURE';
export const TESTNAMELIST_SUCCESS = 'TESTNAMELIST_SUCCESS';
export const TESTNAMELIST_FAILURE = 'TESTNAMELIST_FAILURE';
export const RESULT_CLICKED = 'RESULT_CLICKED';
export const RESULT_CLICKED_SUCCESS = 'RESULT_CLICKED_SUCCESS';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
