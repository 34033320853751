import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/metricsComponent.css';
import { Typography } from '@rmwc/typography';
import { getFacilityRequest } from '../actions/userActions';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { TextField } from '@rmwc/textfield';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import {
  clearMetrics,
  getMetricsRequest,
  printMetrics,
  getGroupsRequest,
  trendPopup,
} from '../actions/metricsActions';
import { Select } from '@rmwc/select';
import { Radio, RadioGroup } from '@material-ui/core';
import { List, ListItem } from '@rmwc/list';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';
import Grid from '@material-ui/core/Grid';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import {
  getSubtestRequest,
  getPanTestRequest,
} from '../actions/resultsActions';
import randomColor from 'randomcolor';
import Swal from 'sweetalert2';
import { patientNameList } from '../actions/reportActions';
import { GridCell, GridRow } from '@rmwc/grid';

//This component was switched with the TrendsDashboardComponent. The UI feature for the Trends graph code is changed here.
class MetricsComponent extends Component {
  constructor() {
    super();
    this.state = {
      metricStartDate: '',
      metricEndDate: '',
      facilities: [],
      patients: [],
      testList: [],
      searchModalOpen: false,
      checked: [],
      testChecked: [],
      panChecked: [],
      data: [],
      selectAll: false,
      rv: 'day range',
      searchBy: '',
      testType: 'subtest',
      groups: [],
      popupDialog: '',
      searchTerms: '',
      fac: '',
      popup: '',
      pointDate: '',
      checkAll: false,
    };
    this.clearChecks = this.clearChecks.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let patientList = [];
    props.patients.forEach((patient) => {
      var patientDOB = patient.patientDOB;
      var epoch = parseInt(patientDOB, 10);
      var hrDate = new Date(epoch);
      var month = hrDate.getMonth() + 1;
      patientList.push(patient);
    });
    let facilityList = [];
    props.facilities.forEach((fac) => {
      facilityList.push(fac);
    });
    let groupList = [];
    props.groups.forEach((group) => {
      groupList.push(group);
    });
    if (
      props.data &&
      state.data &&
      JSON.stringify(props.data.sort()) !== JSON.stringify(state.data.sort())
    ) {
      return {
        ...state,
        facilities: props.facilities,
        testList: props.testList,
        data: props.data,
        newData: true,
        patients: props.patients,
        patientList: patientList,
        facilityList: facilityList,
        groups: props.groups,
        groupList: groupList,
        searching: props.searching,
        popup: props.popup,
      };
    } else {
      return {
        ...state,
        facilities: props.facilities,
        testList: props.testList,
        newData: false,
        patients: props.patients,
        patientList: patientList,
        facilityList: facilityList,
        groups: props.groups,
        groupList: groupList,
        searching: props.searching,
        popup: props.popup,
      };
    }
  }

  componentDidMount() {
    this.setState({ searchModalOpen: true });
    this.props.getFacilities();
    this.props.patientNameList();
    this.props.getSubtests();
    this.props.getPanTests();
    this.props.getGroupsRequest();
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }
  componentDidUpdate() {
    if (this.state.newData) {
      this.state.facilities.forEach((facility) => {
        let randColor = randomColor({
          seed: facility.facilityID,
          luminosity: 'dark',
        });

        this.setState({ [facility.facilityID]: randColor });
      });
    }
  }

  collapse = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleClick() {
    this.setState({
      popupDialog: true,
    });
  }

  search = () => {
    let body = {};

    let startDate;
    let endDate;
    let date2;
    let date;
    let tests;
    if (this.state.checkAll){
      tests = []
    }
    else{
      tests = this.state.testChecked
    }

    if (this.state.rv === 'month range' || this.state.rv === 'day range') {
      date = new Date(Date.parse(this.state.trendStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(Date.parse(this.state.trendEndDate));
      endDate = date2.toISOString().slice(0, 10);
    } else if (this.state.rv === 'month') {
      date = new Date(Date.parse(this.state.trendStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(date.toUTCString());
      date2.setUTCMonth(date2.getUTCMonth() + 1);
      endDate = date2.toISOString().slice(0, 10);
    } else if (this.state.rv === 'day') {
      date = new Date(Date.parse(this.state.trendStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(date.toUTCString());
      date2.setUTCMonth(date2.getUTCMonth() + 1);
      endDate = date.toISOString().slice(0, 10);
    }
    body = {
      dateFrom: startDate,
      dateTo: endDate,
      facilities: [JSON.parse(this.state.checked)],
      tests: tests,
      searchType: this.state.searchBy,
      testType: 'subtest',
      span: this.state.rv,
    };
    console.log(body)
    this.props.getMetrics(body);
    this.setState({ searchModalOpen: false, fac: this.state.checked, checkAll: false});
  };

  saveCanvas() {
    const canvasSave = document.getElementById('graph');
    const d = canvasSave.toDataURL('image/png');
    this.setState({
      graphImage: d.replace(/^data:image\/(png|jpg);base64,/, ''),
    });
    return d.replace(/^data:image\/(png|jpg);base64,/, '');
  }
  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  selectAll = (e) => {
    if (this.state.testType === 'subtest') {
      this.props.testList.map((test) => {
        if (test.testName !== null) {
          this.setState({ [test.testName]: true });
          let testChecked = this.state.testChecked;
          if (!this.state[test.testName]) {
            testChecked.push(test);
          } else {
            testChecked = testChecked.filter(
              (check) => check.testName !== test.testName
            );
          }
          this.setState({
            [test.testName]: !this.state[test.testName],
            testChecked: testChecked,
          });
        }
        this.setState({ selectAll: !this.state.selectAll });
      });
    } else if (this.state.testType === 'panel') {
      this.props.panList.map((test) => {
        if (test.panelName !== null) {
          this.setState({ [test.panelName]: true });
          let testChecked = this.state.panChecked;
          if (!this.state[test.panelName]) {
            testChecked.push(test);
          } else {
            testChecked = testChecked.filter(
              (check) => check.panelName !== test.panelName
            );
          }
          this.setState({
            [test.panelName]: !this.state[test.panelName],
            testChecked: testChecked,
          });
        }
        this.setState({ selectAll: !this.state.selectAll });
      });
    }
    this.setState({checkAll: !this.state.checkAll})
  };
  clearChecks = () => {
    Object.keys(this.state).map((key) => {
      let regex = new RegExp('checkd*');
      this.setState({ orderList: [] });
      if (!regex.test(key)) {
        this.setState({ [key]: false });
      }
      return null;
    });
  };

  render() {
    const states = [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ];
    let labels = new Set();
    let datasets = [];
    let name = '';

    if (this.state.data.length > 0) {
      this.state.data.map((facData) => {
        var randColor = randomColor({
          seed: facData.facilityID,
          luminosity: 'dark',
        });
        let jsonTotal = {};

        if (facData.searchType === 'facility') {
          name = facData.facilityName;
        } else if (facData.searchType === 'patient') {
          name = facData.patientName;
        } else if (facData.searchType === 'group') {
          name = facData.groupName;
        } else if (facData.searchType === 'state') {
          name = 'facilities in ' + facData.stateName;
        }
        jsonTotal.label = 'Total tests for ' + name;
        jsonTotal.id = 'total';
        if (this.state[facData.facilityID]) {
          jsonTotal.borderColor = 'black';
          jsonTotal.backgroundColor = 'black';
        } else {
          jsonTotal.borderColor = 'black';
          jsonTotal.backgroundColor = 'black';
        }

        let yearTotalData = [];

        let jsonAbnormal = {};

        jsonAbnormal.label = 'Total Abnormal tests for ' + name;
        jsonAbnormal.id = 'abnormal';
        if (this.state[facData.facilityID]) {
          jsonAbnormal.borderColor = 'orange';
          jsonAbnormal.backgroundColor = 'orange';
        } else {
          jsonAbnormal.borderColor = 'orange';
          jsonAbnormal.backgroundColor = 'orange';
        }
        jsonAbnormal.borderDash = [10, 5];
        let yearAbnormalData = [];
        let yearAbnormalPer = [];

        let jsonCritical = {};
        jsonCritical.label = 'Total Critical tests for ' + name;
        jsonCritical.id = 'critical';
        if (this.state[facData.facilityID]) {
          jsonCritical.borderColor = 'red';
          jsonCritical.backgroundColor = 'red';
        } else {
          jsonCritical.borderColor = 'red';
          jsonCritical.backgroundColor = 'red';
        }
        jsonCritical.borderDash = [10, 5];
        let yearCriticalData = [];
        // let yearCriticalPer = [];

        let years = Object.keys(facData.facilityData);
        years.map((year) => {
          let months = Object.keys(facData.facilityData[year][0]);
          months.map((month) => {
            let normalTest = facData.facilityData[year][0][month].totalTests;
            let abnormalTest =
              facData.facilityData[year][0][month].totalAbnormalTests;
            let criticalTest =
              facData.facilityData[year][0][month].totalCriticalTests;
            if (this.state.rv === 'month range') {
              labels.add(month + ' ' + year);
              yearTotalData.push(normalTest);

              yearAbnormalData.push(abnormalTest);
              yearCriticalData.push(criticalTest);
              if (normalTest === 0) {
                yearAbnormalPer.push(0);
              } else {
                let per = (abnormalTest / normalTest) * 100;
                yearAbnormalPer.push(per.toFixed(2));
              }
            } else if (
              this.state.rv === 'day range' ||
              this.state.rv === 'month'
            ) {
              let date = new Date(Date.parse(month));
              var nextDay = new Date(date);
              nextDay.setDate(date.getDate() + 1);
              labels.add(
                nextDay.toLocaleString('en-US', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                })
              );
              yearTotalData.push(
                facData.facilityData[year][0][month].totalTests
              );
              yearAbnormalData.push(
                facData.facilityData[year][0][month].totalAbnormalTests
              );
              if (facData.facilityData[year][0][month].totalTests === 0) {
                yearAbnormalPer.push(0);
              } else {
                let per = (abnormalTest / normalTest) * 100;
                yearAbnormalPer.push(per.toFixed(2));
              }
              yearCriticalData.push(
                facData.facilityData[year][0][month].totalCriticalTests
              );
            }
          });
        });

        jsonTotal.data = yearTotalData;
        jsonAbnormal.data = yearAbnormalData;
        jsonAbnormal.percentage = yearAbnormalPer;
        jsonCritical.data = yearCriticalData;

        datasets.push(jsonTotal);
        datasets.push(jsonAbnormal);
        datasets.push(jsonCritical);
      });
      labels = Array.from(labels);
    } else {
      labels = Array.from(labels);
    }
    const options = {
      onClick: (evt, tooltipItem) => {
        if (tooltipItem[0] !== undefined && this.state.searchBy === 'Group') {
          let pointIndex = tooltipItem[0].index;
          let startPoint = null;

          if (this.state.rv === 'month range') {
            const date = new Date(labels[pointIndex]);
            const convertedDate = date
              .toLocaleDateString('en-IN')
              .split('/')
              .reverse()
              .join('-');
            startPoint = convertedDate;
            let endPoint = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            let searchTerms = {
              dateFrom: startPoint,
              dateTo: endPoint,
              facilities: [JSON.parse(this.state.fac)],
              tests: this.state.testChecked,
              searchType: this.state.searchBy,
              testType: 'subtest',
              span: this.state.rv,
            };
            this.props.getPopup(searchTerms, '');
          } else {
            const date = new Date(labels[pointIndex]);
            const convertedDate = date
              .toLocaleDateString('en-IN')
              .split('/')
              .reverse()
              .join('-');
            startPoint = convertedDate;
            let endPoint = convertedDate;
            let searchTerms = {
              dateFrom: startPoint,
              dateTo: endPoint,
              facilities: [JSON.parse(this.state.fac)],
              tests: this.state.testChecked,
              searchType: this.state.searchBy,
              testType: 'subtest',
              span: this.state.rv,
            };
            this.props.getPopup(searchTerms, '');
          }

          var d = new Date(startPoint);
          let temp =
            d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear();

          this.setState({
            popupDialog: true,
            pointDate: temp,
          });
        }
      },
      responsive: true,
      position: 'relitive',
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            // This more specific font property overrides the global property
            font: {
              size: 20,
            },
          },
        },
        title: {
          display: true,
          text: 'Trends Analysis',
          font: {
            size: 30,
          },
        },
        tooltip: {
          bodyFont: {
            size: 20,
          },

          callbacks: {
            label: function (tooltipItem, data) {
              if (tooltipItem.dataset.id === 'total') {
                var label =
                  'Total tests for ' +
                  name +
                  ' : ' +
                  tooltipItem.dataset.data[tooltipItem.dataIndex];
                return label;
              } else if (tooltipItem.dataset.id === 'abnormal') {
                var label =
                  'Abnormal tests for ' +
                  name +
                  ' : ' +
                  tooltipItem.dataset.data[tooltipItem.dataIndex];
                var percentage =
                  'Percentage of Abnormal tests for ' +
                  name +
                  ' : ' +
                  tooltipItem.dataset.percentage[tooltipItem.dataIndex] +
                  '%';
                return [label, percentage];
              } else if (tooltipItem.dataset.id === 'critical') {
                var label =
                  'Total Critical tests for ' +
                  name +
                  ' : ' +
                  tooltipItem.dataset.data[tooltipItem.dataIndex];
                return label;
              }
            },
          },
        },
      },
    };

    const data = {
      labels,
      datasets: datasets,
    };
    let searchingModal = () => {
      return (
        <div
          id="searchingDialog"
          style={{ paddingLeft: '44%', paddingTop: '5%' }}
        >
          <CircularProgress size={100}></CircularProgress>
        </div>
      );
    };

    let popupDialog = () => {
      return (
        <div id="popup">
          <Dialog
            id="popupDialog"
            open={this.state.popupDialog}
            onClose={(evt) => {
              this.setState({ popupDialog: false });
            }}
          >
            <DialogTitle
              id="reportTitle"
              style={{
                borderTop: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              {'Group Breakdown By Facility'}
            </DialogTitle>

            <DialogContent
              style={{
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              <GridRow>
                {this.state.rv === 'month range' ? (
                  <GridCell
                    style={{ left: '400px', position: 'relative', top: '10px' }}
                  >
                    {this.state.pointDate}
                  </GridCell>
                ) : (
                  <GridCell
                    style={{ left: '400px', position: 'relative', top: '10px' }}
                  >
                    {this.state.pointDate}
                  </GridCell>
                )}
              </GridRow>

              {this.state.popup !== null ? (
                Object.keys(this.state.popup).map((key) => {
                  console.log(key);
                  let fac = key;
                  let html = [];
                  let a = '';
                  let n = '';
                  html.push(
                    <div>
                      <hr style={{ height: '40px' }}></hr>

                      <GridRow>
                        <GridCell
                          style={{
                            position: 'relative',
                            left: '390px',
                            top: '-20px',
                          }}
                        >
                          {fac}
                        </GridCell>
                      </GridRow>
                      {/* <hr style={{ height:'50px' }}></hr> */}
                    </div>
                  );
                  Object.keys(this.state.popup[key]).map((test) => {
                    if (test === 'totalAbnormalTests') {
                      a = this.state.popup[key][test];
                    }
                    if (test === 'totalNormalTests') {
                      n = this.state.popup[key][test];
                    }
                  });
                  html.push(
                    <GridRow>
                      {/* <GridCell>{fac}</GridCell> */}
                      <GridRow>
                        <GridCell
                          style={{
                            position: 'relative',
                            left: '280px',
                            width: '450px',
                          }}
                        >
                          {'Total Abnormal Tests: ' + a}
                        </GridCell>
                        <GridCell
                          style={{
                            position: 'relative',
                            left: '420px',
                            width: '450px',
                          }}
                        >
                          {'Total Normal Tests: ' + n}
                        </GridCell>
                      </GridRow>
                    </GridRow>
                  );
                  return html;
                })
              ) : (
                <Typography>No results </Typography>
              )}
            </DialogContent>
            <DialogActions
              style={{
                borderBottom: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                color: 'dodgerblue',
              }}
            >
              <DialogButton
                icon="close"
                cancel
                outlined
                onClick={() => {
                  this.setState({
                    popupDialog: false,
                  });
                }}
              >
                Close
              </DialogButton>
            </DialogActions>
          </Dialog>
        </div>
      );
    };

    let searchModal = () => {
      return (
        <Dialog
          id="metricSearchDialog"
          open={this.state.searchModalOpen}
          onOpen={() => {
            // this.props.clearMetrics();
          }}
          onClose={() => {
            this.setState({ searchModalOpen: false, name: '', checked: [] });
          }}
          maxwidth="md"
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Trends Analysis
          </DialogTitle>
          <DialogContent
            id="trendsDialogContent"
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
              width: '100%',
            }}
          >
            <Grid
              container
              spacing={4}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                {/* <Grid style={{ top: '105px', position: 'absolute', left: '50px' }}> */}
                <Typography>Select trend type:</Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  id="searchBy"
                  label="Search by:"
                  outlined
                  options={['Facility', 'Group', 'Patient', 'State']}
                  value={this.state.searchBy}
                  onChange={(event) => {
                    let value = event.currentTarget.value;
                    this.setState({}, () => {
                      this.setState({
                        searchBy: value,
                        checked: [],
                        trends: [],
                      });
                    });
                  }}
                ></Select>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                {/* <Grid style={{ top: '175px', position: 'absolute', left: '50px' }}> */}
                {this.state.searchBy === 'Patient' ? (
                  <Typography>Select Patient Name:</Typography>
                ) : this.state.searchBy === 'Group' ? (
                  <Typography>Select Group Name:</Typography>
                ) : this.state.searchBy === 'State' ? (
                  <Typography>Select State Name:</Typography>
                ) : (
                  <Typography>Select Facility Name:</Typography>
                )}
              </Grid>
              <Grid item xs={7}>
                {this.state.searchBy === 'Facility' ? (
                  <Select
                    id="facilitySelect"
                    label="Facility"
                    outlined
                    native
                    displayEmpty
                    value={this.state.name}
                    onChange={(event) => {
                      this.setState({
                        checked: event.currentTarget.value,
                        name: event.currentTarget.name,
                      });
                    }}
                    // labelId="Facility"
                    // inputProps={{
                    //   name: 'checked',
                    // }}
                  >
                    {this.state.facilityList.map((state) => (
                      <option
                        name={state.facilityName}
                        value={JSON.stringify(state)}
                      >
                        {state.facilityName}
                      </option>
                    ))}
                  </Select>
                ) : this.state.searchBy === 'Patient' ? (
                  <Select
                    id="patientSelect"
                    label="Patient"
                    outlined
                    native
                    displayEmpty
                    value={this.state.name}
                    onChange={(event) => {
                      this.setState({
                        checked: event.currentTarget.value,
                        name: event.currentTarget.name,
                      });
                    }}
                    // labelId="Patient"
                    // inputProps={{
                    //   name: 'checked',
                    // }}
                  >
                    {this.state.patientList.map((state) => (
                      <option
                        name={state.patientID}
                        value={JSON.stringify(state)}
                      >
                        {state.patientLastName + ', ' + state.patientFirstName}
                      </option>
                    ))}
                  </Select>
                ) : this.state.searchBy === 'Group' ? (
                  <Select
                    style={{
                      width: '100%',
                    }}
                    id="groupSelect"
                    label="Group"
                    outlined
                    native
                    displayEmpty
                    value={this.state.name}
                    onChange={(event) => {
                      this.setState({
                        checked: event.currentTarget.value,
                        name: event.currentTarget.name,
                      });
                    }}
                    // labelId="Group"
                    // inputProps={{
                    //   name: 'checked',
                    // }}
                  >
                    {this.state.groupList.map((state) => (
                      <option
                        name={state.groupID}
                        value={JSON.stringify(state)}
                      >
                        {state.groupName}
                      </option>
                    ))}
                  </Select>
                ) : this.state.searchBy === 'State' ? (
                  <Select
                    style={{
                      width: '100%',
                    }}
                    id="stateSelect"
                    label="State"
                    outlined
                    native
                    displayEmpty
                    value={this.state.name}
                    onChange={(event) => {
                      this.setState({
                        checked: event.currentTarget.value,
                        name: event.currentTarget.name,
                      });
                    }}
                    // labelId="State"
                    // inputProps={{
                    //   name: 'checked',
                    // }}
                  >
                    {states.map((state) => (
                      <option name={state} value={JSON.stringify(state)}>
                        {state}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    style={{ width: '100%' }}
                    label="Select a search type"
                    outlined
                    // native
                    // displayEmpty
                  ></Select>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={5}
              alignContent="center"
              justifyContent="center"

              // style={{ width:'100%'}}
            >
              <Grid item xs={5}>
                <Typography style={{ top: '20px', left: '0px' }}>
                  Select Date Search:{' '}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ left: '-40px', position: 'relative' }}>
                <RadioGroup
                  value={this.state.rv}
                  name="rv"
                  onChange={this.handleChange}
                  row
                  style={{ left: '-120px', position: 'relative' }}
                >
                  <FormControlLabel
                    value="day range"
                    control={<Radio color="primary" />}
                    label="Day Range"
                  />
                  <FormControlLabel
                    value="month range"
                    control={<Radio color="primary" />}
                    label="Month Range"
                  />
                  <FormControlLabel
                    value="month"
                    control={<Radio color="primary" />}
                    label="Month"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={4}>
                {' '}
                {this.state.rv === 'month range' ? (
                  <div id="trendStartDate">
                    <Typography style={{ left: '320px', position: 'relative' }}>
                      Start Month
                    </Typography>
                    <form noValidate>
                      <TextField
                        style={{ width: '70%', left: '320px' }}
                        outlined
                        id="trendStartDateCalender"
                        name="trendStartDate"
                        type="month"
                        value={this.state.trendStartDate}
                        onChange={this.handleChange}
                      />
                    </form>
                  </div>
                ) : this.state.rv === 'day range' ? (
                  <div id="trendStartDate">
                    <Typography style={{ left: '320px', position: 'relative' }}>
                      Start Day
                    </Typography>
                    <form noValidate>
                      <TextField
                        style={{ width: '70%', left: '320px' }}
                        outlined
                        id="trendStartDateCalender"
                        name="trendStartDate"
                        type="date"
                        value={this.state.trendStartDate}
                        onChange={this.handleChange}
                      />
                    </form>
                  </div>
                ) : this.state.rv === 'month' ? (
                  <div id="trendStartDate">
                    <Typography style={{ left: '320px', position: 'relative' }}>
                      Month
                    </Typography>
                    <form noValidate>
                      <TextField
                        style={{ width: '70%', left: '320px' }}
                        outlined
                        id="trendStartDateCalender"
                        name="trendStartDate"
                        type="month"
                        value={this.state.trendStartDate}
                        onChange={this.handleChange}
                      />
                    </form>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={4}>
                {this.state.rv === 'month range' ? (
                  <div id="trendEndDate">
                    <Typography style={{ left: '230px', position: 'relative' }}>
                      End Month
                    </Typography>
                    <form noValidate>
                      <TextField
                        style={{ width: '70%', left: '230px' }}
                        outlined
                        id="trendEndDateCalender"
                        name="trendEndDate"
                        type="month"
                        value={this.state.trendEndDate}
                        onChange={this.handleChange}
                      />
                    </form>
                  </div>
                ) : this.state.rv === 'day range' ? (
                  <div id="trendEndDate">
                    <Typography style={{ left: '230px', position: 'relative' }}>
                      End Day
                    </Typography>
                    <form noValidate>
                      <TextField
                        style={{ width: '70%', left: '230px' }}
                        outlined
                        id="trendEndDateCalender"
                        name="trendEndDate"
                        type="date"
                        value={this.state.trendEndDate}
                        onChange={this.handleChange}
                      />
                    </form>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <div style={{ height: '50px' }}> </div>
              </Grid>
              <Grid container justifyContent="center" alignContent="center">
                <Grid
                  item
                  xs={9}
                  style={{
                    borderStyle: 'solid',
                    left: '0%',
                    position: 'relative',
                  }}
                >
                  {this.state.testType === 'panel' ? (
                    <Typography>Panels</Typography>
                  ) : (
                    <Typography>Tests</Typography>
                  )}
                  <List
                    id="testList"
                    dense={true}
                    style={{
                      height: '180px',
                      position: 'reletive',
                      overflow: 'auto',
                      width: '100%',
                      top: '20px',
                    }}
                  >
                    {this.state.testType === 'subtest' ? (
                      <ListItem style={{ color: 'black', width: '100%' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.selectAll}
                              onClick={(e) => this.selectAll(e)}
                              color="primary"
                            />
                          }
                          label="Check All"
                        />
                      </ListItem>
                    ) : null}

                    {this.props.testList.length > 0 &&
                    this.state.testType === 'subtest'
                      ? this.props.testList.map((test) => {
                          if (test.testName !== null) {
                            return (
                              <ListItem
                                key={test.testName}
                                style={{
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                  color: 'black',
                                  width: '100%',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state[test.testName]
                                          ? this.state[test.testName]
                                          : false
                                      }
                                      onClick={() => {
                                        let testChecked =
                                          this.state.testChecked;
                                        if (!this.state[test.testName]) {
                                          testChecked.push(test);
                                        } else {
                                          testChecked = testChecked.filter(
                                            (check) =>
                                              check.testName !== test.testName
                                          );
                                        }
                                        this.setState({
                                          [test.testName]:
                                            !this.state[test.testName],
                                          testChecked: testChecked,
                                        });
                                      }}
                                      name={test.testName}
                                      color="primary"
                                    />
                                  }
                                  label={test.testName}
                                />
                              </ListItem>
                            );
                          }
                        })
                      : null}
                    {this.props.testList.length > 0 &&
                    this.state.testType === 'panel'
                      ? this.props.panList.map((test) => {
                          if (test.testName !== null) {
                            let testCHK = 'checkd' + test.panelName;

                            return (
                              <ListItem
                                style={{
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                  color: 'black',
                                  width: '100%',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={
                                        this.state.testChecked.length !== 0 &&
                                        !this.state[testCHK]
                                      }
                                      checked={
                                        this.state[testCHK]
                                          ? this.state[testCHK]
                                          : false
                                      }
                                      onClick={() => {
                                        let testChecked =
                                          this.state.testChecked;
                                        if (!this.state[testCHK]) {
                                          testChecked.push(test);
                                        } else {
                                          testChecked = testChecked.filter(
                                            (check) =>
                                              check.panelName !== test.panelName
                                          );
                                        }
                                        this.setState({
                                          [test.panelName]:
                                            !this.state[test.panelName],
                                          [testCHK]: !this.state[testCHK],

                                          testChecked: testChecked,
                                        });
                                      }}
                                      name={test.panelName}
                                      color="primary"
                                    />
                                  }
                                  label={test.panelName}
                                />
                              </ListItem>
                            );
                          }
                        })
                      : null}

                    {this.props.panList.length <= 0 &&
                    this.state.testType === 'panel' ? (
                      <Typography variant="h2">No Panels</Typography>
                    ) : null}
                    {this.props.testList.length <= 0 &&
                    this.state.testType === 'subtest' ? (
                      <Typography variant="h2">No Tests</Typography>
                    ) : null}
                  </List>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignContent="center">
                <Grid item xs={3}>
                  <Button
                    id="createMetricsButton"
                    variant="contained"
                    onClick={() => {
                      if (
                        this.state.rv === 'month range' &&
                        (this.isEmpty(this.state.trendStartDate) ||
                          this.isEmpty(this.state.trendEndDate) ||
                          this.isEmpty(this.state.checked) ||
                          this.isEmpty(this.state.testChecked) ||
                          this.isEmpty(this.state.searchBy))
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all fields.',
                        });
                      } else if (
                        this.state.rv === 'day range' &&
                        (this.isEmpty(this.state.trendStartDate) ||
                          this.isEmpty(this.state.checked) ||
                          this.isEmpty(this.state.testChecked) ||
                          this.isEmpty(this.state.searchBy))
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all fields.',
                        });
                      } else {
                        this.search();
                        if (this.state.rv === 'month range') {
                          this.setState({
                            addUserDialogOpen: false,
                            editingUser: false,
                            sliceStart:
                              new Date(this.state.trendStartDate)
                                .toISOString()
                                .slice(5, 7) +
                              '-' +
                              new Date(this.state.trendStartDate)
                                .toISOString()
                                .slice(0, 4),
                            sliceEnd:
                              new Date(this.state.trendEndDate)
                                .toISOString()
                                .slice(5, 7) +
                              '-' +
                              new Date(this.state.trendEndDate)
                                .toISOString()
                                .slice(0, 4),
                          });
                        } else {
                          this.setState({
                            addUserDialogOpen: false,
                            editingUser: false,
                            checked: [],
                            sliceStart:
                              new Date(this.state.trendStartDate)
                                .toISOString()
                                .slice(5, 7) +
                              '-' +
                              new Date(this.state.trendStartDate)
                                .toISOString()
                                .slice(0, 4),
                            sliceEnd: '',
                          });
                        }
                      }
                    }}
                  >
                    Generate Trends
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              icon="close"
              // cancel
              outlined
              onClick={() => {
                this.setState({ searchModalOpen: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          maxheight: '100%',
          maxwidth: '100%',
          display: 'flex',
          position: 'reletive',
          overflow: 'hidden',
        }}
      >
        {this.state.searching === true ? searchingModal() : null}
        <div style={{ zIndex: 100 }}></div>
        {searchModal()}
        {popupDialog()}
        <div
          style={{
            height: '90%',
            position: 'absolute',
            width: '95%',
            left: '2%',
          }}
          id="canvas"
        >
          <Line
            id="graph"
            options={options}
            data={data}
            style={{ height: '100%' }}
          ></Line>
        </div>
        <div>
          <div>
            <Button
              id="addButton"
              icon={<SearchIcon />}
              label="Search Trends"
              onClick={() => {
                this.clearChecks();
                this.setState({
                  searchModalOpen: !this.state.searchModalOpen,
                  data: [],
                  searchBy: '',
                  testChecked: [],
                  metricStartDate: [],
                  metricEndDate: [],
                  checked: [],
                  rv: 'day range',
                  testType: 'subtest',
                });
              }}
            >
              Search Trends
            </Button>
          </div>
          <div>
            <Button
              id="mergeButton"
              label="Create PDF Report"
              onClick={() => {
                if (this.isEmpty(this.state.data)) {
                  Swal.fire({
                    icon: 'warning',
                    text: 'Please create a trends search',
                  });
                } else {
                  let json = { data: this.state.data, img: this.saveCanvas() };
                  this.props.printMetrics(json);
                }
              }}
            >
              Create PDF Report
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  facilities: state.metrics.facilities,
  data: state.metrics.data,
  testList: state.metrics.testList,
  panList: state.metrics.panTestList,
  patients: state.report.patients,
  groups: state.metrics.groups,
  searching: state.metrics.searching,
  popup: state.metrics.popup,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getFacilities: () => dispatch(getFacilityRequest()),
    getSubtests: () => dispatch(getSubtestRequest()),
    getPanTests: () => dispatch(getPanTestRequest('')),
    getMetrics: (body) => dispatch(getMetricsRequest(body)),
    clearMetrics: () => dispatch(clearMetrics()),
    printMetrics: (body) => dispatch(printMetrics(body)),
    patientNameList: () => dispatch(patientNameList()),
    getGroupsRequest: () => dispatch(getGroupsRequest()),
    getPopup: (body, test) => dispatch(trendPopup(body, test)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricsComponent);
