import {
  QUERY_PATIENTS_SUCCESS,
  QUERY_LAB_SUCCESS,
  PATIENT_SEARCH_SUCCESS,
  PATIENT_REPORT_SUCCESS,
  DIAGNOSIS_REQUEST_SUCCESS,
  VITALS_REQUEST_SUCCESS,
  MEDICATIONS_REQUEST_SUCCESS,
  FACESHEET_REQUEST_SUCCESS,
  DMV_REQUEST_SUCCESS,
  PATIENT_LOADING,
  PATIENT_LOADING_SUCCESS,
  PATIENT_LOADING_FAIL,
  UPDATE_PATIENT,
} from '../constants/patientConstants';
import { ADMIN_FACILITYLIST_SUCCESS } from '../constants/userConstants';

const initialState = {
  patients: [],
  patientsTotal: 0,
  labResults: [],
  searchResults: [],
  reports: [],
  facilities: [],
  diagnosis: [],
  vitals: [],
  medications: [],
  facesheet: [],
  dmv: [],
  patientLoading: false,
  patientLoadingSuccess: null,
};
const patientReducer = (state = initialState, action) => {
  //console.log(action)
  switch (action.type) {
    case QUERY_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.patientResults,
        patientTotal: action.count,
      };
    case QUERY_LAB_SUCCESS:
      return {
        ...state,
        labResults: action.labResults,
      };
    case PATIENT_SEARCH_SUCCESS:
      return {
        ...state,
        patients: action.patientResults,
        patientTotal: action.count,
      };
    case PATIENT_REPORT_SUCCESS:
      return {
        ...state,
        reports: action.patientReports,
      };
    case ADMIN_FACILITYLIST_SUCCESS:
      return {
        ...state,
        facilities: action.facilityList,
      };
    case DIAGNOSIS_REQUEST_SUCCESS:
      return {
        ...state,
        diagnosis: action.diagnosis,
      };
    case VITALS_REQUEST_SUCCESS:
      return {
        ...state,
        vitals: action.vitals,
      };
    case MEDICATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        medications: action.medications,
      };
    case DMV_REQUEST_SUCCESS:
      return {
        ...state,
        dmv: action.dmv,
      };
    case FACESHEET_REQUEST_SUCCESS:
      return {
        ...state,
        facesheet: action.facesheet,
      };
    case PATIENT_LOADING:
      return {
        ...state,
        patientLoading: true,
        patientLoadingSuccess: false,
      };
    case PATIENT_LOADING_SUCCESS:
      return {
        ...state,
        patientLoading: false,
        patientLoadingSuccess: true,
      };
    case PATIENT_LOADING_FAIL:
      return {
        ...state,
        patientLoading: false,
        patientLoadingSuccess: false,
      };
    case UPDATE_PATIENT:
      return {
        ...state,
        patientLoadingSuccess: false,
      };

    default:
      return state;
  }
};
export default patientReducer;
