import {
  FACILITY_REPORT,
  FACILITY_REPORT_SUCCESS,
  FACILITY_REPORT_FAILURE,
  PATIENT_REPORT,
  PATIENT_REPORT_SUCCESS,
  PATIENT_REPORT_FAILURE,
  LOAD_REPORTS_SUCCESS,
  LOAD_REPORTS_FAILURE,
  UPLOAD_LAB,
  UPLOAD_LAB_SUCCESS,
  UPLOAD_LAB_FAIL,
  QUARTERLY_REPORT,
  QUARTERLY_REPORT_SUCCESS,
  QUARTERLY_REPORT_FAILURE,
  FILE_DIALOG_CONTROL,
  LAB_REQUEST_SUCCESS,
  LAB_REQUEST_FAILURE,
  LAB_REQUEST,
  PATIENT_REQUEST_SUCCESS,
  PATIENT_REQUEST_FAILURE,
  PATIENT_REQUEST,
  FACESHEET_REQUEST_FAIL,
  FACESHEET_REQUEST_SUCCESS,
  UPDATE_FACESHEET_REQUEST_FAIL,
  UPDATE_FACESHEET_REQUEST_SUCCESS,
  PATIENT_NAME_QUERY_FAIL,
  PATIENT_NAME_QUERY_SUCCESS,
  LAB_TESTS_QUERY_FAIL,
  LAB_TESTS_QUERY_SUCCESS,
} from '../constants/reportConstants';
import { downloadFile, downloadJSONFile, openNewTabFile } from '../utils/Utils';
import $ from 'jquery';
import Swal from 'sweetalert2';

export const facilityReport = () => ({
  type: FACILITY_REPORT,
});

export const facilityReportSuccess = (reports) => {
  if ($.isEmptyObject(reports)) {
    Swal.fire({
      icon: 'info',
      text: 'There were no search results',
    });
  }
  return {
    type: FACILITY_REPORT_SUCCESS,
    report: reports,
  };
};
export const facilityReportFailure = () => ({
  type: FACILITY_REPORT_FAILURE,
});
export const patientReport = () => ({
  type: PATIENT_REPORT,
});
export const patientReportSuccess = (reports) => {
  if ($.isEmptyObject(reports)) {
    Swal.fire({
      icon: 'info',
      text: 'There were no search results',
    });
  }
  return {
    type: PATIENT_REPORT_SUCCESS,
    report: reports,
  };
};
export const patientReportFailure = () => ({
  type: PATIENT_REPORT_FAILURE,
});

export const loadReportsSuccess = (reports) => ({
  type: LOAD_REPORTS_SUCCESS,
  reportResults: reports,
});
export const loadReportsFailure = () => ({
  type: LOAD_REPORTS_FAILURE,
});
export const uploadLab = () => ({
  type: UPLOAD_LAB,
});

export const uploadFail = () => ({
  type: UPLOAD_LAB_FAIL,
});

export const uploadSuccess = () => ({
  type: UPLOAD_LAB_SUCCESS,
});
export const quarterlyReport = () => ({
  type: QUARTERLY_REPORT,
});
export const quarterlyReportSuccess = (reports) => {
  if ($.isEmptyObject(reports)) {
    Swal.fire({
      icon: 'info',
      text: 'There were no search results',
    });
  }
  downloadFile('quarterlyReport', reports.file, 'XLSX');
  return {
    type: QUARTERLY_REPORT_SUCCESS,
  };
};
export const quarterlyReportFailure = () => ({
  type: QUARTERLY_REPORT_FAILURE,
});

export const fileDialogControl = (boolean) => ({
  type: FILE_DIALOG_CONTROL,
  fileDialogOpen: boolean,
});
export const labReqSuccess = (labReq) => {
  if ($.isEmptyObject(labReq)) {
    Swal.fire({
      icon: 'info',
      text: 'Unable to create lab request',
    });
  }
  return {
    type: LAB_REQUEST_SUCCESS,
    labReq: labReq,
  };
};
export const labReqFailure = () => ({
  type: LAB_REQUEST_FAILURE,
});
export const labRequest = () => ({
  type: LAB_REQUEST,
});
export const facesheetRequestSuccess = (facesheet) => {
  if ($.isEmptyObject(facesheet)) {
    Swal.fire({
      icon: 'info',
      text: 'Unable to retrieve facesheets',
    });
  }
  return {
    type: FACESHEET_REQUEST_SUCCESS,
    facesheet: facesheet,
  };
};
export const facesheetRequestFail = () => ({
  type: FACESHEET_REQUEST_FAIL,
});
export const updateFacesheetRequestSuccess = (facesheet) => ({
  type: UPDATE_FACESHEET_REQUEST_SUCCESS,
});
export const updateFacesheetRequestFail = () => ({
  type: UPDATE_FACESHEET_REQUEST_FAIL,
});
export const patientNameQuerySuccess = (results) => ({
  type: PATIENT_NAME_QUERY_SUCCESS,
  patientResults: results,
});
export const patientNameQueryFail = () => ({
  type: LAB_TESTS_QUERY_FAIL,
});
export const labTestsQuerySuccess = (results) => ({
  type: LAB_TESTS_QUERY_SUCCESS,
  labTests: results,
});
export const labTestsQueryFail = () => ({
  type: PATIENT_NAME_QUERY_FAIL,
});

export const patientReqSuccess = (labReq) => {
  if ($.isEmptyObject(labReq)) {
    Swal.fire({
      icon: 'info',
      text: 'Unable to create lab request',
    });
  }
  return {
    type: PATIENT_REQUEST_SUCCESS,
    labReq: labReq,
  };
};
export const patientReqFailure = () => ({
  type: PATIENT_REQUEST_FAILURE,
});
export const patientRequest = () => ({
  type: PATIENT_REQUEST,
});

export const printReportsByDate = (patientName, fac, start, end, crit) => {
  return (dispatch) => {
    let url = null;
    let param = '';
    let d = new Date(start).toISOString();
    let s = d.split('T');
    start = s[0] + ' 00:00:00.000';
    d = new Date(end).toISOString();
    let e = d.split('T');
    end = e[0] + ' 23:59:59.999';

    if (patientName === '') {
      param =
        'Facilities.facilityName = "' +
        fac +
        '" AND Reports.reportDate Between "' +
        start +
        '" AND "' +
        end +
        '"'
      url = new URL(
        process.env.REACT_APP_API_URL +
          '/report/printReportsQuery?col=*' +
          '&param= ' +
          param +
          '&type=fac&crit=' + crit
      );
      dispatch(facilityReport());
    } else if (fac === '') {
      const name = patientName.split(', ');
      let fName = name[1];
      let lName = name[0];
      param =
        'Patients.patientFirstName = "' +
        fName +
        '" AND Patients.patientLastName = "' +
        lName +
        '" AND Reports.reportDate Between "' +
        start +
        '" AND "' +
        end +
        '"';
      url = new URL(
        process.env.REACT_APP_API_URL +
          '/report/printReportsQuery?col=*' +
          '&param= ' +
          param +
          '&type=pat&crit=' + crit
      );
      dispatch(patientReport());
    }

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/octet-stream',
      },
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 204) {
          console.log('no content');
          Swal.fire({
            title: 'There are no test results in the time period.',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
          });
        } else {
          return response.arrayBuffer();
        }
      })
      .then((searchResult) => {
        var base64String = Buffer.from(searchResult).toString('base64');
        var title = 'report_' + new Date().toISOString().slice(0, 10) + '.pdf';
        // let json = JSON.parse(searchResult);
        // if(json.file){
        //   dispatch(addARRequest(json));

        // }
        openNewTabFile(title, base64String);
        dispatch(facilityReportSuccess('json.title'));
        dispatch(patientReportSuccess('json.title'));
      })
      .catch((error) => {
        dispatch(facilityReportFailure(error));
        dispatch(patientReportFailure(error));
      });
  };
};
export const addARRequest = (json) => {
  console.log(json);
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/addAutoReport');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        // dispatch(getQuickReports());
      })
      .catch((error) => {
        dispatch(facilityReportFailure(error));
      });
  };
};
export const generateLabReq = (
  patientName,
  facility,
  testType,
  labName,
  sampleType
) => {
  let lab = JSON.parse(labName);
  let json = {};
  json.test = testType;
  json.sampleType = sampleType;
  json.labID = lab.labID.toString();

  return (dispatch) => {
    let url = null;
    let param = '';
    if (Object.keys(patientName).length !== 0) {
      json.patient = JSON.parse(patientName);
      json.facility = facility;
    } else if (Object.keys(facility).length !== 0) {
      json.facility = JSON.parse(facility);
      json.patient = patientName;
    }
    url = new URL(process.env.REACT_APP_API_URL + '/report/generateLabReq');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(json),
      credentials: 'include',
    })
      .then((response) => {
        return response.text();
      })
      .then((searchResult) => {
        let json = JSON.parse(searchResult);
        if (json.file) {
          openNewTabFile('testPDF', json.file);
        }
        dispatch(labReqSuccess(json));
        dispatch(patientReqSuccess(json));
      })
      .catch((error) => {
        dispatch(labReqFailure(error));
        dispatch(patientReqFailure(error));
      });
  };
};

export const uploadFiles = (fileType, files, facility) => {
  let facilityOBJ = JSON.parse(facility);
  return (dispatch) => {
    let file;
    let formData = new FormData();

    for (file of files) {
      formData.append('file', file);
    }

    dispatch(uploadLab());
    fetch(process.env.REACT_APP_API_URL + '/data/upload', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'File-Type': fileType,
        Facility: facility,
      },

      body: formData,
      credentials: 'include',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status);
        } else {
          dispatch(uploadSuccess());
        }
      })
      .catch((error) => {
        dispatch(uploadFail(error));
      });
  };
};

export const reportCSV = (fac, start, end) => {
  return (dispatch) => {
    let url = null;
    let day = end + 24 * 60 * 60 * 1000 - 1000;
    let param = '';

    let d = new Date(start).toISOString();
    let s = d.split('T');
    start = s[0] + ' 00:00:00.000';
    d = new Date(end).toISOString();
    let e = d.split('T');
    end = e[0] + ' 23:59:59.999';

    param =
      'Facilities.facilityName = "' +
      fac +
      '" AND Reports.reportDate Between "' +
      start +
      '" AND "' +
      end +
      '"';
    url = new URL(
      process.env.REACT_APP_API_URL +
        '/report/excelQuarterlyReportQuery?col=*' +
        '&param= ' +
        param
    );
    dispatch(quarterlyReport());

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.text();
      })
      .then((searchResult) => {
        let json = JSON.parse(searchResult);
        dispatch(quarterlyReportSuccess(json));
      })
      .catch((error) => {
        dispatch(quarterlyReportFailure(error));
      });
  };
};
export const facesheetRequest = (term) => {
  return (dispatch) => {
    let url = null;
    let where = null;
    where = 'facilityName="' + term + '"';
    url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/facesheetQuery?col=*' +
        '&where=' +
        encodeURIComponent(where)
    );

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.text();
      })
      .then((searchResult) => {
        let json = JSON.parse(searchResult);
        if (json.file) {
          openNewTabFile('testPDF', json.file);
        }

        dispatch(facesheetRequestSuccess(json));
      })
      .catch((error) => {
        dispatch(facesheetRequestFail(error));
      });
  };
};

export const updateFacesheets = (facility, type) => {
  return (dispatch) => {
    let url = null;
    if (type === 'facility') {
      url = new URL(
        process.env.REACT_APP_API_URL +
          '/data/updateFacesheets?col=*' +
          '&facilityName=' +
          encodeURIComponent(facility.facilityName) +
          '&facilityLoginID=' +
          facility.facLoginID
      );
    } else if (type === 'id') {
      url = new URL(
        process.env.REACT_APP_API_URL +
          '/data/updateFacesheets?col=*' +
          '&facilityLoginID=' +
          facility.facLoginID
      );
    }

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.text();
      })
      .then((searchResult) => {
        let json = JSON.parse(searchResult);
        dispatch(updateFacesheetRequestSuccess(json));
      })
      .catch((error) => {
        dispatch(updateFacesheetRequestFail(error));
      });
  };
};
export const labTestsList = (labName) => {
  return (dispatch) => {
    let url = null;
    url = new URL(
      process.env.REACT_APP_API_URL + '/data/getLabTests?labID=' + labName
    );

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(labTestsQuerySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(labTestsQueryFail());
      });
  };
};
export const patientNameList = () => {
  return (dispatch) => {
    let url = null;
    url = new URL(process.env.REACT_APP_API_URL + '/data/patientNameQuery');

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(patientNameQuerySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(patientNameQueryFail());
      });
  };
};
