import { QUERY_LOGINS_SUCCESS } from '../constants/facilityLoginConstants';
const initialState = {
  logins: [],
};
const facilityLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUERY_LOGINS_SUCCESS:
      return {
        ...state,
        logins: action.logins,
      };
    default:
      return state;
  }
};
export default facilityLoginReducer;
